






























/**
 * TheMainHeader.vue
 *
 * This component contains a language switcher, a search bar, some links and the account component
 * Component call:
 *   <TheMainHeader />
 */

import { Vue, Component } from 'nuxt-property-decorator';
import { ContentIdRouteParams, RouteNames } from '@generated/routeNames';
import { HeaderLink } from '@/interfaces/header-navigation';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import TheHeaderSearch from '@/components/search/TheHeaderSearch.vue';
import TheLanguageSwitcherComponent from './TheLanguageSwitcher.vue';
import TheAccountBarComponent from './TheAccountBar.vue';

@Component({
  name: 'the-main-header',
  components: {
    TheHeaderSearch,
    TheLanguageSwitcher: TheLanguageSwitcherComponent,
    AppIcon: AppIconComponent,
    TheAccountBar: TheAccountBarComponent,
  },
})
export default class TheMainHeaderComponent extends Vue {
  showHeaderSearchbar = false;

  headerLinks: HeaderLink[] = [
    { title: 'Leichte Sprache', uuid: '4bda1b40-20d2-4e5a-b7e6-98ff6c7576b3', icon: 'fa-book' },
    { title: 'Barrierefreiheit', uuid: '61f48a33-2c0b-41b8-90c4-6bd694fc8ecf', icon: 'fa-universal-access' },
  ];

  get linkTitleStyle(): string {
    return this.showHeaderSearchbar ? 'xl:inline' : 'lg:inline';
  }

  get linkIconsStyle(): string {
    return this.showHeaderSearchbar ? 'xl-headerlink-icon' : 'lg-headerlink-icon';
  }

  onToggleSearchbar(searchbarExpanded: boolean): void {
    this.showHeaderSearchbar = searchbarExpanded;
  }

  getContentPath(contentId: string): string {
    return this.localePath({ name: RouteNames.CONTENT_ID, params: { [ContentIdRouteParams.ID]: contentId } });
  }

  /**
   * set default header link icon
   */
  headerLinkIcon(icon: string): string {
    return icon || 'fa-file-text-o';
  }
}
