
































































































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { escapeRegExp } from 'lodash';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import { SearchAPI, SearchResult, SearchValue } from '@/interfaces/vsm';
import EscapeUtils from '@/utils/escape-text';
import { TranslateResult } from 'vue-i18n';

/**
 * Customized search input with icon and reset button
 *
 * Properties:
 * - icon: name of the icon which is defined in icon.ts, required
 * - invertedIcon: invert icon colors from fiord to white and white to fiord, default: false
 * - placeholderText: placeholder text in input element, required
 * - label: label text for input element
 * - searchId: unique id for connecting label with input element, required
 * - value: is used for two-way binding to use v-model in parent
 * - autoSuggestion: list of keywords which is used to show unordered list for the auto suggestion
 *
 * Events:
 * - input event to implementing two-way binding
 *
 * Component call:
 * ```html
 *   <SearchInput />
 * ```
 *
 */
@Component({
  name: 'SearchInputComponent',
  components: { AppIcon: AppIconComponent },
})
export default class SearchInputComponent extends Vue {
  @Prop() readonly label?: string;
  @Prop() readonly labelClass?: string | string[];
  @Prop() readonly iconClass?: string | string[];
  @Prop({ required: true }) readonly searchId!: string;
  @Prop({ required: true }) readonly icon!: string;
  @Prop({ required: true }) readonly placeholderText!: string;
  @Prop({ default: [] }) readonly autoSuggestion!: SearchResult[];
  @Prop({ type: Boolean }) readonly isHeaderSearch!: boolean;

  /**
   * two way binding with v-model only work for value as variable name
   */
  @Prop({ required: true, default: { value: '', suggestion: null } })
  readonly value!: SearchValue;

  isOpen = false;
  arrowCounter = 0;
  searchAPI = SearchAPI;

  get popupListId(): string {
    return this.searchId + '-list';
  }

  get labelId(): string {
    return this.searchId + '-label';
  }

  get activePopupListItemId(): string | boolean {
    return this.showPopupList ? this.generatePopupListItemId(this.arrowCounter) : false;
  }

  get showPopupList(): boolean {
    return this.isOpen && this.autoSuggestion.length > 0;
  }

  get inputClass(): string {
    return this.isHeaderSearch ? 'md:pl-9 xl:text-base' : 'sm:text-base';
  }

  /**
   * Generates ID's for the items in the popup list
   *
   * @param index - The index of the item in the list
   */
  generatePopupListItemId(index: number): string {
    return this.popupListId + '-item-' + index;
  }

  /**
   * add cluster keyword for leika and web-search in list item
   *
   * @param result - object which contains suggestion type
   * @returns cluster keyword
   */
  getSearchCluster(result: SearchResult): TranslateResult {
    switch (result.type) {
      case SearchAPI.service:
        return this.$i18n.t('search.service');
      case SearchAPI.cms:
        return this.$i18n.t('search.portal');
      case SearchAPI.webResult:
        return this.$i18n.t('search.searchResult');
      default:
        return '';
    }
  }

  /**
   * format search keyword to be bolder in auto suggestion
   *
   * @param result - object which contains suggestion type
   * @returns formatted html string
   */
  formatListItem(result: SearchResult): string {
    const resultHtml = result.name.replace(
      new RegExp(escapeRegExp(EscapeUtils.escapeText(this.value.value)), 'gi'), // escape input value to prevent XSS
      '<span class="font-bold">$&</span>'
    );

    return '<span>' + resultHtml + '</span>';
  }

  /**
   * send current search keyword to the parent to interact with this
   */
  updateCustomSearch(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.isOpen = true;
    const searchValue: SearchValue = {
      value: target.value,
      suggestion: null,
    };
    this.$emit('input', searchValue);
  }

  /**
   * reset input value and emits to parent
   * method of the reset button
   */
  resetValue(): void {
    this.$emit('input', { value: '', suggestion: null });
    this.$emit('reset', { value: '', suggestion: null });
    this.isOpen = false;
  }

  /**
   * emits search keyword and close auto suggestion
   */
  useSuggestedValue(result: SearchResult): void {
    this.isOpen = false;
    this.$emit('input', { value: result.name, suggestion: result });
  }

  /**
   * navigating to the bottom with arrow key in auto suggestion
   */
  onArrowDown(): void {
    if (this.arrowCounter < this.autoSuggestion.length - 1) {
      this.arrowCounter = this.arrowCounter + 1;
    } else {
      this.arrowCounter = 0;
    }
  }

  /**
   * navigating to the top with arrow key in auto suggestion
   */
  onArrowUp(): void {
    if (this.arrowCounter > 0) {
      this.arrowCounter = this.arrowCounter - 1;
    } else {
      this.arrowCounter = this.autoSuggestion.length - 1;
    }
  }

  /**
   * use auto suggested value and emit this to the parent
   */
  onEnter(e: Event): void {
    if (this.isOpen && this.autoSuggestion.length > 0) {
      e.preventDefault();
      e.stopPropagation();
      const result: SearchResult = this.autoSuggestion[this.arrowCounter];
      this.$emit('input', { value: result.name, suggestion: result });
      this.isOpen = false;
      this.arrowCounter = 0;
    }
  }
}
