
















































import { Vue, Component, Prop } from 'nuxt-property-decorator';
import AppIconComponent from '../svg/AppIcon.vue';

/**
 * DropDown component to render a drop down menu.
 *
 * Props:
 * * label - set a label for drop down menu
 * * fontAwesomeClass - set a icon for drop down
 * * icon-before - set if the icon should appear before label
 * * classes - set classes to the trigger button
 *
 * Slots:
 * * default - add DropDownItem here
 */
@Component({ name: 'DropDown', components: { AppIcon: AppIconComponent } })
export default class DropDownComponent extends Vue {
  /** toggle button label */
  @Prop({ required: true }) readonly label!: string;
  /** button label fontawesome icon */
  @Prop() readonly fontAwesomeClass: string | undefined;
  /** toggle button label icon */
  @Prop({ type: Boolean }) readonly iconBefore!: boolean;
  /** button classes */
  @Prop() readonly classes?: string | string[];
  /** toggle flag */
  open = false;

  /**
   * Toggles drop down.
   */
  toggle(): void {
    this.open = !this.open;
  }

  /**
   * Closes drop down.
   */
  close(): void {
    this.open = false;
  }

  /**
   * Add listener to document to recognize outer click.
   */
  mounted(): void {
    if (process.client) {
      const handler = (e: MouseEvent | TouchEvent): void => {
        if (!this.$el.contains(e.target as Node)) {
          this.close();
        }
      };
      const event = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
      window.document.addEventListener(event, handler);
      (this.$el as any).outerHandler = handler;
    }
  }

  /**
   * Remove listener of document.
   */
  beforeDestroy(): void {
    if (process.client && (this.$el as any).outerHandler) {
      const event = 'ontouchstart' in document.documentElement ? 'touchstart' : 'click';
      window.document.removeEventListener(event, (this.$el as any).outerHandler);
    }
  }
}
