/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Leika API
 * Liefert Leistungsdaten zurück
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { Leika, LeikaProperties } from './leika';

/**
 * 
 * @export
 * @interface LeikaResponse
 */
export interface LeikaResponse extends Array<Leika> {
}

/**
 * All properties of LeikaResponse.
 * @export
 * @enum {string}
 */
export enum LeikaResponseProperties {
}


