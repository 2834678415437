/**
 * Enumeration of all possible route names.
 */
export enum RouteNames {
  ACCOUNT = 'account',
  FORM = 'form',
  PEGA = 'pega',
  PORTALS = 'portals',
  REDIRECT = 'redirect',
  SEARCH = 'search',
  CIRCUMSTANCES_CIRCUMSTANCE = 'circumstances-circumstance',
  CONTENT_ID = 'content-id',
  SERVICE_LEIKAID = 'service-leikaid',
  TOPICS_TOPIC = 'topics-topic',
  INDEX = 'index',
}

/** Enumeration of all route params for route 'circumstances-circumstance'. */
export enum CircumstancesCircumstanceRouteParams {
  CIRCUMSTANCE = 'circumstance',
}

/** Enumeration of all route params for route 'content-id'. */
export enum ContentIdRouteParams {
  ID = 'id',
}

/** Enumeration of all route params for route 'service-leikaid'. */
export enum ServiceLeikaidRouteParams {
  LEIKAID = 'leikaid',
}

/** Enumeration of all route params for route 'topics-topic'. */
export enum TopicsTopicRouteParams {
  TOPIC = 'topic',
}
