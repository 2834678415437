import { Context, Plugin } from '@nuxt/types';

/** declare new properties */
declare module 'vue/types/vue' {
  interface Vue {
    $navigateBack: () => void;
  }
}

/** vue plugin to get previous route */
const navigateBackPlugin: Plugin = (ctx: Context, inject: (key: string, value: any) => void): Promise<void> | void => {
  inject('navigateBack', () => {
    if (ctx.from && ctx.route && ctx.from.fullPath !== ctx.route.fullPath) {
      ctx.redirect(ctx.from.fullPath);
    } else {
      ctx.redirect('/');
    }
  });
};

export default navigateBackPlugin;
