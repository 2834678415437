import { Context, Plugin } from '@nuxt/types';
import { Inject } from '@nuxt/types/app';

/** declare new properties */
declare module 'vue/types/vue' {
  interface Vue {
    $addIgnoreUrl: (urlPart: string) => void;
    $removeIgnoreUrl: (urlPart: string) => void;
  }
}

/** only one request per minute */
const DEBOUNCE = 60000;
/** url marker */
const URL_MARKER = '://';

/** flag to indicate debounce is running */
let callRunning = false;

/**
 * Extends session.
 *
 * @param ctx - context
 */
async function extendSession(ctx: Context): Promise<void> {
  if (!callRunning && (ctx.$accountModule.user || ctx.$accountModule.transientUser)) {
    callRunning = true;
    setTimeout(() => (callRunning = false), DEBOUNCE);
    // trigger user fetch to extend session
    await ctx.$accountModule.fetchUser();
  }
}

/**
 * Plugin to intercept requests.
 *
 * @param ctx - context
 */
const requestInterceptPlugin: Plugin = (ctx: Context, inject: Inject): Promise<void> | void => {
  // build possible request origins
  const origins: string[] = [];
  let ignoreList: string[] = [];
  origins.push(window.location.origin);
  // if (ctx.$config.cmsBrowser.includes(URL_MARKER)) {
  //   origins.push(ctx.$config.cmsBrowser);
  // }
  if (ctx.$config.fmsBaseUrl.includes(URL_MARKER)) {
    origins.push(ctx.$config.fmsBaseUrl);
  }
  // patch XMLHttpRequest
  const open = window.XMLHttpRequest.prototype.open;
  window.XMLHttpRequest.prototype.open = function (...args: unknown[]): void {
    // keep requestUrl for later check
    (this as any).requestUrl = args[1];
    open.apply(this, [...args] as any);
  };
  const send = window.XMLHttpRequest.prototype.send;
  window.XMLHttpRequest.prototype.send = function (data): void {
    // check url and expand session only for request to own origin
    if (
      (this as any).requestUrl &&
      (!(this as any).requestUrl.includes(URL_MARKER) ||
        origins.some((origin) => (this as any).requestUrl.startsWith(origin))) &&
      !ignoreList.some((part) => ((this as any).requestUrl as string).includes(part))
    ) {
      extendSession(ctx);
    }
    send.call(this, data);
  };
  // patch fetch
  const fetch = window.fetch;
  window.fetch = function (input, init): Promise<Response> {
    const url = typeof input === 'string' ? input : input.url;
    if (
      (!url.includes(URL_MARKER) || origins.some((origin) => url.startsWith(origin))) &&
      !ignoreList.some((part) => url.includes(part))
    ) {
      extendSession(ctx);
    }
    return fetch.call(this, input, init);
  };
  // and route changes should also extend session
  ctx.app.router?.afterEach(() => {
    extendSession(ctx);
  });

  // add new context functions
  inject('addIgnoreUrl', (urlPart: string) => {
    if (!ignoreList.includes(urlPart)) {
      ignoreList.push(urlPart);
    }
  });
  inject('removeIgnoreUrl', (urlPart: string) => {
    ignoreList = ignoreList.filter((part) => urlPart !== part);
  });
};

export default requestInterceptPlugin;
