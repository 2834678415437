









































































/**
 * TheHeaderSearch.vue
 *
 * This component uses the SearchInputComponent and handle to search APIs. It is used in the header of each
 * page, except for the "Startseite (Homepage)", "Persönlicher Bereich (Personal Area)" and
 * "Suchergebnis (Search Result)".
 * This component will emit an event when the search-bar is expanded or collapsed.
 *
 * Property:
 *   - location: this property is used to generate unique ids in the children (e.g. homepage, footer, header, search) [string]
 *
 * Pages can mount it with:
 *   <TheHeaderSearch></TheHeaderSearch>
 */

import { Component, Prop, Watch } from 'nuxt-property-decorator';
import { RouteNames } from '@generated/routeNames';
import { BaseSearch } from '@/components/search/BaseSearch';
import SearchInputComponent from '@/components/base/SearchInput.vue';
import AppIconComponent from '@/components/svg/AppIcon.vue';

@Component({
  name: 'TheHeaderSearch',
  components: { SearchInput: SearchInputComponent, AppIcon: AppIconComponent },
})
export default class TheHeaderSearch extends BaseSearch {
  @Prop({ default: 'header' }) readonly location!: string;

  /** flag indicating whether the search bar is visible or not */
  private showSearchBar = false;

  /**
   * init component
   */
  created(): void {
    this.componentLocation = this.location;
  }

  /**
   * To check if the current page is a search page
   */
  get isSearchPage(): boolean {
    return this.localePath(RouteNames.SEARCH) === this.$route.path;
  }

  /**
   * Show the search bar and emit and event about it.
   */
  showSearchbar(): void {
    this.showSearchBar = true;
    this.$emit('toggleSearchbar', this.showSearchBar);
  }

  /**
   * Hide the search bar and emit an event about it.
   */
  hideSearchBar(): void {
    this.showSearchBar = false;
    this.$emit('toggleSearchbar', this.showSearchBar);
  }

  /**
   * Execute the search.
   */
  search(): void {
    this.submit();
    this.hideSearchBar();
  }

  /**
   * Only show the search-button if the current page is NOT an account or index page
   */
  get isSearchAvailable(): boolean {
    return [RouteNames.INDEX, RouteNames.ACCOUNT].map((p) => this.localePath(p)).every((p) => this.$route.path !== p);
  }

  /**
   * computed method to generate unique id for topic search
   */
  get searchTopicID(): string {
    return `search-topic-${this.location}`;
  }

  /**
   * computed method to generate unique id for location
   */
  get searchLocationID(): string {
    return `search-location-${this.location}`;
  }

  /**
   * Computed method is needed to disable/enable the search button. Because of inheritance of BaseSearch we have to
   * check if searchTopic.value is defined to avoid error messages because cannot use length of undefined.
   */
  get buttonDisabled(): boolean {
    return !this.searchTopic.value || this.searchTopic.value.length < 3;
  }

  /**
   * When the current path changes, we need close the search-bar.
   */
  @Watch('$route.path')
  onPathChanged(): void {
    this.hideSearchBar();
  }
}
