/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AxiosPromise, AxiosInstance, CancelTokenSource } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, ResponseWithIncluded } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequestOptions, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FormPaymentDataDto } from '../models';
// @ts-ignore
import { IBANValidateResultDto } from '../models';
// @ts-ignore
import { StartPaymentBody } from '../models';

/**
 * All paths of PaymentService.
 * Path parameters are prefixed with a colon, e.g. /foo/bar/:baz
 *
 * @export
 */
export const PaymentServicePaths = {
    apiPaymentControllerCompletePayment: '/api/payment/complete',
    apiPaymentControllerGetFormPaymentData: '/api/payment/data',
    apiPaymentControllerGetIBANValidation: '/api/payment/validate/{iban}'.replace(`{${"iban"}}`, ':iban'),
    apiPaymentControllerStartPayment: '/api/payment/{formId}/start'.replace(`{${"formId"}}`, ':formId')
}

/**
 * All request headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 *
 * @export
 */
export const PaymentServiceRequestHeaders = {

}

/**
 * All response headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 * Axios will transform all header names to lowercase and therefore this map will also contain all headers in lowercase.
 *
 * @see https://github.com/axios/axios/issues/413
 *
 * @export
 */
export const PaymentServiceResponseHeaders = {

}

/**
 * All path parameters of each operation.
 *
 * @export
 */
export const PaymentServicePathParams = {

    apiPaymentControllerGetIBANValidation: {
        iban: 'iban'
    },

    apiPaymentControllerStartPayment: {
        formId: 'formId'
    }

}

/**
 * All query parameters of each operation.
 *
 * @export
 */
export const PaymentServiceQueryParams = {

    apiPaymentControllerCompletePayment: {
        id: 'id',
        result: 'result'
    },

    apiPaymentControllerGetFormPaymentData: {
        leiKaId: 'leiKaId',
        authorityKey: 'authorityKey'
    },

}

/**
 * PaymentService - axios parameter creator
 * @export
 */
export const PaymentServiceAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {'SUCCESS' | 'ERROR' | 'CANCEL'} result 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerCompletePayment: async (id: string, result: 'SUCCESS' | 'ERROR' | 'CANCEL', options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiPaymentControllerCompletePayment', 'id', id)
            // verify required parameter 'result' is not null or undefined
            assertParamExists('apiPaymentControllerCompletePayment', 'result', result)
            const localVarPath = `/api/payment/complete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }
            if (result !== undefined) {
                localVarQueryParameter['result'] = result;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leiKaId 
         * @param {string} authorityKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerGetFormPaymentData: async (leiKaId: string, authorityKey: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'leiKaId' is not null or undefined
            assertParamExists('apiPaymentControllerGetFormPaymentData', 'leiKaId', leiKaId)
            // verify required parameter 'authorityKey' is not null or undefined
            assertParamExists('apiPaymentControllerGetFormPaymentData', 'authorityKey', authorityKey)
            const localVarPath = `/api/payment/data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (leiKaId !== undefined) {
                localVarQueryParameter['leiKaId'] = leiKaId;
            }
            if (authorityKey !== undefined) {
                localVarQueryParameter['authorityKey'] = authorityKey;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerGetIBANValidation: async (iban: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'iban' is not null or undefined
            assertParamExists('apiPaymentControllerGetIBANValidation', 'iban', iban)
            const localVarPath = `/api/payment/validate/{iban}`
                .replace(`{${"iban"}}`, encodeURIComponent(String(iban)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} formId 
         * @param {StartPaymentBody} startPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerStartPayment: async (formId: string, startPaymentBody: StartPaymentBody, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'formId' is not null or undefined
            assertParamExists('apiPaymentControllerStartPayment', 'formId', formId)
            // verify required parameter 'startPaymentBody' is not null or undefined
            assertParamExists('apiPaymentControllerStartPayment', 'startPaymentBody', startPaymentBody)
            const localVarPath = `/api/payment/{formId}/start`
                .replace(`{${"formId"}}`, encodeURIComponent(String(formId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startPaymentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Cancel tokens of pending requests. One cancel token per service function.
 */
const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * PaymentService - functional programming interface
 * @export
 */
export const PaymentServiceFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentServiceAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {'SUCCESS' | 'ERROR' | 'CANCEL'} result 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentControllerCompletePayment(id: string, result: 'SUCCESS' | 'ERROR' | 'CANCEL', options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentControllerCompletePayment(id, result, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiPaymentController_completePayment'],
                set: (token: CancelTokenSource) => cancelTokens['ApiPaymentController_completePayment'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} leiKaId 
         * @param {string} authorityKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentControllerGetFormPaymentData(leiKaId: string, authorityKey: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormPaymentDataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentControllerGetFormPaymentData(leiKaId, authorityKey, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiPaymentController_getFormPaymentData'],
                set: (token: CancelTokenSource) => cancelTokens['ApiPaymentController_getFormPaymentData'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentControllerGetIBANValidation(iban: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IBANValidateResultDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentControllerGetIBANValidation(iban, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiPaymentController_getIBANValidation'],
                set: (token: CancelTokenSource) => cancelTokens['ApiPaymentController_getIBANValidation'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} formId 
         * @param {StartPaymentBody} startPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPaymentControllerStartPayment(formId: string, startPaymentBody: StartPaymentBody, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPaymentControllerStartPayment(formId, startPaymentBody, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiPaymentController_startPayment'],
                set: (token: CancelTokenSource) => cancelTokens['ApiPaymentController_startPayment'] = token,
              },
              configuration
            );
        },
    }
};

/**
 * PaymentService - factory interface
 * @export
 */
export const PaymentServiceFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentServiceFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {'SUCCESS' | 'ERROR' | 'CANCEL'} result 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerCompletePayment(id: string, result: 'SUCCESS' | 'ERROR' | 'CANCEL', options?: RequestOptions): AxiosPromise<void> {
            return localVarFp.apiPaymentControllerCompletePayment(id, result, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leiKaId 
         * @param {string} authorityKey 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerGetFormPaymentData(leiKaId: string, authorityKey: string, options?: RequestOptions): AxiosPromise<FormPaymentDataDto> {
            return localVarFp.apiPaymentControllerGetFormPaymentData(leiKaId, authorityKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} iban 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerGetIBANValidation(iban: string, options?: RequestOptions): AxiosPromise<IBANValidateResultDto> {
            return localVarFp.apiPaymentControllerGetIBANValidation(iban, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} formId 
         * @param {StartPaymentBody} startPaymentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPaymentControllerStartPayment(formId: string, startPaymentBody: StartPaymentBody, options?: RequestOptions): AxiosPromise<string> {
            return localVarFp.apiPaymentControllerStartPayment(formId, startPaymentBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PaymentService - interface
 * @export
 * @interface PaymentService
 */
export interface PaymentServiceInterface {
    /**
     * 
     * @param {string} id 
     * @param {'SUCCESS' | 'ERROR' | 'CANCEL'} result 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentServiceInterface
     */
    apiPaymentControllerCompletePayment(id: string, result: 'SUCCESS' | 'ERROR' | 'CANCEL', options?: RequestOptions): AxiosPromise<void>;

    /**
     * 
     * @param {string} leiKaId 
     * @param {string} authorityKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentServiceInterface
     */
    apiPaymentControllerGetFormPaymentData(leiKaId: string, authorityKey: string, options?: RequestOptions): AxiosPromise<FormPaymentDataDto>;

    /**
     * 
     * @param {string} iban 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentServiceInterface
     */
    apiPaymentControllerGetIBANValidation(iban: string, options?: RequestOptions): AxiosPromise<IBANValidateResultDto>;

    /**
     * 
     * @param {string} formId 
     * @param {StartPaymentBody} startPaymentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentServiceInterface
     */
    apiPaymentControllerStartPayment(formId: string, startPaymentBody: StartPaymentBody, options?: RequestOptions): AxiosPromise<string>;

}

/**
 * PaymentService - object-oriented interface
 * @export
 * @class PaymentService
 * @extends {BaseAPI}
 */
export class PaymentService extends BaseAPI implements PaymentServiceInterface {
    /**
     * 
     * @param {string} id 
     * @param {'SUCCESS' | 'ERROR' | 'CANCEL'} result 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentService
     */
    public apiPaymentControllerCompletePayment(id: string, result: 'SUCCESS' | 'ERROR' | 'CANCEL', options?: RequestOptions) {
        return PaymentServiceFp(this.configuration).apiPaymentControllerCompletePayment(id, result, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leiKaId 
     * @param {string} authorityKey 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentService
     */
    public apiPaymentControllerGetFormPaymentData(leiKaId: string, authorityKey: string, options?: RequestOptions) {
        return PaymentServiceFp(this.configuration).apiPaymentControllerGetFormPaymentData(leiKaId, authorityKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} iban 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentService
     */
    public apiPaymentControllerGetIBANValidation(iban: string, options?: RequestOptions) {
        return PaymentServiceFp(this.configuration).apiPaymentControllerGetIBANValidation(iban, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} formId 
     * @param {StartPaymentBody} startPaymentBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentService
     */
    public apiPaymentControllerStartPayment(formId: string, startPaymentBody: StartPaymentBody, options?: RequestOptions) {
        return PaymentServiceFp(this.configuration).apiPaymentControllerStartPayment(formId, startPaymentBody, options).then((request) => request(this.axios, this.basePath));
    }
}
