



























import { Vue, Component, Prop } from 'nuxt-property-decorator';
import { Component as ComponentType, AsyncComponent } from 'vue/types/options';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import { DialogEvent } from '@/interfaces/dialog';

const EVENT = 'ontouchstart' in window.document.documentElement ? 'touchstart' : 'mousedown';

/**
 * Component implements the dialog container component to predefine a header, actions and ...
 * Please note that this component is only visible in the Vue DevTools, if the Dialog is already open when opening the Vue DevTools
 */
@Component({
  components: { AppIcon: AppIconComponent },
  name: 'DialogContainerComponent',
})
export default class DialogContainerComponent extends Vue {
  /** title */
  @Prop({ required: true })
  readonly title!: string;

  /** component to render */
  @Prop({ required: true })
  readonly component!: ComponentType<any, any, any, any> | AsyncComponent<any, any, any, any>;

  /** props for the component */
  @Prop({ required: true })
  readonly props!: Record<string, unknown>;

  /** mounted hook */
  mounted(): void {
    setTimeout(() => {
      this.keyDown = this.keyDown.bind(this);
      (this.$el as HTMLElement).addEventListener('keydown', this.keyDown);
      this.documentClick = this.documentClick.bind(this);
      window.document.addEventListener(EVENT, this.documentClick);
      const autoFocusElement = this.$el.querySelector('.auto-focus, input') as any;
      if (autoFocusElement && autoFocusElement.focus && !autoFocusElement.hasAttribute('disable')) {
        autoFocusElement.focus();
      } else {
        const list = this.getFocusableElements();
        if (list.length > 0) {
          list[list.length - 1].focus();
        }
      }
    });
  }

  beforeDestroy(): void {
    (this.$el as HTMLElement).removeEventListener('keydown', this.keyDown);
    window.document.removeEventListener(EVENT, this.documentClick);
  }

  /** close dialog */
  closeDialog(): void {
    this.$destroy();
  }

  onDismiss(): void {
    this.$emit(DialogEvent.Dismiss);
    this.closeDialog();
  }

  onCancel(value: unknown): void {
    this.$emit(DialogEvent.Cancel, value);
    this.closeDialog();
  }

  onOk(value: unknown): void {
    this.$emit(DialogEvent.Ok, value);
    this.closeDialog();
  }

  /**
   * document click listener.
   */
  private documentClick(e: MouseEvent | TouchEvent): void {
    if (this.$refs.dialogEl && !(this.$refs.dialogEl as HTMLElement).contains(e.target as Node)) {
      this.onDismiss();
    }
  }

  /**
   * Handles keydown events.
   */
  private keyDown(e: KeyboardEvent): void {
    switch (e.key) {
      // handle escape button
      case 'Escape':
        this.onDismiss();
        break;
      // handle tab button
      case 'Tab':
        this.keepFocus(e);
        break;
      default:
        break;
    }
  }

  /**
   * Keeps focus in dialog.
   *
   * @param e - Keyboardevent
   */
  private keepFocus(e: KeyboardEvent): void {
    const list = this.getFocusableElements();
    if (list.length > 0) {
      const compareElement = e.shiftKey ? list[0] : list[list.length - 1];
      if (e.target !== compareElement) {
        return;
      }
      (e.shiftKey ? list[list.length - 1] : list[0]).focus();
    }
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   * Gets keyboard-focusable elements.
   */
  private getFocusableElements(): HTMLElement[] {
    return Array.from(
      this.$el.querySelectorAll('a, button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])')
    ).filter((el) => !el.hasAttribute('disabled')) as HTMLElement[];
  }
}
