/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { CompetenceModel, CompetenceModelProperties } from './competence-model';

/**
 * 
 * @export
 * @interface ZustaendigkeitAntwortXmlZustaendigkeiten
 */
export interface ZustaendigkeitAntwortXmlZustaendigkeiten {
    /**
     * 
     * @type {CompetenceModel}
     * @memberof ZustaendigkeitAntwortXmlZustaendigkeiten
     */
    'zustaendigkeit'?: CompetenceModel;
}

/**
 * All properties of ZustaendigkeitAntwortXmlZustaendigkeiten.
 * @export
 * @enum {string}
 */
export enum ZustaendigkeitAntwortXmlZustaendigkeitenProperties {
    zustaendigkeit = 'zustaendigkeit'
}


