/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FormCreateDto
 */
export interface FormCreateDto {
    /**
     * 
     * @type {string}
     * @memberof FormCreateDto
     */
    'leiKaId': string;
    /**
     * 
     * @type {string}
     * @memberof FormCreateDto
     */
    'authorityKey': string;
    /**
     * 
     * @type {boolean}
     * @memberof FormCreateDto
     */
    'applyUserData'?: boolean;
}

/**
 * All properties of FormCreateDto.
 * @export
 * @enum {string}
 */
export enum FormCreateDtoProperties {
    leiKaId = 'leiKaId',
    authorityKey = 'authorityKey',
    applyUserData = 'applyUserData'
}


