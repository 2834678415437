/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { AdapterConfigsModel, AdapterConfigsModelProperties } from './adapter-configs-model';
// @ts-ignore
import { OrganisationsModel, OrganisationsModelProperties } from './organisations-model';

/**
 * 
 * @export
 * @interface ZustaendigkeitAntwortJsonWeitereOrganisationseinheiten
 */
export interface ZustaendigkeitAntwortJsonWeitereOrganisationseinheiten {
    /**
     * 
     * @type {Array<OrganisationsModel>}
     * @memberof ZustaendigkeitAntwortJsonWeitereOrganisationseinheiten
     */
    'daten'?: Array<OrganisationsModel>;
    /**
     * 
     * @type {Array<AdapterConfigsModel>}
     * @memberof ZustaendigkeitAntwortJsonWeitereOrganisationseinheiten
     */
    'adapterConfigs'?: Array<AdapterConfigsModel>;
}

/**
 * All properties of ZustaendigkeitAntwortJsonWeitereOrganisationseinheiten.
 * @export
 * @enum {string}
 */
export enum ZustaendigkeitAntwortJsonWeitereOrganisationseinheitenProperties {
    daten = 'daten',
    adapterConfigs = 'adapterConfigs'
}


