/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AxiosPromise, AxiosInstance, CancelTokenSource } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, ResponseWithIncluded } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequestOptions, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestErrorModel } from '../models';
// @ts-ignore
import { ServerErrorModel } from '../models';
// @ts-ignore
import { WspZustaendigkeitAntwortJson } from '../models';
// @ts-ignore
import { ZustaendigkeitAntwortJson } from '../models';

/**
 * All paths of DefaultService.
 * Path parameters are prefixed with a colon, e.g. /foo/bar/:baz
 *
 * @export
 */
export const DefaultServicePaths = {
    /**
     * Ermittlung der Zuständigkeiten.
     */
    getCompetences: '/zustaendigkeiten',
    /**
     * Ermittlung aller WSP relevantent Zuständigkeiten.
     */
    getWspCompetences: '/wsp'
}

/**
 * All request headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 *
 * @export
 */
export const DefaultServiceRequestHeaders = {

}

/**
 * All response headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 * Axios will transform all header names to lowercase and therefore this map will also contain all headers in lowercase.
 *
 * @see https://github.com/axios/axios/issues/413
 *
 * @export
 */
export const DefaultServiceResponseHeaders = {

}

/**
 * All path parameters of each operation.
 *
 * @export
 */
export const DefaultServicePathParams = {

}

/**
 * All query parameters of each operation.
 *
 * @export
 */
export const DefaultServiceQueryParams = {

    /**
     * Available query parameters for getCompetences.
     */
    getCompetences: {
        /**
         * Der Leistungsschlüssel nach dem gesucht wird.
         */
        leistungsSchluessel: 'leistungsSchluessel',
        /**
         * Der Regionalschlüssel der Region, in der gesucht werden soll.
         */
        regionalSchluessel: 'regionalSchluessel',
        /**
         * Die Postleitzahl, die für die Suche von Zuständigkeiten aus fremden Quellen, z. B. DVDS (Finanzämter) und KiTas (KiTa-Finder NRW), verwendet wird.
         */
        plz: 'plz',
        /**
         * Anzahl der Ergebnisse, die zurückgeliefert werden sollen.
         */
        anzahl: 'anzahl',
        /**
         * Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * `EN` - Englisch   * `DE` - Deutsch   * `FR` - Französisch   * `ES` - Spanisch   * `PT` - Portugiesisch   * `IT` - Italienisch   * `NL` - Niederländisch   * `PL` - Polnisch   * `RU` - Russisch   * `JP` - Japanisch   * `ZH` - Chinesisch 
         */
        sprache: 'sprache'
    },

    /**
     * Available query parameters for getWspCompetences.
     */
    getWspCompetences: {
        /**
         * Der Leistungsschlüssel nach dem gesucht wird.
         */
        leistungsSchluessel: 'leistungsSchluessel',
        /**
         * Der Mandant nach dem gesucht wird. Hier kann nach einer bestimmten Stadt gesucht werden (mandant=Stadt Coesfeld) oder nach allen Mandanten, wo der Suchbegriff vorkommt \"mandant=coesfeld\" liefert Ergebnisse für den Kreis als auch für die Stadt.
         */
        mandant: 'mandant',
        /**
         * Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * `EN` - Englisch   * `DE` - Deutsch   * `FR` - Französisch   * `ES` - Spanisch   * `PT` - Portugiesisch   * `IT` - Italienisch   * `NL` - Niederländisch   * `PL` - Polnisch   * `RU` - Russisch   * `JP` - Japanisch   * `ZH` - Chinesisch 
         */
        sprache: 'sprache'
    }

}

/**
 * DefaultService - axios parameter creator
 * @export
 */
export const DefaultServiceAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Diese API wird eingesetzt, um die Zuständigkeit für eine Leistung innerhalb einer Region zu ermitteln.
         * @summary Ermittlung der Zuständigkeiten.
         * @param {string} leistungsSchluessel Der Leistungsschlüssel nach dem gesucht wird.
         * @param {string} [regionalSchluessel] Der Regionalschlüssel der Region, in der gesucht werden soll.
         * @param {string} [plz] Die Postleitzahl, die für die Suche von Zuständigkeiten aus fremden Quellen, z. B. DVDS (Finanzämter) und KiTas (KiTa-Finder NRW), verwendet wird.
         * @param {string} [anzahl] Anzahl der Ergebnisse, die zurückgeliefert werden sollen.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetences: async (leistungsSchluessel: string, regionalSchluessel?: string, plz?: string, anzahl?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'leistungsSchluessel' is not null or undefined
            assertParamExists('getCompetences', 'leistungsSchluessel', leistungsSchluessel)
            const localVarPath = `/zustaendigkeiten`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (leistungsSchluessel !== undefined) {
                localVarQueryParameter['leistungsSchluessel'] = leistungsSchluessel;
            }
            if (regionalSchluessel !== undefined) {
                localVarQueryParameter['regionalSchluessel'] = regionalSchluessel;
            }
            if (plz !== undefined) {
                localVarQueryParameter['plz'] = plz;
            }
            if (anzahl !== undefined) {
                localVarQueryParameter['anzahl'] = anzahl;
            }
            if (sprache !== undefined) {
                localVarQueryParameter['sprache'] = sprache;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Diese API wird eingesetzt, um alle WSP relevanten Zuständigkeiten zu ermitteln.
         * @summary Ermittlung aller WSP relevantent Zuständigkeiten.
         * @param {string} [leistungsSchluessel] Der Leistungsschlüssel nach dem gesucht wird.
         * @param {string} [mandant] Der Mandant nach dem gesucht wird. Hier kann nach einer bestimmten Stadt gesucht werden (mandant&#x3D;Stadt Coesfeld) oder nach allen Mandanten, wo der Suchbegriff vorkommt \&quot;mandant&#x3D;coesfeld\&quot; liefert Ergebnisse für den Kreis als auch für die Stadt.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWspCompetences: async (leistungsSchluessel?: string, mandant?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options: RequestOptions = {}): Promise<RequestArgs> => {
            const localVarPath = `/wsp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (leistungsSchluessel !== undefined) {
                localVarQueryParameter['leistungsSchluessel'] = leistungsSchluessel;
            }
            if (mandant !== undefined) {
                localVarQueryParameter['mandant'] = mandant;
            }
            if (sprache !== undefined) {
                localVarQueryParameter['sprache'] = sprache;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Cancel tokens of pending requests. One cancel token per service function.
 */
const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * DefaultService - functional programming interface
 * @export
 */
export const DefaultServiceFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultServiceAxiosParamCreator(configuration)
    return {
        /**
         * Diese API wird eingesetzt, um die Zuständigkeit für eine Leistung innerhalb einer Region zu ermitteln.
         * @summary Ermittlung der Zuständigkeiten.
         * @param {string} leistungsSchluessel Der Leistungsschlüssel nach dem gesucht wird.
         * @param {string} [regionalSchluessel] Der Regionalschlüssel der Region, in der gesucht werden soll.
         * @param {string} [plz] Die Postleitzahl, die für die Suche von Zuständigkeiten aus fremden Quellen, z. B. DVDS (Finanzämter) und KiTas (KiTa-Finder NRW), verwendet wird.
         * @param {string} [anzahl] Anzahl der Ergebnisse, die zurückgeliefert werden sollen.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompetences(leistungsSchluessel: string, regionalSchluessel?: string, plz?: string, anzahl?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZustaendigkeitAntwortJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompetences(leistungsSchluessel, regionalSchluessel, plz, anzahl, sprache, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['getCompetences'],
                set: (token: CancelTokenSource) => cancelTokens['getCompetences'] = token,
              },
              configuration
            );
        },
        /**
         * Diese API wird eingesetzt, um alle WSP relevanten Zuständigkeiten zu ermitteln.
         * @summary Ermittlung aller WSP relevantent Zuständigkeiten.
         * @param {string} [leistungsSchluessel] Der Leistungsschlüssel nach dem gesucht wird.
         * @param {string} [mandant] Der Mandant nach dem gesucht wird. Hier kann nach einer bestimmten Stadt gesucht werden (mandant&#x3D;Stadt Coesfeld) oder nach allen Mandanten, wo der Suchbegriff vorkommt \&quot;mandant&#x3D;coesfeld\&quot; liefert Ergebnisse für den Kreis als auch für die Stadt.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWspCompetences(leistungsSchluessel?: string, mandant?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WspZustaendigkeitAntwortJson>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWspCompetences(leistungsSchluessel, mandant, sprache, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['getWspCompetences'],
                set: (token: CancelTokenSource) => cancelTokens['getWspCompetences'] = token,
              },
              configuration
            );
        },
    }
};

/**
 * DefaultService - factory interface
 * @export
 */
export const DefaultServiceFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultServiceFp(configuration)
    return {
        /**
         * Diese API wird eingesetzt, um die Zuständigkeit für eine Leistung innerhalb einer Region zu ermitteln.
         * @summary Ermittlung der Zuständigkeiten.
         * @param {string} leistungsSchluessel Der Leistungsschlüssel nach dem gesucht wird.
         * @param {string} [regionalSchluessel] Der Regionalschlüssel der Region, in der gesucht werden soll.
         * @param {string} [plz] Die Postleitzahl, die für die Suche von Zuständigkeiten aus fremden Quellen, z. B. DVDS (Finanzämter) und KiTas (KiTa-Finder NRW), verwendet wird.
         * @param {string} [anzahl] Anzahl der Ergebnisse, die zurückgeliefert werden sollen.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompetences(leistungsSchluessel: string, regionalSchluessel?: string, plz?: string, anzahl?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): AxiosPromise<ZustaendigkeitAntwortJson> {
            return localVarFp.getCompetences(leistungsSchluessel, regionalSchluessel, plz, anzahl, sprache, options).then((request) => request(axios, basePath));
        },
        /**
         * Diese API wird eingesetzt, um alle WSP relevanten Zuständigkeiten zu ermitteln.
         * @summary Ermittlung aller WSP relevantent Zuständigkeiten.
         * @param {string} [leistungsSchluessel] Der Leistungsschlüssel nach dem gesucht wird.
         * @param {string} [mandant] Der Mandant nach dem gesucht wird. Hier kann nach einer bestimmten Stadt gesucht werden (mandant&#x3D;Stadt Coesfeld) oder nach allen Mandanten, wo der Suchbegriff vorkommt \&quot;mandant&#x3D;coesfeld\&quot; liefert Ergebnisse für den Kreis als auch für die Stadt.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWspCompetences(leistungsSchluessel?: string, mandant?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): AxiosPromise<WspZustaendigkeitAntwortJson> {
            return localVarFp.getWspCompetences(leistungsSchluessel, mandant, sprache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultService - interface
 * @export
 * @interface DefaultService
 */
export interface DefaultServiceInterface {
    /**
     * Diese API wird eingesetzt, um die Zuständigkeit für eine Leistung innerhalb einer Region zu ermitteln.
     * @summary Ermittlung der Zuständigkeiten.
     * @param {string} leistungsSchluessel Der Leistungsschlüssel nach dem gesucht wird.
     * @param {string} [regionalSchluessel] Der Regionalschlüssel der Region, in der gesucht werden soll.
     * @param {string} [plz] Die Postleitzahl, die für die Suche von Zuständigkeiten aus fremden Quellen, z. B. DVDS (Finanzämter) und KiTas (KiTa-Finder NRW), verwendet wird.
     * @param {string} [anzahl] Anzahl der Ergebnisse, die zurückgeliefert werden sollen.
     * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultServiceInterface
     */
    getCompetences(leistungsSchluessel: string, regionalSchluessel?: string, plz?: string, anzahl?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): AxiosPromise<ZustaendigkeitAntwortJson>;

    /**
     * Diese API wird eingesetzt, um alle WSP relevanten Zuständigkeiten zu ermitteln.
     * @summary Ermittlung aller WSP relevantent Zuständigkeiten.
     * @param {string} [leistungsSchluessel] Der Leistungsschlüssel nach dem gesucht wird.
     * @param {string} [mandant] Der Mandant nach dem gesucht wird. Hier kann nach einer bestimmten Stadt gesucht werden (mandant&#x3D;Stadt Coesfeld) oder nach allen Mandanten, wo der Suchbegriff vorkommt \&quot;mandant&#x3D;coesfeld\&quot; liefert Ergebnisse für den Kreis als auch für die Stadt.
     * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultServiceInterface
     */
    getWspCompetences(leistungsSchluessel?: string, mandant?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): AxiosPromise<WspZustaendigkeitAntwortJson>;

}

/**
 * DefaultService - object-oriented interface
 * @export
 * @class DefaultService
 * @extends {BaseAPI}
 */
export class DefaultService extends BaseAPI implements DefaultServiceInterface {
    /**
     * Diese API wird eingesetzt, um die Zuständigkeit für eine Leistung innerhalb einer Region zu ermitteln.
     * @summary Ermittlung der Zuständigkeiten.
     * @param {string} leistungsSchluessel Der Leistungsschlüssel nach dem gesucht wird.
     * @param {string} [regionalSchluessel] Der Regionalschlüssel der Region, in der gesucht werden soll.
     * @param {string} [plz] Die Postleitzahl, die für die Suche von Zuständigkeiten aus fremden Quellen, z. B. DVDS (Finanzämter) und KiTas (KiTa-Finder NRW), verwendet wird.
     * @param {string} [anzahl] Anzahl der Ergebnisse, die zurückgeliefert werden sollen.
     * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultService
     */
    public getCompetences(leistungsSchluessel: string, regionalSchluessel?: string, plz?: string, anzahl?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions) {
        return DefaultServiceFp(this.configuration).getCompetences(leistungsSchluessel, regionalSchluessel, plz, anzahl, sprache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Diese API wird eingesetzt, um alle WSP relevanten Zuständigkeiten zu ermitteln.
     * @summary Ermittlung aller WSP relevantent Zuständigkeiten.
     * @param {string} [leistungsSchluessel] Der Leistungsschlüssel nach dem gesucht wird.
     * @param {string} [mandant] Der Mandant nach dem gesucht wird. Hier kann nach einer bestimmten Stadt gesucht werden (mandant&#x3D;Stadt Coesfeld) oder nach allen Mandanten, wo der Suchbegriff vorkommt \&quot;mandant&#x3D;coesfeld\&quot; liefert Ergebnisse für den Kreis als auch für die Stadt.
     * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultService
     */
    public getWspCompetences(leistungsSchluessel?: string, mandant?: string, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions) {
        return DefaultServiceFp(this.configuration).getWspCompetences(leistungsSchluessel, mandant, sprache, options).then((request) => request(this.axios, this.basePath));
    }
}
