/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { Geokodierung, GeokodierungProperties } from './geokodierung';
// @ts-ignore
import { TypModel, TypModelProperties } from './typ-model';

/**
 * 
 * @export
 * @interface AnschriftModel
 */
export interface AnschriftModel {
    /**
     * 
     * @type {TypModel}
     * @memberof AnschriftModel
     */
    'typ'?: TypModel;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'strasse'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'hausnummer'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'postfach'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'postleitzahl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'ort'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'zusatz'?: string;
    /**
     * 
     * @type {Geokodierung}
     * @memberof AnschriftModel
     */
    'geokodierung'?: Geokodierung;
    /**
     * 
     * @type {string}
     * @memberof AnschriftModel
     */
    'anfahrturl'?: string;
}

/**
 * All properties of AnschriftModel.
 * @export
 * @enum {string}
 */
export enum AnschriftModelProperties {
    typ = 'typ',
    strasse = 'strasse',
    hausnummer = 'hausnummer',
    postfach = 'postfach',
    postleitzahl = 'postleitzahl',
    ort = 'ort',
    zusatz = 'zusatz',
    geokodierung = 'geokodierung',
    anfahrturl = 'anfahrturl'
}


