/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { AnschriftModel, AnschriftModelProperties } from './anschrift-model';
// @ts-ignore
import { KommunikationsModel, KommunikationsModelProperties } from './kommunikations-model';
// @ts-ignore
import { KontaktpersonModel, KontaktpersonModelProperties } from './kontaktperson-model';

/**
 * 
 * @export
 * @interface OrganisationsModel
 */
export interface OrganisationsModel {
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'identifikator'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'mandant'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'mandantID'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'beschreibung'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'kurbeschreibung'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'infoOeffnungszeitenText'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'infoParkplatz'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'infoOEPNV'?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'infoBarrierefreiheit'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationsModel
     */
    'kennzeichenRollstuhlgerecht'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisationsModel
     */
    'gebietID'?: Array<string>;
    /**
     * 
     * @type {Array<KontaktpersonModel>}
     * @memberof OrganisationsModel
     */
    'kontaktperson'?: Array<KontaktpersonModel>;
    /**
     * 
     * @type {Array<AnschriftModel>}
     * @memberof OrganisationsModel
     */
    'anschrift'?: Array<AnschriftModel>;
    /**
     * 
     * @type {Array<KommunikationsModel>}
     * @memberof OrganisationsModel
     */
    'kommunikation'?: Array<KommunikationsModel>;
    /**
     * 
     * @type {string}
     * @memberof OrganisationsModel
     */
    'rolle'?: string;
}

/**
 * All properties of OrganisationsModel.
 * @export
 * @enum {string}
 */
export enum OrganisationsModelProperties {
    name = 'name',
    identifikator = 'identifikator',
    mandant = 'mandant',
    mandantID = 'mandantID',
    beschreibung = 'beschreibung',
    kurbeschreibung = 'kurbeschreibung',
    infoOeffnungszeitenText = 'infoOeffnungszeitenText',
    infoParkplatz = 'infoParkplatz',
    infoOEPNV = 'infoOEPNV',
    infoBarrierefreiheit = 'infoBarrierefreiheit',
    kennzeichenRollstuhlgerecht = 'kennzeichenRollstuhlgerecht',
    gebietID = 'gebietID',
    kontaktperson = 'kontaktperson',
    anschrift = 'anschrift',
    kommunikation = 'kommunikation',
    rolle = 'rolle'
}


