/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface StartPaymentBody
 */
export interface StartPaymentBody {
    /**
     * 
     * @type {string}
     * @memberof StartPaymentBody
     */
    'redirect': string;
    /**
     * 
     * @type {string}
     * @memberof StartPaymentBody
     */
    'method'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartPaymentBody
     */
    'accountOwner'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartPaymentBody
     */
    'iban'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StartPaymentBody
     */
    'forceNew'?: boolean;
}

/**
 * All properties of StartPaymentBody.
 * @export
 * @enum {string}
 */
export enum StartPaymentBodyProperties {
    redirect = 'redirect',
    method = 'method',
    accountOwner = 'accountOwner',
    iban = 'iban',
    forceNew = 'forceNew'
}


