












/**
 * TheLogoBar.vue
 *
 * This component includes the page name and its logo
 * Component call:
 *   <TheLogoBar />
 */
import { Component } from 'nuxt-property-decorator';
import { RouteNames } from '@generated/routeNames';
import LogoComponent from './Logo';

@Component({ name: 'the-logo-bar' })
export default class TheLogoBarComponent extends LogoComponent {
  get homePath(): string {
    return this.localePath(RouteNames.INDEX);
  }
}
