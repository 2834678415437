import { Component, Watch } from 'nuxt-property-decorator';
import {
  // CircumstancesCircumstanceRouteParams,
  // ContentIdRouteParams,
  RouteNames,
  ServiceLeikaidRouteParams,
  // TopicsTopicRouteParams,
} from '@generated/routeNames';
import { SearchAPI, SearchResult, SearchValue } from '@/interfaces/vsm';
// import { CustomizedLink } from '@/interfaces/name-with-link';
// import { Service } from '@/interfaces/service';
// import { Portal } from '@/interfaces/portal';
import { WebTreffer, WebTrefferJsonAntwort } from '@generated/web_such';
import { LeikaResponse } from '@generated/leika';
import { LocationSearch } from './LocationSearch';

/**
 * Provides common methods for the search
 */
@Component
export class BaseSearch extends LocationSearch {
  readonly nrOfWebSearchResultsToFetch = 10;

  searchTopic: SearchValue = { value: '', suggestion: null };
  sortedSearchResults: SearchResult[] = [];
  componentLocation = 'homepage';
  lastAutoSuggestSearchSearchTerm = '';

  get storedTopic(): SearchValue {
    return this.$searchModule.topic;
  }

  /**
   * Handles the input emitted on input and on focus of the input field
   * @param searchTopic - Object containing search value and suggestion
   */
  onSearchTopicInput(searchTopic: SearchValue): void {
    this.searchTopic = searchTopic;
    if (searchTopic.suggestion) {
      if (searchTopic.value !== this.lastAutoSuggestSearchSearchTerm) {
        this.sortedSearchResults = [];
      }
    } else if (searchTopic.value !== this.lastAutoSuggestSearchSearchTerm) {
      this.searchTopicChange();
    }
  }

  /**
   * onStoredTopicChanged(value: string)
   *
   * if the topic store is updated we will assign the new store
   * value to the searchTopic field only in the search results page
   *
   * @param value - changed topic store
   */
  @Watch('storedTopic')
  onStoredTopicChanged(value: SearchValue): void {
    if (this.componentLocation === 'results') {
      this.searchTopic = value;
    }
  }

  /**
   * searchTopicChange()
   *
   * event handler method to interact with child component search input to get topic related
   * auto suggestion with debounce functionality
   */
  searchTopicChange(): void {
    clearTimeout(this.timerID);
    // this.timerID = setTimeout(this.searchTopicHandler, this.sleepTime);
  }

  // /**
  //  * searchTopicHandler()
  //  *
  //  * event handler method to interact with child component search input to get topic related
  //  * auto suggestion
  //  * start fetching data for more than two given characters and add four elements for
  //  *   - leika api
  //  *   - web search api
  //  *   - full search api
  //  * to the topic auto suggestion
  //  * used as callback method in setTimeout
  //  */
  // async searchTopicHandler(): Promise<void> {
  //   this.sortedSearchResults = [];
  //   if (this.searchTopic.value.length > 2) {
  //     const {
  //       resultsFullSearch,
  //       resultsLeikaWithoutDuplicates,
  //       resultsWebSearch,
  //     } = await this.fetchAndBuildSearchResults(this.searchTopic.value, this.nrOfWebSearchResultsToFetch, 1);
  //     this.lastAutoSuggestSearchSearchTerm = this.searchTopic.value;
  //     if (resultsFullSearch && resultsLeikaWithoutDuplicates && resultsWebSearch) {
  //       // this.sortedSearchResults = this.$searchSortingService.sortAutoSuggestResults(
  //       //   resultsFullSearch,
  //       //   resultsLeikaWithoutDuplicates,
  //       //   resultsWebSearch
  //       // );
  //     }
  //   }
  // }

  // /**
  //  * Fetches and builds the search results from CMS, Leika and Web Search
  //  * @param searchTopicValue - The search term
  //  * @param nrOfWebSearchResultsToFetch - The number of web results to fetch
  //  * @param page - The page to start fetching the number of web results from
  //  */
  // async fetchAndBuildSearchResults(
  //   searchTopicValue: string,
  //   nrOfWebSearchResultsToFetch: number,
  //   page: number
  // ): Promise<{
  //   resultsFullSearch: SearchResult[];
  //   resultsLeikaWithoutDuplicates: SearchResult[];
  //   resultsWebSearch: SearchResult[];
  //   resultsWebSearchData: WebTrefferJsonAntwort;
  // }> {
  //   const [resultsFullSearch, resultsLeika, resultsWebSearchData] = await Promise.all([
  //     this.fetchAndBuildFullSearchSearchResults(searchTopicValue),
  //     this.fetchAndBuildLeikaSearchResults(searchTopicValue),
  //     this.fetchWebSearchAPI(searchTopicValue, nrOfWebSearchResultsToFetch, page),
  //   ]);
  //   let resultsWebSearch: SearchResult[] = [];

  //   if (resultsWebSearchData.daten) {
  //     resultsWebSearch = this.buildWebSearchSearchResults(resultsWebSearchData.daten);
  //   }
  //   let resultsLeikaWithoutDuplicates: SearchResult[] = [];
  //   if (resultsFullSearch && resultsLeika) {
  //     resultsLeikaWithoutDuplicates = this.removeDuplicateLeikaServices(resultsFullSearch, resultsLeika);
  //   }
  //   return {
  //     resultsFullSearch,
  //     resultsLeikaWithoutDuplicates,
  //     resultsWebSearch,
  //     resultsWebSearchData,
  //   };
  // }

  // /**
  //  * Fetches and builds the full search search results
  //  * @param searchTopicValue - The search term
  //  * @returns The Promise of the search results
  //  */
  // private async fetchAndBuildFullSearchSearchResults(searchTopicValue: string): Promise<SearchResult[]> {
  //   const fullSearchResults = ((await this.fetchFullSearchAPI(searchTopicValue)) || []).filter(
  //     (fullSearchResult) => fullSearchResult.title
  //   );

  //   const cmsServices = await Promise.all(
  //     fullSearchResults.map((result) =>
  //       result.type === ContentType.service ? this.fetchPortalServiceAPI(result.uuid) : undefined
  //     )
  //   );

  //   const cmsLinks = await Promise.all(
  //     fullSearchResults.map((fullSearchResult, index) => this.createCmsLink(fullSearchResult, cmsServices[index]))
  //   );

  //   return fullSearchResults.map<SearchResult>((fullSearchResult, index) => ({
  //     name: fullSearchResult.title,
  //     description: fullSearchResult.body.processed,
  //     key: fullSearchResult.uuid,
  //     link: cmsLinks[index],
  //     type: fullSearchResult.type === ContentType.service ? SearchAPI.service : SearchAPI.cms,
  //     raw: fullSearchResult,
  //     cmsService: cmsServices[index],
  //     leikaId: cmsServices[index]?.fieldVsmLeistungId,
  //     leikaServiceTitle: cmsServices[index]?.title,
  //     image: fullSearchResult.image,
  //   }));
  // }

  /**
   * Fetches and builds the leika search results
   * @param searchTopicValue - The search term
   * @returns The promise of the search results
   */
  private async fetchAndBuildLeikaSearchResults(searchTopicValue: string): Promise<SearchResult[]> {
    return (await this.fetchLeikaAPI(searchTopicValue))
      .filter((leikaResult) => leikaResult.Bezeichnung)
      .map((leikaResult) => {
        const res: SearchResult = {
          name: leikaResult?.Bezeichnung || '',
          key: leikaResult?.Schluessel,
          description: leikaResult?.Kurztext,
          type: SearchAPI.service,
          raw: leikaResult,
          leikaId: leikaResult.Schluessel,
          leikaServiceTitle: leikaResult.Bezeichnung,
        };

        if (leikaResult?.Schluessel) {
          res.link = {
            name: RouteNames.SERVICE_LEIKAID,
            params: { [ServiceLeikaidRouteParams.LEIKAID as string]: leikaResult.Schluessel },
          };
        }

        return res;
      });
  }

  /**
   * Builds the web search search results from the web search results
   * @param webSearchResults - The web search results
   * @returns The web search search results
   */
  buildWebSearchSearchResults(webSearchResults: WebTreffer[]): SearchResult[] {
    return webSearchResults
      .filter((webSearchResult) => webSearchResult.titel)
      .map<SearchResult>((webSearchResult) => this.buildWebSearchSearchResult(webSearchResult));
  }

  /**
   * Builds a web search search result from the web search results
   * @param webSearchResult - The web search result
   * @returns The web search search result
   */
  private buildWebSearchSearchResult(webSearchResult: WebTreffer): SearchResult {
    return {
      name: webSearchResult?.titel || '',
      description: webSearchResult.snippet,
      key: webSearchResult.id,
      link: webSearchResult.url,
      type: SearchAPI.webResult,
      raw: webSearchResult,
    };
  }

  /**
   * Removes duplicate leika services from the leika search results
   * @param fullSearchSearchResults - The full search search results
   * @param leikaSearchResults - The leika search results
   * @returns The leika search results without the duplicates
   */
  private removeDuplicateLeikaServices(
    fullSearchSearchResults: SearchResult[],
    leikaSearchResults: SearchResult[]
  ): SearchResult[] {
    const cmsServices = fullSearchSearchResults.filter((fullSearchSearchResult) => fullSearchSearchResult.cmsService);
    for (const cmsService of cmsServices) {
      leikaSearchResults = leikaSearchResults.filter(
        (leikaSearchResult) => leikaSearchResult.key !== cmsService.leikaId
      );
    }
    return leikaSearchResults;
  }

  /**
   * fetchLeikaAPI()
   *
   * fetches from the leika api services
   *
   * @param topic - keyword for service search
   */
  async fetchLeikaAPI(topic: string): Promise<LeikaResponse> {
    try {
      return (await this.$leikaService.getLeika(topic))?.data || [];
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('fetchLeikaAPI error: ', err);
      return [];
    }
  }

  // /**
  //  * fetchPortalServiceAPI()
  //  *
  //  * fetches from the portal api services
  //  *
  //  * @param serviceId - id to get specific service information
  //  *
  //  * @returns specific service for given uuid from portal api or undefined
  //  */
  // private fetchPortalServiceAPI(serviceId: string): Promise<Service | undefined> {
  //   return this.$callApi<Service>('service', {
  //     urlParams: { SERVICE_ID: serviceId },
  //   });
  // }

  // /**
  //  * fetchPortalAPI()
  //  *
  //  * fetches from the portal api external teaser information
  //  *
  //  * @param portalId - id to get specific external portal information
  //  *
  //  * @returns specific external teaser info for given uuid from portal api or undefined
  //  */
  // private fetchPortalAPI(portalId: string): Promise<Portal | undefined> {
  //   return this.$callApi<Portal>('get-specific-portal', {
  //     urlParams: { PORTAL_ID: portalId },
  //   });
  // }

  /**
   * fetchWebSearchAPI()
   *
   * fetches from the web search api and return external search results
   * fetches only the first four elements
   *
   * @param topic - keyword for web search
   * @param resultNumber - amount of web search results to get from request
   * @param page - start number of item
   *
   * @returns list of searched keyword from external portals or undefined
   */
  async fetchWebSearchAPI(topic: string, resultNumber = 10, page = 1): Promise<WebTrefferJsonAntwort> {
    try {
      return (await this.$webSuchService?.webTrefferGet(topic, undefined, page, resultNumber))?.data || {};
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('fetchWebSearchAPI error: ', err);
      return {};
    }
  }

  // /**
  //  * fetchFullSearchAPI()
  //  *
  //  * fetches from the full search api and return search results from CMS
  //  *
  //  * @param topic - keyword for cms search
  //  *
  //  * @returns list of searched keyword from internal CMS or undefined
  //  */
  // private fetchFullSearchAPI(topic: string): Promise<FullSearch[] | undefined> {
  //   return this.$callApi<FullSearch[]>('full-search', {
  //     urlParams: { SEARCH_KEY: topic },
  //   });
  // }

  // /**
  //  * creates cms link. it depends on type of search result
  //  * @param result - search result element from the cms
  //  * @param cmsService - (optional)
  //  * @returns valid nuxt links for different pages
  //  */
  // private async createCmsLink(result: FullSearch, cmsService?: Service): Promise<CustomizedLink | string | undefined> {
  //   let link: CustomizedLink | string | undefined = {} as CustomizedLink;
  //   switch (result.type) {
  //     case ContentType.service: {
  //       if (cmsService && cmsService.fieldVsmLeistungId) {
  //         link = {
  //           name: RouteNames.SERVICE_LEIKAID,
  //           params: { [ServiceLeikaidRouteParams.LEIKAID]: cmsService.fieldVsmLeistungId },
  //         };
  //       }
  //       break;
  //     }
  //     case ContentType.information: {
  //       link = {
  //         name: RouteNames.CONTENT_ID,
  //         params: { [ContentIdRouteParams.ID]: result.uuid },
  //       };
  //       break;
  //     }
  //     case ContentType.circumstances: {
  //       link = {
  //         name: RouteNames.CIRCUMSTANCES_CIRCUMSTANCE,
  //         params: { [CircumstancesCircumstanceRouteParams.CIRCUMSTANCE]: result.uuid },
  //       };
  //       break;
  //     }
  //     case ContentType.externalTeaser: {
  //       // const teaser: Portal | undefined = await this.fetchPortalAPI(result.uuid);
  //       link = '#';
  //       break;
  //     }
  //     case ContentType.topic: {
  //       link = {
  //         name: RouteNames.TOPICS_TOPIC,
  //         params: { [TopicsTopicRouteParams.TOPIC]: result.uuid },
  //       };
  //       break;
  //     }
  //     default:
  //       link = undefined;
  //   }
  //   return link;
  // }

  /**
   * submit()
   *
   * stores topic and location search keywords in store and routes to the search result page
   * cleans search topic in each component except on search result page
   */
  submit(): void {
    this.submitLocation();
    this.$searchModule.updateTopic(this.searchTopic);

    if (this.componentLocation !== 'results') {
      this.searchTopic = { value: '', suggestion: null };
      this.sortedSearchResults = [];
    }

    this.$router.push(
      this.localePath({
        name: RouteNames.SEARCH,
        query: { key: encodeURIComponent(this.storedTopic.value) },
      })
    );
  }

  /**
   * Resets the topic in the store
   */
  resetTopic(): void {
    this.$searchModule.resetTopic();
  }
}
