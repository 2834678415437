import Vue from 'vue';
import { blockPlugin } from './block.plugin';

declare module 'vue/types/vue' {
  interface Vue {
    $block: (target: string) => void;
    $unblock: (target: string) => void;
  }
}

Vue.use(blockPlugin);
