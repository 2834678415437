













import { DEFAULT_LAYOUT_STYLE_OBJECT_KEY } from '@/interfaces/default-layout-types';
import { Component, ProvideReactive, Vue, Watch } from 'nuxt-property-decorator';
import TheMandatoryFooter from '../components/footer/TheMandatoryFooter.vue';
import TheLogoBar from '../components/header/TheLogoBar.vue';
import TheMainHeader from '../components/header/TheMainHeader.vue';
import TheNavigation from '../components/header/TheNavigation.vue';

/**
 * Default layout for the whole application.
 */
@Component({
  components: {
    TheMainHeader,
    TheLogoBar,
    TheNavigation,
    TheMandatoryFooter,
  },
})
export default class Default extends Vue {
  /**
   * If the current locale changes, we need to update the vuex store for topic fields
   */
  @Watch('$i18n.locale')
  async onCurrentLocaleChange(): Promise<void> {
    await this.$fetch();
  }

  /**
   * provide a style object to change the styling of default layout from child components using inject()
   * more style attributes or classes can be added here on demand
   *
   * HINT: a cleaner solution would be something similar to the dialog-plugin.
   */
  @ProvideReactive(DEFAULT_LAYOUT_STYLE_OBJECT_KEY) defaultLayerStyleObj = {
    zIndex: 'z-20',
  };
}
