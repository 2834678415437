/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Der Typ des Kommunikationskanals.   * 001:  Telefon (alias telefon)   * 002:  Mobil (alias mobil)   * 003:  Fax (alias fax)   * 004:  E-Mail-Adresse (alias email) 
 * @export
 * @enum {string}
 */
export enum KanalModel {
    _001 = '001',
    _002 = '002',
    _003 = '003',
    _004 = '004',
    Telefon = 'telefon',
    Mobil = 'mobil',
    Fax = 'fax',
    Email = 'email'
}



