







import { Vue, Component, Prop } from 'nuxt-property-decorator';
import icons from '@/static/icons.json';

@Component({
  name: 'AppIcon',
})
/**
 * TODO https://jira.t-systems-mms.eu/browse/DETOZGNRW-1884
 * refactoring icons using fontawesome icon
 */
export default class AppIconComponent extends Vue {
  @Prop() readonly name: string | undefined;

  // use font awesome icon class
  @Prop() readonly fontAwesomeClass: string | undefined;
  @Prop({ default: 24 }) readonly width!: number;
  @Prop({ default: 24 }) readonly height!: number;

  get viewBox(): string {
    return '0 0 ' + this.width + ' ' + this.height;
  }

  get path(): string {
    // Here we use 'as any' because of the implicit error: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type
    return this.name ? (icons as any)[this.name] : '';
  }
}
