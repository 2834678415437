/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { AcrLevel, AcrLevelProperties } from './acr-level';

/**
 * 
 * @export
 * @interface UserAndMetadataDtoLoginMetadata
 */
export interface UserAndMetadataDtoLoginMetadata {
    /**
     * 
     * @type {Array<string>}
     * @memberof UserAndMetadataDtoLoginMetadata
     */
    'scopes'?: Array<string>;
    /**
     * 
     * @type {AcrLevel}
     * @memberof UserAndMetadataDtoLoginMetadata
     */
    'level'?: AcrLevel;
    /**
     * 
     * @type {boolean}
     * @memberof UserAndMetadataDtoLoginMetadata
     */
    'anon'?: boolean;
}

/**
 * All properties of UserAndMetadataDtoLoginMetadata.
 * @export
 * @enum {string}
 */
export enum UserAndMetadataDtoLoginMetadataProperties {
    scopes = 'scopes',
    level = 'level',
    anon = 'anon'
}


