import Vue, { DirectiveOptions } from 'vue';

/**
 * Plugin to disable a control.
 */
export const blockPlugin = {
  install(vue: typeof Vue): void {
    // prepare prototype
    const prototype = vue.prototype as {
      $block: (target: string) => void;
      $unblock: (target: string) => void;
    } & Vue;
    // state
    const counts: { [key: string]: number } = {};

    /**
     * Blocks all with target marked controls.
     *
     * @param target - target to block
     */
    prototype.$block = (target: string): void => {
      if (!counts[target]) {
        counts[target] = 0;
        window.dispatchEvent(new CustomEvent(`block:${target}`, { detail: true }));
      }
      counts[target]++;
    };

    /**
     * Unblocks all with target marked controls.
     *
     * @param target - target to unblock
     */
    prototype.$unblock = (target: string): void => {
      if (counts[target] === 1) {
        window.dispatchEvent(new CustomEvent(`block:${target}`, { detail: false }));
      }
      if (counts[target] > 0) {
        counts[target]--;
      }
    };

    /**
     * Directive to handle block events
     */
    const loading: DirectiveOptions = {
      bind(el: any, binding) {
        el.__loadingHandler = (event: CustomEvent): void => {
          if (event.detail) {
            el.__oldDisabled = el.disabled;
          }
          el.disabled = !!(el.__oldDisabled || event.detail);
        };
        window.addEventListener(`block:${binding.value}`, el.__loadingHandler);
      },
      unbind(el: any, binding) {
        window.removeEventListener(`block:${binding.value}`, el.__loadingHandler);
      },
    };

    vue.directive('block', loading);
  },
};
