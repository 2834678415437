/**
 * With this plugin we provide all our store modules to all vue instances and also to the nuxt context to have an easy access way.
 *
 * To add a new module it is necessary to extend both, the Vue and the Context interface, and inject the module inside
 * the plugin-function.
 */
import { Plugin } from '@nuxt/types';
import { getModule } from 'nuxt-property-decorator';
import AccountModule from '@/store/account';
import SearchModule from '@/store/search';
import SearchTopicFieldsModule from '@/store/search-topic-fields';
import { Store } from 'vuex/types/index';

/** declare new vuex store modules to all vue instances */
declare module 'vue/types/vue' {
  interface Vue {
    $accountModule: AccountModule;
    $searchModule: SearchModule;
    $searchTopicFieldsModule: SearchTopicFieldsModule;
  }
}

/** declare the store to all vuex store modules */
declare module 'vuex-module-decorators/dist/types' {
  interface VuexModule<S = ThisType<any>> {
    store: Store<S>;
  }
}

/** declare new vuex store modules to the nuxt context */
declare module '@nuxt/types' {
  interface Context {
    $accountModule: AccountModule;
    $searchModule: SearchModule;
    $searchTopicFieldsModule: SearchTopicFieldsModule;
  }
}

/** vue plugin to add all vuex store modules */
const StorePlugin: Plugin = ({ store }, inject) => {
  inject('accountModule', getModule(AccountModule, store));
  inject('searchModule', getModule(SearchModule, store));
  inject('searchTopicFieldsModule', getModule(SearchTopicFieldsModule, store));
};

export default StorePlugin;
