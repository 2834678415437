/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { AcrLevel, AcrLevelProperties } from './acr-level';
// @ts-ignore
import { FMSType, FMSTypeProperties } from './fmstype';
// @ts-ignore
import { FeeType, FeeTypeProperties } from './fee-type';
// @ts-ignore
import { ScopeDto, ScopeDtoProperties } from './scope-dto';

/**
 * 
 * @export
 * @interface FormMetadataInfoDto
 */
export interface FormMetadataInfoDto {
    /**
     * 
     * @type {string}
     * @memberof FormMetadataInfoDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FormMetadataInfoDto
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormMetadataInfoDto
     */
    'leiKaIds': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FormMetadataInfoDto
     */
    'authorityKeys': Array<string>;
    /**
     * 
     * @type {ScopeDto}
     * @memberof FormMetadataInfoDto
     */
    'scopes': ScopeDto;
    /**
     * 
     * @type {AcrLevel}
     * @memberof FormMetadataInfoDto
     */
    'acrLevel': AcrLevel;
    /**
     * 
     * @type {FMSType}
     * @memberof FormMetadataInfoDto
     */
    'fmsType': FMSType;
    /**
     * 
     * @type {FeeType}
     * @memberof FormMetadataInfoDto
     */
    'feeType': FeeType;
}

/**
 * All properties of FormMetadataInfoDto.
 * @export
 * @enum {string}
 */
export enum FormMetadataInfoDtoProperties {
    name = 'name',
    title = 'title',
    leiKaIds = 'leiKaIds',
    authorityKeys = 'authorityKeys',
    scopes = 'scopes',
    acrLevel = 'acrLevel',
    fmsType = 'fmsType',
    feeType = 'feeType'
}


