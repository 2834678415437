/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * AGS/ARS API
 * Eine API, um die Amtliche Gemeindeschlüssel(AGS) und Amtliche Regionalschlüssel (ARS) einer Ortschaft zu ermitteln.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: awemo@publicplan.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface OrtModel
 */
export interface OrtModel {
    /**
     * 
     * @type {string}
     * @memberof OrtModel
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrtModel
     */
    'regionalschluessel': string;
    /**
     * 
     * @type {string}
     * @memberof OrtModel
     */
    'gemeindeschluessel': string;
    /**
     * 
     * @type {string}
     * @memberof OrtModel
     */
    'verbandsschluessel': string;
    /**
     * 
     * @type {string}
     * @memberof OrtModel
     */
    'landesregierung': string;
    /**
     * 
     * @type {string}
     * @memberof OrtModel
     */
    'collectionschluessel'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrtModel
     */
    'postleitzahlen': Array<string>;
}

/**
 * All properties of OrtModel.
 * @export
 * @enum {string}
 */
export enum OrtModelProperties {
    name = 'name',
    regionalschluessel = 'regionalschluessel',
    gemeindeschluessel = 'gemeindeschluessel',
    verbandsschluessel = 'verbandsschluessel',
    landesregierung = 'landesregierung',
    collectionschluessel = 'collectionschluessel',
    postleitzahlen = 'postleitzahlen'
}


