/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * AGS/ARS API
 * Eine API, um die Amtliche Gemeindeschlüssel(AGS) und Amtliche Regionalschlüssel (ARS) einer Ortschaft zu ermitteln.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: awemo@publicplan.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { OrtModel, OrtModelProperties } from './ort-model';

/**
 * 
 * @export
 * @interface Ort
 */
export interface Ort {
    /**
     * 
     * @type {Array<OrtModel>}
     * @memberof Ort
     */
    'daten'?: Array<OrtModel>;
}

/**
 * All properties of Ort.
 * @export
 * @enum {string}
 */
export enum OrtProperties {
    daten = 'daten'
}


