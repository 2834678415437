import Vue from 'vue';
import {
  EmitEventType,
  ListenToEventsType,
  ListenToFocusType,
  navigationPlugin,
  RedirectType,
} from './navigation.plugin';

/** define context */
declare module 'vue/types/vue' {
  interface Vue {
    $redirect: RedirectType;
    $listenToEvents: ListenToEventsType;
    $emitEvent: EmitEventType;
    $listenToFocus: ListenToFocusType;
    $isFocusedWindow: () => boolean;
  }
}

/** install plugin */
Vue.use(navigationPlugin);
