








import { Vue, Component, Prop } from 'nuxt-property-decorator';
import AppIcon from '@/components/svg/AppIcon.vue';

/**
 * This component can be used to render a nuxt link together with a chevron right icon
 *
 * props:
 *  - iconClass a prop to pass css classes to the inner icon from parent component.
 */
@Component({ name: 'NavigationLink', components: { AppIcon } })
export default class NavigationLink extends Vue {
  @Prop() readonly iconClass?: string | string[] | Record<string, string>;
}
