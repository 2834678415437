/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { AdapterConfigTypesModel, AdapterConfigTypesModelProperties } from './adapter-config-types-model';

/**
 * 
 * @export
 * @interface AdapterConfigsModel
 */
export interface AdapterConfigsModel {
    /**
     * 
     * @type {string}
     * @memberof AdapterConfigsModel
     */
    'name'?: string;
    /**
     * 
     * @type {AdapterConfigTypesModel}
     * @memberof AdapterConfigsModel
     */
    'typ'?: AdapterConfigTypesModel;
    /**
     * 
     * @type {string}
     * @memberof AdapterConfigsModel
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdapterConfigsModel
     */
    'urlWeiterleitung'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdapterConfigsModel
     */
    'leistungsschluessel'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AdapterConfigsModel
     */
    'suchSeiteUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof AdapterConfigsModel
     */
    'suchSeiteUrlOhneOrt'?: string;
}

/**
 * All properties of AdapterConfigsModel.
 * @export
 * @enum {string}
 */
export enum AdapterConfigsModelProperties {
    name = 'name',
    typ = 'typ',
    url = 'url',
    urlWeiterleitung = 'urlWeiterleitung',
    leistungsschluessel = 'leistungsschluessel',
    suchSeiteUrl = 'suchSeiteUrl',
    suchSeiteUrlOhneOrt = 'suchSeiteUrlOhneOrt'
}


