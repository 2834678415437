/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LeistungsModel
 */
export interface LeistungsModel {
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'schluessel'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'leistungsbezeichnung'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'leistungsbezeichnung2'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LeistungsModel
     */
    'begriffeImKontext'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'kurztext'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'volltext'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'rechtsgrundlage'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'erforderlicheUnterlagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'voraussetzungen'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'kosten'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'verfahrensablauf'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'bearbeitungsdauer'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'fristen'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'formulare'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'weiterfuehrendeInformationen'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'hinweise'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'letzteAktualisierung'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'urheber'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'urlOnlineDienst'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'urlInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof LeistungsModel
     */
    'teaser'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeistungsModel
     */
    'wspRelevanz'?: boolean;
}

/**
 * All properties of LeistungsModel.
 * @export
 * @enum {string}
 */
export enum LeistungsModelProperties {
    schluessel = 'schluessel',
    leistungsbezeichnung = 'leistungsbezeichnung',
    leistungsbezeichnung2 = 'leistungsbezeichnung2',
    begriffeImKontext = 'begriffeImKontext',
    kurztext = 'kurztext',
    volltext = 'volltext',
    rechtsgrundlage = 'rechtsgrundlage',
    erforderlicheUnterlagen = 'erforderlicheUnterlagen',
    voraussetzungen = 'voraussetzungen',
    kosten = 'kosten',
    verfahrensablauf = 'verfahrensablauf',
    bearbeitungsdauer = 'bearbeitungsdauer',
    fristen = 'fristen',
    formulare = 'formulare',
    weiterfuehrendeInformationen = 'weiterfuehrendeInformationen',
    hinweise = 'hinweise',
    letzteAktualisierung = 'letzteAktualisierung',
    urheber = 'urheber',
    urlOnlineDienst = 'urlOnlineDienst',
    urlInfo = 'urlInfo',
    teaser = 'teaser',
    wspRelevanz = 'wspRelevanz'
}


