import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3ab6dfeb = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _43b859fe = () => interopDefault(import('../pages/form/index.vue' /* webpackChunkName: "pages/form/index" */))
const _cb77ecee = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _75e97d09 = () => interopDefault(import('../pages/pega/index.vue' /* webpackChunkName: "pages/pega/index" */))
const _39c39f96 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _76ed9a22 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _4dba1c63 = () => interopDefault(import('../pages/portals/index.vue' /* webpackChunkName: "pages/portals/index" */))
const _1d376188 = () => interopDefault(import('../pages/circumstances/_circumstance.vue' /* webpackChunkName: "pages/circumstances/_circumstance" */))
const _7313787d = () => interopDefault(import('../pages/content/_id.vue' /* webpackChunkName: "pages/content/_id" */))
const _01101053 = () => interopDefault(import('../pages/service/_leikaid.vue' /* webpackChunkName: "pages/service/_leikaid" */))
const _795addd8 = () => interopDefault(import('../pages/topics/_topic.vue' /* webpackChunkName: "pages/topics/_topic" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _3ab6dfeb,
    name: "index___en"
  }, {
    path: "/formular",
    component: _43b859fe,
    name: "form___de"
  }, {
    path: "/nutzerkonto",
    component: _cb77ecee,
    name: "account___de"
  }, {
    path: "/pega",
    component: _75e97d09,
    name: "pega___de"
  }, {
    path: "/redirect",
    component: _39c39f96,
    name: "redirect___de"
  }, {
    path: "/suche",
    component: _76ed9a22,
    name: "search___de"
  }, {
    path: "/themenportale",
    component: _4dba1c63,
    name: "portals___de"
  }, {
    path: "/en/account",
    component: _cb77ecee,
    name: "account___en"
  }, {
    path: "/en/form",
    component: _43b859fe,
    name: "form___en"
  }, {
    path: "/en/pega",
    component: _75e97d09,
    name: "pega___en"
  }, {
    path: "/en/portals",
    component: _4dba1c63,
    name: "portals___en"
  }, {
    path: "/en/redirect",
    component: _39c39f96,
    name: "redirect___en"
  }, {
    path: "/en/search",
    component: _76ed9a22,
    name: "search___en"
  }, {
    path: "/en/circumstances/:circumstance",
    component: _1d376188,
    name: "circumstances-circumstance___en"
  }, {
    path: "/en/content/:id",
    component: _7313787d,
    name: "content-id___en"
  }, {
    path: "/en/service/:leikaid",
    component: _01101053,
    name: "service-leikaid___en"
  }, {
    path: "/en/topics/:topic",
    component: _795addd8,
    name: "topics-topic___en"
  }, {
    path: "/inhalt/:id",
    component: _7313787d,
    name: "content-id___de"
  }, {
    path: "/lebenslagen/:circumstance",
    component: _1d376188,
    name: "circumstances-circumstance___de"
  }, {
    path: "/leistung/:leikaid",
    component: _01101053,
    name: "service-leikaid___de"
  }, {
    path: "/themenfelder/:topic",
    component: _795addd8,
    name: "topics-topic___de"
  }, {
    path: "/",
    component: _3ab6dfeb,
    name: "index___de"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
