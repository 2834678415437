/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { LeistungsModel, LeistungsModelProperties } from './leistungs-model';
// @ts-ignore
import { OrganisationsModel, OrganisationsModelProperties } from './organisations-model';

/**
 * 
 * @export
 * @interface CompetenceModel
 */
export interface CompetenceModel {
    /**
     * 
     * @type {LeistungsModel}
     * @memberof CompetenceModel
     */
    'leistung'?: LeistungsModel;
    /**
     * 
     * @type {Array<OrganisationsModel>}
     * @memberof CompetenceModel
     */
    'organisationseinheit'?: Array<OrganisationsModel>;
}

/**
 * All properties of CompetenceModel.
 * @export
 * @enum {string}
 */
export enum CompetenceModelProperties {
    leistung = 'leistung',
    organisationseinheit = 'organisationseinheit'
}


