import { Configuration, FormService, PaymentService, SessionService } from '@/generated/integration';
import { getApp } from '@/utils/composition-api.utils';
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';

let cache: {
  formService: FormService;
  sessionService: SessionService;
  paymentService: PaymentService;
};

export function useIntegrationServices(
  appConfig?: NuxtRuntimeConfig
): {
  formService: FormService;
  sessionService: SessionService;
  paymentService: PaymentService;
} {
  if (!cache) {
    appConfig = appConfig || getApp().$config;
    const config = new Configuration({
      basePath: process.server
        ? appConfig.integration || appConfig.integrationBrowser || '/'
        : appConfig.integrationBrowser || '/',
      baseOptions: { withCredentials: true },
    });
    cache = {
      formService: new FormService(config),
      sessionService: new SessionService(config),
      paymentService: new PaymentService(config),
    };
  }
  return cache;
}
