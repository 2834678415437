import Vue from 'vue';

/** declare new property */
declare module 'vue/types/vue' {
  interface Vue {
    isActive: boolean;
  }
}

/**
 * The plugin for enabling all components' activated and deactivated hook as well as adding a boolean to present its status
 */
export const ActiveInstancePlugin = {
  install(vm: typeof Vue): void {
    vm.mixin(
      vm.extend({
        data() {
          return {
            /**
             * Since this plugin runs before instantiating the root Vue.js Application, all client only components
             * (that are located inside <client-only> component) will not getting the correct isActive value,
             * because the activated hook was not triggered for them and this results that the isActive flag remaining false.
             *
             * by setting isActive to process.client, we will set the initial value of isActive for client-component to true
             * and for the others it will stay false like before.
             */
            isActive: process.client,
          };
        },
        // In the activated hook, we need to set the isActive as true
        activated() {
          this.isActive = true;
        },
        // In the deactivated hook, since the mounted instance is turned to background, we need to set the isActive as false
        deactivated() {
          this.isActive = false;
        },
      })
    );
  },
};

Vue.use(ActiveInstancePlugin);
