/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Leika API
 * Liefert Leistungsdaten zurück
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AxiosPromise, AxiosInstance, CancelTokenSource } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, ResponseWithIncluded } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequestOptions, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Leika } from '../models';
// @ts-ignore
import { LeikaResponse } from '../models';

/**
 * All paths of DefaultService.
 * Path parameters are prefixed with a colon, e.g. /foo/bar/:baz
 *
 * @export
 */
export const DefaultServicePaths = {
    /**
     * Abfrage von Leistungen anhand von Suchbegriffen
     */
    getInfoLeikaCode: '/services/{leikaCode}'.replace(`{${"leikaCode"}}`, ':leikaCode'),
    /**
     * Abfrage von Leistungen anhand von Suchbegriffen
     */
    getLeika: '/services'
}

/**
 * All request headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 *
 * @export
 */
export const DefaultServiceRequestHeaders = {

}

/**
 * All response headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 * Axios will transform all header names to lowercase and therefore this map will also contain all headers in lowercase.
 *
 * @see https://github.com/axios/axios/issues/413
 *
 * @export
 */
export const DefaultServiceResponseHeaders = {

}

/**
 * All path parameters of each operation.
 *
 * @export
 */
export const DefaultServicePathParams = {

    /**
     * Available path parameters for getInfoLeikaCode.
     */
    getInfoLeikaCode: {
        /**
         * Der Leistungsschlüssel nach dem gesucht wird
         */
        leikaCode: 'leikaCode'
    },

}

/**
 * All query parameters of each operation.
 *
 * @export
 */
export const DefaultServiceQueryParams = {

    /**
     * Available query parameters for getLeika.
     */
    getLeika: {
        /**
         * Der Leistungsschlüssel nach dem gesucht wird
         */
        q: 'q'
    }

}

/**
 * DefaultService - axios parameter creator
 * @export
 */
export const DefaultServiceAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Liefert bei Eingabe eines Leikaschlüssels detaillierte Informationen zur Leistung zurück. Die Rückgabevalues (Strings) enthalten dabei bereits formatiertes HTML.
         * @summary Abfrage von Leistungen anhand von Suchbegriffen
         * @param {string} leikaCode Der Leistungsschlüssel nach dem gesucht wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoLeikaCode: async (leikaCode: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'leikaCode' is not null or undefined
            assertParamExists('getInfoLeikaCode', 'leikaCode', leikaCode)
            const localVarPath = `/services/{leikaCode}`
                .replace(`{${"leikaCode"}}`, encodeURIComponent(String(leikaCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Liefert bei Eingabe eines Suchbegriffs den passenden Leikaschluessel und weitere relevante Information zu einer Leistung zurück
         * @summary Abfrage von Leistungen anhand von Suchbegriffen
         * @param {string} q Der Leistungsschlüssel nach dem gesucht wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeika: async (q: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'q' is not null or undefined
            assertParamExists('getLeika', 'q', q)
            const localVarPath = `/services`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Cancel tokens of pending requests. One cancel token per service function.
 */
const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * DefaultService - functional programming interface
 * @export
 */
export const DefaultServiceFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultServiceAxiosParamCreator(configuration)
    return {
        /**
         * Liefert bei Eingabe eines Leikaschlüssels detaillierte Informationen zur Leistung zurück. Die Rückgabevalues (Strings) enthalten dabei bereits formatiertes HTML.
         * @summary Abfrage von Leistungen anhand von Suchbegriffen
         * @param {string} leikaCode Der Leistungsschlüssel nach dem gesucht wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInfoLeikaCode(leikaCode: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Leika>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInfoLeikaCode(leikaCode, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['getInfoLeikaCode'],
                set: (token: CancelTokenSource) => cancelTokens['getInfoLeikaCode'] = token,
              },
              configuration
            );
        },
        /**
         * Liefert bei Eingabe eines Suchbegriffs den passenden Leikaschluessel und weitere relevante Information zu einer Leistung zurück
         * @summary Abfrage von Leistungen anhand von Suchbegriffen
         * @param {string} q Der Leistungsschlüssel nach dem gesucht wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLeika(q: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeikaResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLeika(q, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['getLeika'],
                set: (token: CancelTokenSource) => cancelTokens['getLeika'] = token,
              },
              configuration
            );
        },
    }
};

/**
 * DefaultService - factory interface
 * @export
 */
export const DefaultServiceFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultServiceFp(configuration)
    return {
        /**
         * Liefert bei Eingabe eines Leikaschlüssels detaillierte Informationen zur Leistung zurück. Die Rückgabevalues (Strings) enthalten dabei bereits formatiertes HTML.
         * @summary Abfrage von Leistungen anhand von Suchbegriffen
         * @param {string} leikaCode Der Leistungsschlüssel nach dem gesucht wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInfoLeikaCode(leikaCode: string, options?: RequestOptions): AxiosPromise<Leika> {
            return localVarFp.getInfoLeikaCode(leikaCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Liefert bei Eingabe eines Suchbegriffs den passenden Leikaschluessel und weitere relevante Information zu einer Leistung zurück
         * @summary Abfrage von Leistungen anhand von Suchbegriffen
         * @param {string} q Der Leistungsschlüssel nach dem gesucht wird
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLeika(q: string, options?: RequestOptions): AxiosPromise<LeikaResponse> {
            return localVarFp.getLeika(q, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultService - interface
 * @export
 * @interface DefaultService
 */
export interface DefaultServiceInterface {
    /**
     * Liefert bei Eingabe eines Leikaschlüssels detaillierte Informationen zur Leistung zurück. Die Rückgabevalues (Strings) enthalten dabei bereits formatiertes HTML.
     * @summary Abfrage von Leistungen anhand von Suchbegriffen
     * @param {string} leikaCode Der Leistungsschlüssel nach dem gesucht wird
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultServiceInterface
     */
    getInfoLeikaCode(leikaCode: string, options?: RequestOptions): AxiosPromise<Leika>;

    /**
     * Liefert bei Eingabe eines Suchbegriffs den passenden Leikaschluessel und weitere relevante Information zu einer Leistung zurück
     * @summary Abfrage von Leistungen anhand von Suchbegriffen
     * @param {string} q Der Leistungsschlüssel nach dem gesucht wird
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultServiceInterface
     */
    getLeika(q: string, options?: RequestOptions): AxiosPromise<LeikaResponse>;

}

/**
 * DefaultService - object-oriented interface
 * @export
 * @class DefaultService
 * @extends {BaseAPI}
 */
export class DefaultService extends BaseAPI implements DefaultServiceInterface {
    /**
     * Liefert bei Eingabe eines Leikaschlüssels detaillierte Informationen zur Leistung zurück. Die Rückgabevalues (Strings) enthalten dabei bereits formatiertes HTML.
     * @summary Abfrage von Leistungen anhand von Suchbegriffen
     * @param {string} leikaCode Der Leistungsschlüssel nach dem gesucht wird
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultService
     */
    public getInfoLeikaCode(leikaCode: string, options?: RequestOptions) {
        return DefaultServiceFp(this.configuration).getInfoLeikaCode(leikaCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Liefert bei Eingabe eines Suchbegriffs den passenden Leikaschluessel und weitere relevante Information zu einer Leistung zurück
     * @summary Abfrage von Leistungen anhand von Suchbegriffen
     * @param {string} q Der Leistungsschlüssel nach dem gesucht wird
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultService
     */
    public getLeika(q: string, options?: RequestOptions) {
        return DefaultServiceFp(this.configuration).getLeika(q, options).then((request) => request(this.axios, this.basePath));
    }
}
