











import { Vue, Component, Prop } from 'nuxt-property-decorator';

/**
 * Items for DropDownComponent.
 *
 * Props:
 * * action - link a action to the anchor tag
 * * label - set a label for item
 * * classes - set classes to the anchor
 */
@Component({ name: 'DropDownItem' })
export default class DropDownItemComponent extends Vue {
  /** item action */
  @Prop({ required: true }) readonly action!: () => unknown;
  /** item label */
  @Prop({ required: true }) readonly label!: string;
  /** classes */
  @Prop() readonly classes?: string | string[];

  /**
   * Triggers action.
   */
  triggerAction(): void {
    if (typeof this.action === 'function') {
      this.action();
    }
    if (typeof (this.$parent as any).close === 'function') {
      (this.$parent as any).close();
    }
  }
}
