/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Websuch-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { Paginiereung, PaginiereungProperties } from './paginiereung';
// @ts-ignore
import { WebTreffer, WebTrefferProperties } from './web-treffer';

/**
 * 
 * @export
 * @interface WebTrefferJsonAntwort
 */
export interface WebTrefferJsonAntwort {
    /**
     * 
     * @type {Array<WebTreffer>}
     * @memberof WebTrefferJsonAntwort
     */
    'daten'?: Array<WebTreffer>;
    /**
     * 
     * @type {Paginiereung}
     * @memberof WebTrefferJsonAntwort
     */
    'paginierung'?: Paginiereung;
}

/**
 * All properties of WebTrefferJsonAntwort.
 * @export
 * @enum {string}
 */
export enum WebTrefferJsonAntwortProperties {
    daten = 'daten',
    paginierung = 'paginierung'
}


