import { OrtModel } from '@/generated/ags_ars';
import { SearchAPI, SearchResult, SearchValue } from '@/interfaces/vsm';
import { Component, Vue, Watch } from 'nuxt-property-decorator';

/**
 * Base component for location search components.
 */
@Component
export class LocationSearch extends Vue {
  searchLocation: SearchValue = { value: '', suggestion: null };
  locationResults: SearchResult[] = [];
  lastAutoSuggestSearchLocation = '';

  timerID!: ReturnType<typeof setTimeout>;
  sleepTime = 300;

  get storedLocation(): SearchValue {
    return this.$searchModule.location;
  }

  /**
   * onStoredLocationChanged(value: string)
   *
   * if the location store is updated we will assign the new store
   * value to the searchLocation field, so each location input is
   * pre-filled
   *
   * @param value - changed location store
   */
  @Watch('storedLocation')
  onStoredLocationChanged(value: SearchValue): void {
    this.searchLocation = value;
  }

  /**
   * Handles the input emitted on input and on focus of the input field
   * @param searchLocation - Object containing search value and suggestion
   */
  onSearchLocationInput(searchLocation: SearchValue): void {
    this.searchLocation = searchLocation;
    if (searchLocation.suggestion) {
      if (searchLocation.value !== this.lastAutoSuggestSearchLocation) {
        this.locationResults = [];
      }
    } else if (searchLocation.value !== this.lastAutoSuggestSearchLocation) {
      this.searchLocationChange();
    }
  }

  /**
   * searchLocationChange()
   *
   * event handler method to interact with child component search input to get location related
   * auto suggestion with debounce functionality
   */
  searchLocationChange(): void {
    clearTimeout(this.timerID);
    this.timerID = setTimeout(this.searchLocationHandler, this.sleepTime);
  }

  /**
   * searchLocationHandler()
   *
   * event handler method to interact with child component search input to get location related
   * auto suggestion
   * start fetching data for more than two given characters and add four elements for
   *   - ags/ars api
   * to the location auto suggestion
   * used as callback method in setTimeout
   */
  async searchLocationHandler(): Promise<void> {
    if (this.searchLocation.value.length > 2) {
      const results = await this.fetchLocationAPI();
      this.lastAutoSuggestSearchLocation = this.searchLocation.value;
      this.locationResults = [];
      for (const result of results) {
        this.locationResults.push({
          name: result.name,
          key: result.regionalschluessel,
          type: SearchAPI.location,
          raw: result,
        });
      }
    } else {
      // delete auto-suggestion array in case of deleting the keyword
      this.locationResults = [];
    }
  }

  /**
   * fetchLocationAPI()
   *
   * fetches from the ags/ars api locations
   *
   * @returns list of services from ags/ars api
   */
  async fetchLocationAPI(): Promise<OrtModel[]> {
    try {
      const response = await this.$agsArsService.sucheOrte(this.searchLocation.value);
      return response.data.daten || [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Got an error while calling VSM api: ', error);
      return [];
    }
  }

  /**
   * submitLocation()
   *
   * stores location search keywords in store
   */
  submitLocation(): void {
    this.$searchModule.updateLocation(this.searchLocation);
  }

  resetLocation(): void {
    this.$searchModule.resetLocation();
  }

  /** initialize location model with stored model */
  mounted(): void {
    if (this.storedLocation && this.storedLocation.suggestion) {
      this.searchLocation = this.storedLocation;
    }
  }
}
