var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('div',[(_vm.user)?_c('DropDown',{attrs:{"label":_vm.user.name,"font-awesome-class":"fa-user-o","icon-before":"","classes":[
      'account-trigger',
      'bg-white',
      'sm:bg-cornflowerBlue2',
      'font-bold',
      'sm:text-white',
      'sm:rounded-md',
      'sm:text-sm',
      'hover:shadow-hover',
      'focus:shadow-hover',
      'active:font-normal',
      'uppercase',
      'max-w-50' ]}},[_c('DropDownItem',{attrs:{"label":_vm.$t('account.account'),"action":_vm.account,"classes":_vm.classes}}),_vm._v(" "),_c('DropDownItem',{attrs:{"label":_vm.$t('account.logout'),"action":_vm.logout,"classes":_vm.classes}})],1):_vm._e(),_vm._v(" "),(!_vm.user)?_c('button',{staticClass:"inline-flex items-center p-2 text-sm font-bold uppercase bg-white rounded-md login-button text-cornflowerBlue sm:bg-cornflowerBlue2 sm:text-white sm:px-4 focus:outline-none hover:shadow-hover focus:shadow-hover active:font-normal",attrs:{"aria-label":_vm.$t('account.login')},on:{"click":_vm.login}},[_c('AppIcon',{staticClass:"w-4 h-4 fill-current sm:mr-2",attrs:{"font-awesome-class":"fa-user-o"}}),_vm._v(" "),_c('span',{staticClass:"hidden keep-size sm:inline",attrs:{"data-text":_vm.$t('account.login')}},[_vm._v(_vm._s(_vm.$t('account.login')))])],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }