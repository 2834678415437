













































































































/**
 * TheNavigation.vue
 * This component includes the main menu structure.
 * Component call:
 *   <TheNavigation />
 */
import { Vue, Component, Watch } from 'nuxt-property-decorator';
import { RouteNames, TopicsTopicRouteParams } from '@generated/routeNames';
import NavigationLink from '@/components/base/NavigationLink.vue';
import AppIcon from '@/components/svg/AppIcon.vue';
import { ScreenSize } from '@/plugins/event-listener.plugin';
import { TopicFields } from '@/interfaces/topic-fields';

@Component({ name: 'the-navigation', components: { AppIcon, NavigationLink } })
export default class TheNavigationComponent extends Vue {
  menuOpenState = { mainMenu: false, subMenu: false };
  navigationLinkStyles: string[] = [
    // general
    'w-full block relative cursor-pointer ',
    // typo:
    'text-white uppercase hover:font-bold active:font-bold',
    // border
    'border-t hover:border border-white border-t-1 block border-solid md:border-t-0 md:hover:border-t-1 md:hover:border-white',
    // margin + padding
    'px-4 py-3 md:py-5',
    // custom
    'navigation-link',
  ];

  hasTouchCapabilities = false;

  // bind setTouchCapabilities to EventListener, which gives us a reference
  setTouchCapabilitiesToTrue = this.setTouchCapabilities.bind(this, true);

  mounted(): void {
    this.setMenuState();
    window.addEventListener('touchstart', this.setTouchCapabilitiesToTrue);
  }

  destroy(): void {
    window.removeEventListener('touchstart', this.setTouchCapabilitiesToTrue);
  }

  get topics(): TopicFields[] {
    return this.$searchTopicFieldsModule.topics;
  }

  setTouchCapabilities(value: boolean): void {
    this.hasTouchCapabilities = value;
  }

  /**
   * Return icon name based on menu state
   */
  get mainMenuIcon(): string {
    return this.menuOpenState.mainMenu ? 'close' : 'menu';
  }

  /**
   * Rotate the icon arrow in dependency of the open state of the submenu
   */
  get subMenuStyle(): Record<'transform', string> {
    return this.menuOpenState.subMenu ? { transform: 'rotate(180deg)' } : { transform: 'rotate(0deg)' };
  }

  /**
   * Get CSS class names for topic navigation item.
   * Add active class name when the page we're visiting is considered a "parent page".
   */
  get topicFieldsStyle(): Array<string> {
    const style = this.isRouteActive
      ? this.navigationLinkStyles.concat('nuxt-link-active')
      : this.navigationLinkStyles;
    return this.menuOpenState.subMenu ? style.concat('submenu-open') : style;
  }

  /**
   * Return true when visiting a topic, circumstance, or service page.
   */
  get isRouteActive(): boolean {
    return ['navigation.topics', 'navigation.circumstances', 'navigation.service'].some((key) =>
      this.$route.path.includes(this.$i18n.t(key).toString().toLowerCase())
    );
  }

  /**
   * Returns true when the current screenSize is MEDIUM or larger.
   */
  get isMediumScreen(): boolean {
    return this.$clientDimensions.isLargerOrEqual(ScreenSize.MEDIUM);
  }

  /**
   * Returns the local path to the start/index page.
   */
  get startPath(): string {
    return this.localePath(RouteNames.INDEX);
  }

  /**
   * Returns the localized path to the portals page.
   */
  get portalsPath(): string {
    return this.localePath(RouteNames.PORTALS);
  }

  /**
   * Set the "MainMenu" state to true or false, depending on the browser width. It also reset the subMenu state and touch capabilities.
   */
  @Watch('$clientDimensions', { deep: true })
  setMenuState(): void {
    this.menuOpenState.mainMenu = this.isMediumScreen;
    this.menuOpenState.subMenu = false;
    this.setTouchCapabilities(false);
  }

  /**
   * Toggle the "main menu" state to show or hide the main menu visually (on mobile/desktop only).
   */
  toggleMainMenu(): void {
    if (!this.isMediumScreen) {
      // avoid flickering of submenu, when it has been opened before
      this.menuOpenState.subMenu = false;
      this.menuOpenState.mainMenu = !this.menuOpenState.mainMenu;
    }
  }

  /**
   * Used for mouse, tab and keyboard navigation.
   */
  toggleSubMenu(): void {
    this.menuOpenState.subMenu = !this.menuOpenState.subMenu;
  }

  /**
   * Sets the "submenu" state to true, so that it opens.
   * Triggered by mouseover
   */
  openSubMenu(): void {
    if (!this.hasTouchCapabilities && this.isMediumScreen) {
      this.menuOpenState.subMenu = true;
    }
  }

  /**
   * Returns the localized path for a topic with the given id.
   */
  getTopicPath(topicId: string): string {
    return this.localePath({ name: RouteNames.TOPICS_TOPIC, params: { [TopicsTopicRouteParams.TOPIC]: topicId } });
  }

  /**
   * Sets the submenu state to false, so that it closes.
   * Triggered by mouseleave
   */
  @Watch('$route.path')
  closeSubMenu(): void {
    if (this.isMediumScreen) {
      this.menuOpenState.subMenu = false;
    }
  }
}
