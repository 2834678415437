




















import { Component } from 'nuxt-property-decorator';
import LogoComponent from '@/components/header/Logo';
import CopyrightFooterComponent from '@/components/footer/CopyrightFooter.vue';

@Component({
  components: {
    CopyrightFooter: CopyrightFooterComponent,
  },
})
export default class Stripped extends LogoComponent {}
