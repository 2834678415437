/**
 * The <a> elements in the responses don't contain the external link icon as shown in UXPin. This appends the icon for each </a>
 * @param description - The service detail response
 */
export function appendExternalIconToLinks(description: string): string {
  let finalResult = '';
  if (description) {
    finalResult = description.replace(
      /<\/a>/g,
      '<AppIcon font-awesome-class="fa-external-link" class="mt-1 mb-2" /></a>'
    );
  }
  return finalResult;
}

/**
 * Formats the service detail response from Leika or Zufi by adding classes to the <ul> elements and appending the external icon links
 *
 * @param description - The service detail response from Leika or Zufi
 */
export function formatServiceDetailResponse(description: string): string {
  let finalResult = '';
  if (description) {
    // TODO DETOZGNRW-1594 Should we use 'replaceAll' already ? It should be supported since August 2020
    // Add classes to ul elements
    finalResult = description.replace(/<ul>/g, '<ul class="list-outside list-disc ml-6 my-4">');
    // Add SVG to links
    finalResult = appendExternalIconToLinks(finalResult);
  }
  return finalResult;
}

/**
 * Formats the service detail response from Leika by removing the spans
 *
 * @param description - The service detail response from Leika
 */
export function formatLeikaServiceDetailResponse(description: string): string {
  let finalResult = '';
  if (description) {
    // TODO DETOZGNRW-1594 Should we use 'replaceAll' already ? It should be supported since August 2020
    finalResult = formatServiceDetailResponse(description);
    finalResult = finalResult.replace(/<span>/g, '');
    finalResult = finalResult.replace(/<\/span>/g, '');
  }
  return finalResult;
}

/**
 * construct tooltip texts
 * @param title - title line
 * @param description - optional, content description
 */
export function formatTooltipContent(title: string, description?: string): string {
  return description
    ? `<div class='text-codGray text-left'><span class='font-bold'>${title}</span>${description}<div>`
    : `<span class='text-codGray text-left'>${title}</span>`;
}

/**
 * The CMS response contains <p> and </p> in the description section, it is fine for v-html but not good for hover text
 * @param description - the input string
 */
export function removeParagraphTagFromDescription(description: string): string {
  let finalResult = '';
  if (description) {
    finalResult = description.replace(/<p>/g, '').replace(/<\/p>/g, '');
  }
  return finalResult;
}

/**
 * Replaces src values in the 'completeTextContent' if they start with '/' adding the given baseURL in front.
 *
 * @param completeTextContent - textContent where the src attributes should be replaced
 * @param baseUrl - baseURL to use
 */
export function formatSrcWithBaseUrl(completeTextContent: string, baseUrl: string): string {
  return completeTextContent.replace(/(src=")(\/[^"]*")/g, (_, matchingGroup1, matchingGroup2) => {
    if (!matchingGroup1 || !matchingGroup2) {
      // this can only happen if the regex was manipulated
      throw new Error('Regex for replace should have two groups!');
    }
    const srcValue = matchingGroup2.startsWith('/') ? `${baseUrl}${matchingGroup2}` : matchingGroup2;
    return `${matchingGroup1}${srcValue}`;
  });
}
