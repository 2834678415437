/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface RequestErrorModel
 */
export interface RequestErrorModel {
    /**
     * 
     * @type {Array<object>}
     * @memberof RequestErrorModel
     */
    'fehler'?: Array<object>;
}

/**
 * All properties of RequestErrorModel.
 * @export
 * @enum {string}
 */
export enum RequestErrorModelProperties {
    fehler = 'fehler'
}


