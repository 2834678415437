




































import DropDownComponent from '@/components/dropdown/DropDown.vue';
import DropDownItemComponent from '@/components/dropdown/DropDownItem.vue';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import { Subscription } from '@/plugins/navigation/navigation.plugin';
import { UiUser } from '@/store/account';
import { RouteNames } from '@generated/routeNames';
import { Component, Vue } from 'nuxt-property-decorator';

/**
 * TheAccountBarComponent shows account menu in the header.
 */
@Component({
  name: 'the-account-bar',
  components: {
    AppIcon: AppIconComponent,
    DropDown: DropDownComponent,
    DropDownItem: DropDownItemComponent,
  },
})
export default class TheAccountBarComponent extends Vue {
  /** classes for drop down items */
  classes = [
    'font-bold',
    'text-codGray',
    'hover:bg-cornflowerBlue2',
    'hover:text-white',
    'hover:shadow-hover',
    'focus:shadow-hover',
    'active:font-normal',
  ];

  ready = false;
  loginSubscription!: Subscription;

  /** Navigation to account page. */
  account(): void {
    // @TODO NRWFMS-170 link should be via SPA routing
    window.location.href = this.localePath(RouteNames.ACCOUNT);
  }

  /**
   * Triggers fetch user.
   */
  async fetchUser(): Promise<void> {
    await this.$accountModule.fetchUser();
    this.ready = true;
  }

  /**
   * Initial fetch when component is loaded.
   */
  async mounted(): Promise<void> {
    await this.fetchUser();
    if (this.$accountModule.transientUser) {
      await this.$accountModule.logout();
    }
    this.loginSubscription = this.$listenToEvents('LOGIN', async () => {
      await this.fetchUser();
      if (this.$accountModule.user) {
        // @TODO NRWFMS-170 redirect should be via SPA routing
        window.location.href = this.localePath(RouteNames.ACCOUNT);
      } else if (this.$route.path === this.localePath(RouteNames.ACCOUNT)) {
        await this.$router.push('/');
      }
    });
  }

  /**
   * Unsubscribe login subscription.
   */
  beforeDestroy(): void {
    this.loginSubscription.unsubscribe();
  }

  /**
   * Getter for user object
   */
  get user(): UiUser | null {
    return this.$accountModule.user;
  }

  /**
   * Login method opens a popup window with integration endpoint url, which redirects to the servicekonto login.
   */
  async login(): Promise<void> {
    const handle = this.$redirect({
      openWindow: true,
      targetRoute: this.localePath(RouteNames.ACCOUNT),
      target: 'LOGIN',
    });
    await this.$accountModule.logout();
    handle.navigate(`${this.$config.integrationBrowser}/api/login`, { addRedirect: true });
  }

  /**
   * Logs user out.
   */
  async logout(): Promise<void> {
    // eslint-disable-next-line no-console
    await dispatchEvent(new Event('PEGA_LOGOUT'));
    await this.$accountModule.logout();
    await this.$emitEvent('LOGIN');
    if (this.$route.path === this.localePath(RouteNames.ACCOUNT)) {
      await this.$router.push('/');
    }
  }
}
