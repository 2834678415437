import { NuxtApp } from '@nuxt/types/app';
import { getCurrentInstance } from '@vue/composition-api';

/**
 * Gives access to nuxt app.
 *
 * @returns app
 */
export function getApp(): NuxtApp {
  const vm = getCurrentInstance();
  if (vm) {
    return vm.proxy.$nuxt;
  }
  throw new Error('Call within setup method!');
}
