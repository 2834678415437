





















/**
 * TheLanguageSwitcher.vue
 *
 * This component has a selection to switch between languages which are defined
 * in the nuxt.config.js
 * the default language is set to German
 *
 * Component call:
 *   <TheLanguageSwitcher />
 *
 * Development guide:
 * https://confluence.t-systems-mms.eu/display/detozgnrw/Multi-language+support
 */

import { Component, Vue } from 'nuxt-property-decorator';
import AppIconComponent from '@/components/svg/AppIcon.vue';
import { NuxtVueI18n } from 'nuxt-i18n/types';

@Component({
  name: 'the-language-switcher',
  components: {
    AppIcon: AppIconComponent,
  },
})
export default class TheLanguageSwitcherComponent extends Vue {
  get locales(): (string | NuxtVueI18n.Options.LocaleObject)[] {
    return this.$i18n.locales || [];
  }

  get currentLocale(): string {
    return this.$i18n.locale;
  }

  set currentLocale(newLocale: string) {
    this.$router.push(this.switchLocalePath(newLocale));
  }
}
