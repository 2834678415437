/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AxiosPromise, AxiosInstance, CancelTokenSource } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, ResponseWithIncluded } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequestOptions, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FormCreateDto } from '../models';
// @ts-ignore
import { FormDetailDto } from '../models';
// @ts-ignore
import { FormDetailWithMetadataDto } from '../models';
// @ts-ignore
import { FormMetadataInfoDto } from '../models';
// @ts-ignore
import { FormNonceDto } from '../models';
// @ts-ignore
import { FormPdfDto } from '../models';
// @ts-ignore
import { PartialFormDetailWithMetadataDto } from '../models';

/**
 * All paths of FormService.
 * Path parameters are prefixed with a colon, e.g. /foo/bar/:baz
 *
 * @export
 */
export const FormServicePaths = {
    apiFormControllerCreateForm: '/api/form',
    apiFormControllerDeleteForm: '/api/form/{id}'.replace(`{${"id"}}`, ':id'),
    apiFormControllerFormCompleteWithSessionUser: '/api/form/{id}/complete'.replace(`{${"id"}}`, ':id'),
    apiFormControllerFormCredentials: '/api/form/{id}/credentials'.replace(`{${"id"}}`, ':id'),
    apiFormControllerGetAvailableForms: '/api/forms/available',
    apiFormControllerGetForm: '/api/form/{id}'.replace(`{${"id"}}`, ':id'),
    apiFormControllerGetFormByLeiKaAndAuthority: '/api/form',
    apiFormControllerGetFormPdf: '/api/form/{id}/pdf'.replace(`{${"id"}}`, ':id'),
    apiFormControllerGetFormStatus: '/api/form/{id}/status'.replace(`{${"id"}}`, ':id'),
    apiFormControllerGetForms: '/api/forms',
    apiFormControllerSubmitForm: '/api/form/{id}/submit'.replace(`{${"id"}}`, ':id')
}

/**
 * All request headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 *
 * @export
 */
export const FormServiceRequestHeaders = {

}

/**
 * All response headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 * Axios will transform all header names to lowercase and therefore this map will also contain all headers in lowercase.
 *
 * @see https://github.com/axios/axios/issues/413
 *
 * @export
 */
export const FormServiceResponseHeaders = {

}

/**
 * All path parameters of each operation.
 *
 * @export
 */
export const FormServicePathParams = {

    apiFormControllerDeleteForm: {
        id: 'id'
    },

    apiFormControllerFormCompleteWithSessionUser: {
        id: 'id'
    },

    apiFormControllerFormCredentials: {
        id: 'id'
    },

    apiFormControllerGetForm: {
        id: 'id'
    },

    apiFormControllerGetFormPdf: {
        id: 'id'
    },

    apiFormControllerGetFormStatus: {
        id: 'id'
    },

    apiFormControllerSubmitForm: {
        id: 'id'
    }

}

/**
 * All query parameters of each operation.
 *
 * @export
 */
export const FormServiceQueryParams = {

    apiFormControllerGetAvailableForms: {
        lang: 'lang',
        leiKaId: 'leiKaId',
        authorityKey: 'authorityKey'
    },

    apiFormControllerGetForm: {
        lang: 'lang'
    },

    apiFormControllerGetFormByLeiKaAndAuthority: {
        lang: 'lang',
        leiKaId: 'leiKaId',
        authorityKey: 'authorityKey'
    },

    apiFormControllerGetForms: {
        lang: 'lang'
    },

}

/**
 * FormService - axios parameter creator
 * @export
 */
export const FormServiceAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {FormCreateDto} formCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerCreateForm: async (formCreateDto: FormCreateDto, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'formCreateDto' is not null or undefined
            assertParamExists('apiFormControllerCreateForm', 'formCreateDto', formCreateDto)
            const localVarPath = `/api/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formCreateDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerDeleteForm: async (id: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerDeleteForm', 'id', id)
            const localVarPath = `/api/form/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {FormNonceDto} formNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerFormCompleteWithSessionUser: async (id: string, formNonceDto: FormNonceDto, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerFormCompleteWithSessionUser', 'id', id)
            // verify required parameter 'formNonceDto' is not null or undefined
            assertParamExists('apiFormControllerFormCompleteWithSessionUser', 'formNonceDto', formNonceDto)
            const localVarPath = `/api/form/{id}/complete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(formNonceDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerFormCredentials: async (id: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerFormCredentials', 'id', id)
            const localVarPath = `/api/form/{id}/credentials`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lang] 
         * @param {string} [leiKaId] 
         * @param {string} [authorityKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetAvailableForms: async (lang?: string, leiKaId?: string, authorityKey?: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/forms/available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }
            if (leiKaId !== undefined) {
                localVarQueryParameter['leiKaId'] = leiKaId;
            }
            if (authorityKey !== undefined) {
                localVarQueryParameter['authorityKey'] = authorityKey;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetForm: async (id: string, lang?: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerGetForm', 'id', id)
            const localVarPath = `/api/form/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} leiKaId 
         * @param {string} authorityKey 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetFormByLeiKaAndAuthority: async (leiKaId: string, authorityKey: string, lang?: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'leiKaId' is not null or undefined
            assertParamExists('apiFormControllerGetFormByLeiKaAndAuthority', 'leiKaId', leiKaId)
            // verify required parameter 'authorityKey' is not null or undefined
            assertParamExists('apiFormControllerGetFormByLeiKaAndAuthority', 'authorityKey', authorityKey)
            const localVarPath = `/api/form`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }
            if (leiKaId !== undefined) {
                localVarQueryParameter['leiKaId'] = leiKaId;
            }
            if (authorityKey !== undefined) {
                localVarQueryParameter['authorityKey'] = authorityKey;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetFormPdf: async (id: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerGetFormPdf', 'id', id)
            const localVarPath = `/api/form/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetFormStatus: async (id: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerGetFormStatus', 'id', id)
            const localVarPath = `/api/form/{id}/status`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetForms: async (lang?: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/forms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerSubmitForm: async (id: string, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiFormControllerSubmitForm', 'id', id)
            const localVarPath = `/api/form/{id}/submit`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Cancel tokens of pending requests. One cancel token per service function.
 */
const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * FormService - functional programming interface
 * @export
 */
export const FormServiceFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FormServiceAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {FormCreateDto} formCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerCreateForm(formCreateDto: FormCreateDto, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerCreateForm(formCreateDto, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_createForm'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_createForm'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerDeleteForm(id: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerDeleteForm(id, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_deleteForm'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_deleteForm'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {FormNonceDto} formNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerFormCompleteWithSessionUser(id: string, formNonceDto: FormNonceDto, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerFormCompleteWithSessionUser(id, formNonceDto, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_formCompleteWithSessionUser'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_formCompleteWithSessionUser'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerFormCredentials(id: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerFormCredentials(id, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_formCredentials'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_formCredentials'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} [lang] 
         * @param {string} [leiKaId] 
         * @param {string} [authorityKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerGetAvailableForms(lang?: string, leiKaId?: string, authorityKey?: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormMetadataInfoDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerGetAvailableForms(lang, leiKaId, authorityKey, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_getAvailableForms'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_getAvailableForms'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerGetForm(id: string, lang?: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDetailWithMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerGetForm(id, lang, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_getForm'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_getForm'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} leiKaId 
         * @param {string} authorityKey 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerGetFormByLeiKaAndAuthority(leiKaId: string, authorityKey: string, lang?: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PartialFormDetailWithMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerGetFormByLeiKaAndAuthority(leiKaId, authorityKey, lang, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_getFormByLeiKaAndAuthority'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_getFormByLeiKaAndAuthority'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerGetFormPdf(id: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormPdfDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerGetFormPdf(id, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_getFormPdf'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_getFormPdf'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerGetFormStatus(id: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerGetFormStatus(id, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_getFormStatus'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_getFormStatus'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerGetForms(lang?: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FormDetailWithMetadataDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerGetForms(lang, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_getForms'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_getForms'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiFormControllerSubmitForm(id: string, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FormDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiFormControllerSubmitForm(id, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiFormController_submitForm'],
                set: (token: CancelTokenSource) => cancelTokens['ApiFormController_submitForm'] = token,
              },
              configuration
            );
        },
    }
};

/**
 * FormService - factory interface
 * @export
 */
export const FormServiceFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FormServiceFp(configuration)
    return {
        /**
         * 
         * @param {FormCreateDto} formCreateDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerCreateForm(formCreateDto: FormCreateDto, options?: RequestOptions): AxiosPromise<void> {
            return localVarFp.apiFormControllerCreateForm(formCreateDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerDeleteForm(id: string, options?: RequestOptions): AxiosPromise<void> {
            return localVarFp.apiFormControllerDeleteForm(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {FormNonceDto} formNonceDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerFormCompleteWithSessionUser(id: string, formNonceDto: FormNonceDto, options?: RequestOptions): AxiosPromise<void> {
            return localVarFp.apiFormControllerFormCompleteWithSessionUser(id, formNonceDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerFormCredentials(id: string, options?: RequestOptions): AxiosPromise<object> {
            return localVarFp.apiFormControllerFormCredentials(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lang] 
         * @param {string} [leiKaId] 
         * @param {string} [authorityKey] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetAvailableForms(lang?: string, leiKaId?: string, authorityKey?: string, options?: RequestOptions): AxiosPromise<Array<FormMetadataInfoDto>> {
            return localVarFp.apiFormControllerGetAvailableForms(lang, leiKaId, authorityKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetForm(id: string, lang?: string, options?: RequestOptions): AxiosPromise<FormDetailWithMetadataDto> {
            return localVarFp.apiFormControllerGetForm(id, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} leiKaId 
         * @param {string} authorityKey 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetFormByLeiKaAndAuthority(leiKaId: string, authorityKey: string, lang?: string, options?: RequestOptions): AxiosPromise<PartialFormDetailWithMetadataDto> {
            return localVarFp.apiFormControllerGetFormByLeiKaAndAuthority(leiKaId, authorityKey, lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetFormPdf(id: string, options?: RequestOptions): AxiosPromise<FormPdfDto> {
            return localVarFp.apiFormControllerGetFormPdf(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetFormStatus(id: string, options?: RequestOptions): AxiosPromise<FormDetailDto> {
            return localVarFp.apiFormControllerGetFormStatus(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [lang] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerGetForms(lang?: string, options?: RequestOptions): AxiosPromise<Array<FormDetailWithMetadataDto>> {
            return localVarFp.apiFormControllerGetForms(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiFormControllerSubmitForm(id: string, options?: RequestOptions): AxiosPromise<FormDetailDto> {
            return localVarFp.apiFormControllerSubmitForm(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FormService - interface
 * @export
 * @interface FormService
 */
export interface FormServiceInterface {
    /**
     * 
     * @param {FormCreateDto} formCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerCreateForm(formCreateDto: FormCreateDto, options?: RequestOptions): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerDeleteForm(id: string, options?: RequestOptions): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {FormNonceDto} formNonceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerFormCompleteWithSessionUser(id: string, formNonceDto: FormNonceDto, options?: RequestOptions): AxiosPromise<void>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerFormCredentials(id: string, options?: RequestOptions): AxiosPromise<object>;

    /**
     * 
     * @param {string} [lang] 
     * @param {string} [leiKaId] 
     * @param {string} [authorityKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerGetAvailableForms(lang?: string, leiKaId?: string, authorityKey?: string, options?: RequestOptions): AxiosPromise<Array<FormMetadataInfoDto>>;

    /**
     * 
     * @param {string} id 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerGetForm(id: string, lang?: string, options?: RequestOptions): AxiosPromise<FormDetailWithMetadataDto>;

    /**
     * 
     * @param {string} leiKaId 
     * @param {string} authorityKey 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerGetFormByLeiKaAndAuthority(leiKaId: string, authorityKey: string, lang?: string, options?: RequestOptions): AxiosPromise<PartialFormDetailWithMetadataDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerGetFormPdf(id: string, options?: RequestOptions): AxiosPromise<FormPdfDto>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerGetFormStatus(id: string, options?: RequestOptions): AxiosPromise<FormDetailDto>;

    /**
     * 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerGetForms(lang?: string, options?: RequestOptions): AxiosPromise<Array<FormDetailWithMetadataDto>>;

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormServiceInterface
     */
    apiFormControllerSubmitForm(id: string, options?: RequestOptions): AxiosPromise<FormDetailDto>;

}

/**
 * FormService - object-oriented interface
 * @export
 * @class FormService
 * @extends {BaseAPI}
 */
export class FormService extends BaseAPI implements FormServiceInterface {
    /**
     * 
     * @param {FormCreateDto} formCreateDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerCreateForm(formCreateDto: FormCreateDto, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerCreateForm(formCreateDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerDeleteForm(id: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerDeleteForm(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {FormNonceDto} formNonceDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerFormCompleteWithSessionUser(id: string, formNonceDto: FormNonceDto, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerFormCompleteWithSessionUser(id, formNonceDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerFormCredentials(id: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerFormCredentials(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lang] 
     * @param {string} [leiKaId] 
     * @param {string} [authorityKey] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerGetAvailableForms(lang?: string, leiKaId?: string, authorityKey?: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerGetAvailableForms(lang, leiKaId, authorityKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerGetForm(id: string, lang?: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerGetForm(id, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} leiKaId 
     * @param {string} authorityKey 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerGetFormByLeiKaAndAuthority(leiKaId: string, authorityKey: string, lang?: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerGetFormByLeiKaAndAuthority(leiKaId, authorityKey, lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerGetFormPdf(id: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerGetFormPdf(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerGetFormStatus(id: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerGetFormStatus(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [lang] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerGetForms(lang?: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerGetForms(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FormService
     */
    public apiFormControllerSubmitForm(id: string, options?: RequestOptions) {
        return FormServiceFp(this.configuration).apiFormControllerSubmitForm(id, options).then((request) => request(this.axios, this.basePath));
    }
}
