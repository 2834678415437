/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { FormMetadataInfoDto, FormMetadataInfoDtoProperties } from './form-metadata-info-dto';
// @ts-ignore
import { FormStatus, FormStatusProperties } from './form-status';

/**
 * 
 * @export
 * @interface PartialFormDetailWithMetadataDto
 */
export interface PartialFormDetailWithMetadataDto {
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'id'?: string;
    /**
     * 
     * @type {FormStatus}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'status'?: FormStatus;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'statusChanged'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'leiKaId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'authorityKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'messageId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'formPdfId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'receiptPdfId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'checkoutSign'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'applyUserData'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'amount'?: number;
    /**
     * 
     * @type {FormMetadataInfoDto}
     * @memberof PartialFormDetailWithMetadataDto
     */
    'metadata': FormMetadataInfoDto;
}

/**
 * All properties of PartialFormDetailWithMetadataDto.
 * @export
 * @enum {string}
 */
export enum PartialFormDetailWithMetadataDtoProperties {
    id = 'id',
    status = 'status',
    statusChanged = 'statusChanged',
    leiKaId = 'leiKaId',
    authorityKey = 'authorityKey',
    name = 'name',
    messageId = 'messageId',
    formPdfId = 'formPdfId',
    receiptPdfId = 'receiptPdfId',
    checkoutSign = 'checkoutSign',
    applyUserData = 'applyUserData',
    amount = 'amount',
    metadata = 'metadata'
}


