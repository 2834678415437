/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Websuch-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Paginiereung
 */
export interface Paginiereung {
    /**
     * Die Gesamtanzahl der Ergebnisse.
     * @type {number}
     * @memberof Paginiereung
     */
    'gesamt'?: number;
    /**
     * Die Anzahl der Ergebnisse pro Seite.
     * @type {number}
     * @memberof Paginiereung
     */
    'anzahl'?: number;
    /**
     * Startnummer aus der Gesamtanzahl.
     * @type {number}
     * @memberof Paginiereung
     */
    'start'?: number;
    /**
     * Endnummer aus der Gesamtanzahl.
     * @type {number}
     * @memberof Paginiereung
     */
    'ende'?: number;
    /**
     * Aktuelle Seite.
     * @type {number}
     * @memberof Paginiereung
     */
    'aktuelleSeite'?: number;
    /**
     * Die Url der nächste Seite.
     * @type {string}
     * @memberof Paginiereung
     */
    'naechsteUrl'?: string;
}

/**
 * All properties of Paginiereung.
 * @export
 * @enum {string}
 */
export enum PaginiereungProperties {
    /**
     * Die Gesamtanzahl der Ergebnisse.
     */
    gesamt = 'gesamt',
    /**
     * Die Anzahl der Ergebnisse pro Seite.
     */
    anzahl = 'anzahl',
    /**
     * Startnummer aus der Gesamtanzahl.
     */
    start = 'start',
    /**
     * Endnummer aus der Gesamtanzahl.
     */
    ende = 'ende',
    /**
     * Aktuelle Seite.
     */
    aktuelleSeite = 'aktuelleSeite',
    /**
     * Die Url der nächste Seite.
     */
    naechsteUrl = 'naechsteUrl'
}


