/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { AnschriftModel, AnschriftModelProperties } from './anschrift-model';
// @ts-ignore
import { KommunikationsModel, KommunikationsModelProperties } from './kommunikations-model';

/**
 * 
 * @export
 * @interface KontaktpersonModel
 */
export interface KontaktpersonModel {
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'anrede'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'titel'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'vorname'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'familienname'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'position'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'sprechzeiten'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'raum'?: string;
    /**
     * 
     * @type {string}
     * @memberof KontaktpersonModel
     */
    'internetadresse'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof KontaktpersonModel
     */
    'zustaendigkeit'?: Array<string>;
    /**
     * 
     * @type {Array<AnschriftModel>}
     * @memberof KontaktpersonModel
     */
    'anschrift'?: Array<AnschriftModel>;
    /**
     * 
     * @type {Array<KommunikationsModel>}
     * @memberof KontaktpersonModel
     */
    'kommunikation'?: Array<KommunikationsModel>;
}

/**
 * All properties of KontaktpersonModel.
 * @export
 * @enum {string}
 */
export enum KontaktpersonModelProperties {
    anrede = 'anrede',
    titel = 'titel',
    vorname = 'vorname',
    familienname = 'familienname',
    position = 'position',
    sprechzeiten = 'sprechzeiten',
    raum = 'raum',
    internetadresse = 'internetadresse',
    zustaendigkeit = 'zustaendigkeit',
    anschrift = 'anschrift',
    kommunikation = 'kommunikation'
}


