import { setQueryParams } from '@/utils/url.utils';
import { BroadcastChannel } from 'broadcast-channel';
import Vue from 'vue';

const SOURCE_STORAGE_KEY = 'primary:window';
const PRIMARY_TARGET = 'PRIMARY_TARGET';

/** Defines a event emitted internally. */
interface BroadcastEvent {
  target: string;
  source: string;
  isFailure?: boolean;
  isSessionTimeout?: boolean;
  data?: Record<string, string>;
}

/** Defines a event emitted externally. */
export interface Event {
  /** failure occurred */
  isFailure?: boolean;
  /** is session timed out */
  isSessionTimeout?: boolean;
  /** optional data, is picked from query in redirect page */
  data?: Record<string, string>;
}

/** Options to configure $redirect function. */
export interface RedirectOptions {
  /** should opened a window */
  openWindow?: boolean;
  /** target to listen to in opened window */
  target?: string;
  /** target route to came back to after redirect is finished */
  targetRoute?: string | { path: string; query: Record<string, string> };
}

// function types and returned handle interfaces
export interface RedirectHandle {
  navigate: (
    url: string,
    navOptions?: {
      query?: Record<string, string | number | boolean>;
      addRedirect?: boolean;
      redirectParams?: Record<string, string>;
    }
  ) => void;
  createRedirectUrl: (params?: Record<string, string>) => string;
  close: () => void;
}
export type RedirectType = (options: RedirectOptions) => RedirectHandle;
export interface Subscription {
  unsubscribe: () => void;
}
export interface SubscriptionWithReSubscribe extends Subscription {
  reSubscribe: () => void;
}
export type ListenToEventsType = (target: string, callback: (event: Event) => void) => Subscription;
export type EmitEventType = (target: string, payload?: Event) => Promise<void>;
export type ListenToFocusType = (callback: (isFocused: boolean) => void) => SubscriptionWithReSubscribe;

/**
 * Plugin to provide functions for multi window handling and redirecting.
 */
export const navigationPlugin = {
  install(vue: typeof Vue): void {
    const prototype = vue.prototype as {
      $listenToEvents: ListenToEventsType;
      $emitEvent: EmitEventType;
      $listenToFocus: ListenToFocusType;
      $redirect: RedirectType;
      $isFocusedWindow: () => boolean;
    } & Vue;
    let windowRef: Window | null;
    const source = Math.random().toString(36).substr(2, 9);
    const channel = new BroadcastChannel('sync', { webWorkerSupport: false });

    /**
     * Returns current window focus.
     *
     * @returns true if current window is focused
     */
    prototype.$isFocusedWindow = function (): boolean {
      return source === localStorage.getItem(SOURCE_STORAGE_KEY);
    };

    /**
     * Calls back if window focus changes. Recommendation is to use this function with a callback which unsubscribes
     * immediately if focus is lost and to show a banner with such a hint.
     *
     * @param callback - is called if focus is changed
     * @returns subscription handle to unsubscribe and resubscribe to the event
     */
    prototype.$listenToFocus = function (callback: (isFocused: boolean) => void): SubscriptionWithReSubscribe {
      const focusHandler = (): void => {
        localStorage.setItem(SOURCE_STORAGE_KEY, source);
        channel.postMessage({ source, target: PRIMARY_TARGET });
      };
      focusHandler();
      const messageHandler = (event: BroadcastEvent): void => {
        if (event.target === PRIMARY_TARGET && typeof callback === 'function') {
          callback(event.source === source);
        }
      };
      window.addEventListener('focus', focusHandler);
      channel.addEventListener('message', messageHandler);
      return {
        unsubscribe: (): void => {
          window.removeEventListener('focus', focusHandler);
          channel.removeEventListener('message', messageHandler);
        },
        reSubscribe: (): void => {
          window.addEventListener('focus', focusHandler);
          channel.addEventListener('message', messageHandler);
          focusHandler();
        },
      };
    };

    /**
     * Attaches a callback to a cross window/tab event. This should be mainly used to handle redirects and results of
     * opened windows, because it totally uncouples a closing window event form existing other windows.
     *
     * @param target - target to listen to
     * @param callback - callback for calling back if event occurs
     * @returns a subscription handle to unsubscribe form target afterwards
     */
    prototype.$listenToEvents = function (target: string, callback: (event: Event) => void): Subscription {
      const handler = (event?: BroadcastEvent): void => {
        if (event && event.target === target && typeof callback === 'function') {
          callback({ isFailure: !!event.isFailure, isSessionTimeout: !!event.isSessionTimeout, data: event.data });
        }
      };
      channel.addEventListener('message', handler);
      return {
        unsubscribe: (): void => {
          channel.removeEventListener('message', handler);
        },
      };
    };

    /**
     * Emits an event to the given target with the given payload.
     *
     * @param target - given target
     * @param payload - payload to submit
     */
    prototype.$emitEvent = async function (target: string, payload: Event = {}): Promise<void> {
      const event: BroadcastEvent = { ...payload, target, source };
      await channel.postMessage(event);
    };

    /**
     * Creates a handle to control a redirect or an opened window. If open a window is selected,
     * the function opens immediately a window to bind user event to the open action to prevent opening window
     * to be blocked. The returned handle allows to navigate to a new url afterwards. This makes it possible to fetch
     * a resource and get for example a url to navigate to.
     *
     * @param options - redirect options
     * @returns handle to control redirect
     */
    prototype.$redirect = function (options: RedirectOptions): RedirectHandle {
      // close old handle if existing
      if (windowRef) {
        windowRef.close();
      }
      // set defaults
      windowRef = options.openWindow ? null : window;
      // open a window is selected
      if (options.openWindow) {
        windowRef = window.open(
          `${window.location.origin}/redirect?waitingForRedirect=true`,
          'RedirectWindow',
          `toolbar=no,location=yes,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,copyhistory=no,height=900,width=860,top=80,left=${
            window.screen.width / 2 - 430
          }`
        );
      }
      /**
       * Creates a url string.
       *
       * @param params - query params
       * @returns redirect url
       */
      const createRedirectUrl = (params?: Record<string, string>): string => {
        const route = options.targetRoute
          ? typeof options.targetRoute === 'string'
            ? options.targetRoute
            : setQueryParams(options.targetRoute.path, { ...options.targetRoute.query, ...params })
          : '';
        return options.openWindow
          ? setQueryParams(`${window.location.origin}/redirect`, {
              ...params,
              target: options.target,
              route,
              source,
            })
          : `${window.location.origin}${route}`;
      };

      /**
       * Navigates to a new given url.
       *
       * @param url - url to navigate to using href of opened window or the current window.
       * @param navOptions - options for the navigation, mainly automatic query parameter adding
       */
      const navigate = (
        url: string,
        navOptions?: {
          query?: Record<string, string | number | boolean>;
          addRedirect?: boolean;
          redirectParams?: Record<string, string>;
        }
      ): void => {
        if (windowRef) {
          const params = {
            ...navOptions?.query,
            ...(navOptions?.addRedirect
              ? {
                  redirect: createRedirectUrl(options.openWindow ? navOptions.redirectParams : {}),
                }
              : {}),
          };
          windowRef.location.href = setQueryParams(url, params);
        }
      };

      /**
       * Closes opened window.
       */
      const close = (): void => {
        if (windowRef) {
          windowRef.close();
        }
      };
      return { navigate, createRedirectUrl, close };
    };
  },
};
