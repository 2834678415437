// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("assets/fonts/benton-sans-regular.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("assets/fonts/benton-sans-bold.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face{font-family:benton-sans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");font-style:normal;font-weight:400}@font-face{font-family:benton-sans;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-style:normal;font-weight:700}html{font-family:benton-sans}.keep-size:after{display:block;content:attr(data-text);font-weight:700;visibility:hidden;overflow:hidden;height:0}", ""]);
// Exports
module.exports = exports;
