import { useIntegrationServices } from '@/composables/integrationServices';
import type { FormService, PaymentService, SessionService } from '@/generated/integration';
import { Configuration as AgsArsConfiguration, DefaultService as AgsArsService } from '@generated/ags_ars';
// import { ContentExternalTeaserService } from '@generated/cms';
import { Configuration as LeikaConfiguration, DefaultService as LeikaService } from '@generated/leika';
import { Configuration as WebSuchConfiguration, DefaultService as WebSuchService } from '@generated/web_such';
import { Configuration as ZufiConfiguration, DefaultService as ZufiService } from '@generated/zufi';
import { Plugin } from '@nuxt/types';

/** declare services at the store */
declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    // $cmsExternalTeaserService: ContentExternalTeaserService;
    $agsArsService: AgsArsService;
    $leikaService: LeikaService;
    $webSuchService: WebSuchService;
    $zufiService: ZufiService;
    $services: {
      formService: FormService;
      sessionService: SessionService;
      paymentService: PaymentService;
    };
  }
}

/** declare services to all vue instances */
declare module 'vue/types/vue' {
  interface Vue {
    // $cmsExternalTeaserService: ContentExternalTeaserService;
    $agsArsService: AgsArsService;
    $leikaService: LeikaService;
    $webSuchService: WebSuchService;
    $zufiService: ZufiService;
    $services: {
      formService: FormService;
      sessionService: SessionService;
      paymentService: PaymentService;
    };
  }
}

/** declare services for nuxt */
declare module '@nuxt/types' {
  interface NuxtAppOptions {
    // $cmsExternalTeaserService: ContentExternalTeaserService;
    $agsArsService: AgsArsService;
    $leikaService: LeikaService;
    $webSuchService: WebSuchService;
    $services: {
      formService: FormService;
      sessionService: SessionService;
      paymentService: PaymentService;
    };
  }
}

/**
 * A list of all base-urls for each service. Unfortunately, we can not use the base-url from the openapi-spec-file.
 *
 * ATTENTION: Url should not have a trailing slash.
 */
const BASE_PATH = {
  AgsArs: 'https://ags-ars.api.vsm.nrw',
  Leika: 'https://leika.vsm.nrw',
  WebSuch: 'https://web-suche.api.vsm.nrw',
  Zufi: 'https://zufi.api.vsm.nrw',
};

/** vue plugin to add all generated services */
const ServicePlugin: Plugin = (ctx, inject) => {
  // TODO DETOZGNRW-2203 watch locale-changes and adjust CMS-base-path accordingly
  // timeout for finishing/aborting request in ms
  // TODO https://jira.t-systems-mms.eu/browse/DETOZGNRW-373, after this ticket is solved, reset the timeout back to 1000 (or some other reasonable values) and fix the tests
  const vsmBaseOptions = {
    timeout: 30000,
    proxy: false,
  };

  inject(
    'agsArsService',
    new AgsArsService(new AgsArsConfiguration({ basePath: BASE_PATH.AgsArs, baseOptions: vsmBaseOptions }))
  );
  inject(
    'leikaService',
    new LeikaService(new LeikaConfiguration({ basePath: BASE_PATH.Leika, baseOptions: vsmBaseOptions }))
  );
  inject(
    'webSuchService',
    new WebSuchService(new WebSuchConfiguration({ basePath: BASE_PATH.WebSuch, baseOptions: vsmBaseOptions }))
  );
  inject(
    'zufiService',
    new ZufiService(new ZufiConfiguration({ basePath: BASE_PATH.Zufi, baseOptions: vsmBaseOptions }))
  );
  inject('services', useIntegrationServices(ctx.$config));
};

export default ServicePlugin;
