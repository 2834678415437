




















import { Vue, Component, Prop } from 'nuxt-property-decorator';

/**
 * NotFound component to render 404 not found error page.
 *
 * Props:
 * * error - the nuxt error
 *
 */
@Component({ name: 'NotFound' })
export default class NotFound extends Vue {
  @Prop({ required: true }) readonly error!: any;
}
