/*
 ** TailwindCSS Configuration File
 **
 ** Docs: https://tailwindcss.com/docs/configuration
 ** Default: https://github.com/tailwindcss/tailwindcss/blob/master/stubs/defaultConfig.stub.js
 */
module.exports = {
  future: {
    /**
     * purges all layers (base, components, and utilities) by default
     * https://v1.tailwindcss.com/docs/upcoming-changes#purge-layers-by-default
     */
    purgeLayersByDefault: true,
  },
  purge: {
    content: [
      './pages/**/*.vue',
      './components/**/*.vue',
      './layouts/**/*.vue',
      './plugins/**/*.vue',
      './plugins/**/*.ts',
    ],
    options: {
      /**
       * purgecss options can be placed here
       * see https://purgecss.com/configuration.html#options
       */
    },
  },
  theme: {
    screens: {
      sm: '640px',
      // => @media (min-width: 640px) { ... }

      md: '768px',
      // => @media (min-width: 768px) { ... }

      lg: '1024px',
      // => @media (min-width: 1024px) { ... }

      xl: '1280px',
      // => @media (min-width: 1280px) { ... }

      'max-height-xsm': { raw: '(max-height: 320px)' },
      // => @media (max-height: 320px) { ... }

      'max-height-sm': { raw: '(max-height: 640px)' },
      // => @media (max-height: 640px) { ... }

      'max-md': [{ max: '767px' }],
    },
    colors: {
      athensGray: '#f0f1f5',
      manateeGray: '#8D95A5',
      gallery: '#ededed',
      fiord: '#3c506e',
      fiord2: '#43506c',
      fiord3: '#3b4f6f',
      mischka: '#dadce1',
      alto: '#dadada',
      ghost: '#c5c7d1',
      aluminium: '#a4a7b3',
      deepCerulean: '#007db1',
      scorpion: '#595959',
      bahamaBlue: '#006397',
      orient: '#005b8c',
      biscay: '#1b4265',
      cloudBurst: '#243754',
      cloudBurst2: '#233755',
      mineShaft: '#333333',
      codGray: '#121212',
      black: '#000000',
      white: '#ffffff',
      grey: '#8c8c8c',
      grenadier: '#c83200',
      peach: '#fbceb1',
      cornflowerBlue: '#184a8f',
      cornflowerBlue2: '#1F4A8D',
    },
    fontSize: {
      xs: '.75rem',
      sm: '.875rem',
      base: '1rem',
      lg: '1.125rem',
      xl: '1.25rem',
      '2xl': '1.5rem',
      '3xl': '1.875rem',
      '2em': '2rem',
      '4xl': '2.25rem',
      '5xl': '3rem',
      '6xl': '4rem',
      '7xl': '5rem',
      '8xl': '6rem',
      404: '12.5rem',
    },
    inset: {
      '1/100': '1%',
      '1/50': '2%',
      '1/2': '50%',
      0: '0rem',
      4: '1rem',
      6: '1.5rem',
      16: '4rem',
      24: '6rem',
      28: '7rem',
      52: '13rem',
    },
    minWidth: {
      0: 0,
      20: '5rem',
      30: '7.5rem',
      50: '12.5rem',
      72: '18rem',
      75: '18.75rem',
    },
    maxWidth: {
      50: '12.5rem',
      80: '20rem',
      176: '44rem',
      225: '56.25rem',
      300: '75rem',
      xs: '20rem',
      full: '100%',
    },
    minHeight: {
      auto: 'auto',
      7: '1.75rem',
      11: '2.75rem',
      12: '3rem',
      full: '100vh',
    },
    maxHeight: {
      0: '0',
      200: '50rem',
      '1/4': '25%',
      '1/2': '50%',
      '3/4': '75%',
      '9/10': '90%',
      full: '100%',
      constM: '6.25rem',
    },
    extend: {
      spacing: {
        2: '0.5rem', // 8px
        2.5: '0.625rem', // 10px
        2.75: '0.6875rem', // 11px
        3: '0.75rem', // 12px
        3.5: '0.875rem', // 14px
        4.5: '1.125rem',
        6.5: '1.625rem', // 26px
        7: '1.75rem',
        9: '2.25rem', // 36px
        9.5: '2.375rem', // 38px
        10.5: '2.625rem',
        11: '2.75rem',
        14: '3.5rem',
        15: '3.75rem',
        18: '4.5rem',
        21: '5.25rem',
        22: '5.5rem',
        25: '6.25rem',
        26: '6.5rem',
        28: '7rem',
        30: '7.5rem',
        34: '8.5rem',
        36: '9rem',
        44: '11rem',
        48: '12rem',
        50: '12.5rem',
        52: '13rem',
        58: '14.5rem',
        62: '15.5rem',
        68: '17rem',
        72: '18rem',
        74: '18.5rem',
        75: '18.75rem',
        80: '20rem',
        84: '21rem',
        88: '22rem',
        98: '24.5rem',
        100: '25rem',
        112: '28rem',
        128: '32rem',
        132: '33rem',
        140: '35rem',
        152: '38rem',
        160: '40rem',
        168: '42rem',
        312: '78rem',
      },
      borderWidth: {
        0: '0',
        1: '1px',
        2: '2px',
        5: '5px',
      },
      boxShadow: {
        hover: '0px 0px 2px 2px rgba(92,160,255,1)',
        hoverInner: 'inset 0px 0px 2px 2px rgba(92,160,255,1)',
        mlg: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        lgs: '0 5px 25px 0px rgba(0, 0, 0, .1), 0 2px 9px -5px rgba(0, 0, 0, .1)',
        bg: '0px 8px 32px rgba(7,21,41,0.15)',
        rightSidebar: '0px 8px 32px rgba(7, 21, 41, 0.15)',
        searchbar: '0px 0px 10px 0px rgba(0, 0, 0, 0.2)',
        navigation: 'rgba(0, 0, 0, 0.1) 0 4px 16px 0',
        dropdown: '0 0 16px 4px rgba(0, 0, 0, 0.1)',
      },
      transitionProperty: {
        height: 'height',
        linear: 'linear',
      },
      inset: {
        sm: '0.125rem',
        '1/2': '50%',
        '1/4': '25%',
        '1/10': '10%',
        '-10000': '-625rem',
      },
      lineHeight: {
        6.5: '1.688rem', // 27px
      },
      width: {
        '1/2-vw': '50vw',
        '3/4-vw': '75vw',
        '1/20': '5%',
        '4/5': '80%',
        '19/20': '95%',
      },
      height: {
        '600px': '600px',
      },
      opacity: {
        95: '0.95',
      },
      zIndex: {
        2000: '2000',
      },
    },
  },
  variants: {
    backgroundColor: ['responsive', 'hover', 'even', 'odd', 'focus'],
    borderWidth: ['responsive, first', 'last'],
    padding: ['responsive, first', 'last'],
    fontWeight: ['responsive', 'hover', 'focus', 'active'],
    display: ['responsive', 'first'],
    appearance: ['hover', 'focus'],
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    function ({ addBase, theme }) {
      function extractColorVars(colorObj, colorGroup = '') {
        return Object.keys(colorObj).reduce((vars, colorKey) => {
          const value = colorObj[colorKey];

          const newVars =
            typeof value === 'string'
              ? { [`--color${colorGroup}-${colorKey}`]: value }
              : extractColorVars(value, `-${colorKey}`);

          return { ...vars, ...newVars };
        }, {});
      }

      addBase({
        ':root': extractColorVars(theme('colors')),
      });
    },
  ],
  important: '#sp',
};
