import { theme } from './tailwind.config';

/**
 * A function converts a breakpoint from a string ('1024px') to a number (1024)
 * @param breakpoint - breakpoint, which will be converted to a number
 */
export const getBreakPointAsNumber = (breakpoint) => {
  return /^[0-9]+px$/.test(breakpoint)
    ? Number(breakpoint.replace(/px/, ''))
    : (() => {
        throw new Error('The provided breakpoint is not valid');
      })();
};

const config = {
  breakPoints: {
    sm: getBreakPointAsNumber(theme.screens.sm),
    md: getBreakPointAsNumber(theme.screens.md),
    lg: getBreakPointAsNumber(theme.screens.lg),
    xl: getBreakPointAsNumber(theme.screens.xl),
  },
};

/**
 * an object contains custom media queries as css variables.
 * further custom media queries variables can be added here.
 * Note: please make sure to use postcss as value for lang
 * <style lang="postcss">....</style>
 */
export const customMedia = {
  '--min-xl': `(min-width: ${theme.screens.xl})`,
  '--min-lg': `(min-width: ${theme.screens.lg})`,
  '--min-md': `(min-width: ${theme.screens.md})`,
  '--min-sm': `(min-width: ${theme.screens.sm})`,

  '--max-xl': `(max-width: ${config.breakPoints.xl - 1}px)`,
  '--max-lg': `(max-width: ${config.breakPoints.lg - 1}px)`,
  '--max-md': `(max-width: ${config.breakPoints.md - 1}px)`,
  '--max-sm': `(max-width: ${config.breakPoints.sm - 1}px)`,
};

export default config;
