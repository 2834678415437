/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Geokodierung
 */
export interface Geokodierung {
    /**
     * 
     * @type {number}
     * @memberof Geokodierung
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof Geokodierung
     */
    'longitude'?: number;
}

/**
 * All properties of Geokodierung.
 * @export
 * @enum {string}
 */
export enum GeokodierungProperties {
    latitude = 'latitude',
    longitude = 'longitude'
}


