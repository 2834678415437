/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Stammtextmodel
 */
export interface Stammtextmodel {
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'schluessel'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'gruppierung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'ansprechpunkt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'bearbeitungsdauer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'erforderlicheUnterlagen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'fachlich_freigegeben_am'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'fachlich_freigegeben_durch'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'formular'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'hinweise'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'kosten'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'kurztext'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'rechtsgrundlage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'verfahrensablauf'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'volltext'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'vorraussetzung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'weiterfuehrende_information'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'kennung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'verrichtung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'verrichtungsdetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'bezeichnung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'bezeichnung2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'typ'?: string;
    /**
     * 
     * @type {string}
     * @memberof Stammtextmodel
     */
    'datum'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stammtextmodel
     */
    'besondere_merkmale'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Stammtextmodel
     */
    'synonyme'?: Array<string>;
}

/**
 * All properties of Stammtextmodel.
 * @export
 * @enum {string}
 */
export enum StammtextmodelProperties {
    schluessel = 'schluessel',
    gruppierung = 'gruppierung',
    ansprechpunkt = 'ansprechpunkt',
    bearbeitungsdauer = 'bearbeitungsdauer',
    erforderlicheUnterlagen = 'erforderlicheUnterlagen',
    fachlich_freigegeben_am = 'fachlich_freigegeben_am',
    fachlich_freigegeben_durch = 'fachlich_freigegeben_durch',
    formular = 'formular',
    hinweise = 'hinweise',
    kosten = 'kosten',
    kurztext = 'kurztext',
    rechtsgrundlage = 'rechtsgrundlage',
    verfahrensablauf = 'verfahrensablauf',
    volltext = 'volltext',
    vorraussetzung = 'vorraussetzung',
    weiterfuehrende_information = 'weiterfuehrende_information',
    kennung = 'kennung',
    verrichtung = 'verrichtung',
    verrichtungsdetail = 'verrichtungsdetail',
    bezeichnung = 'bezeichnung',
    bezeichnung2 = 'bezeichnung2',
    typ = 'typ',
    datum = 'datum',
    besondere_merkmale = 'besondere_merkmale',
    synonyme = 'synonyme'
}


