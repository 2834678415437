/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Leika API
 * Liefert Leistungsdaten zurück
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Leika
 */
export interface Leika {
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Ansprechpunkt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Fristen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Bezeichnung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Kosten(Gebühren, Auslagen,etc.)'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Verrichtung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Verrichtungsdetail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Bezeichnung2'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Formulare'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Volltext'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Weiterführende Informationen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'SynonymeVSM'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Rechtsgrundlage'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Schluessel'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Verfahrensablauf'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Bearbeitungsablauf'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Bearbeitungsdauer'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Veröffentlichungsdatum'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Urheber'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Voraussetzungen'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Leistungsgruppierung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Leistungskennung'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Kurztext'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Leika
     */
    'Besondere Merkmale'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Hinweise (Besonderheiten)'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Typisierung'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Leika
     */
    'Synonyme'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Fachlich freigegeben durch'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Fachlich freigegeben am'?: string;
    /**
     * 
     * @type {string}
     * @memberof Leika
     */
    'Erforderliche Unterlagen'?: string;
    /**
     * only on search with query parameter q
     * @type {number}
     * @memberof Leika
     */
    'confidence'?: number;
}

/**
 * All properties of Leika.
 * @export
 * @enum {string}
 */
export enum LeikaProperties {
    Ansprechpunkt = 'Ansprechpunkt',
    Fristen = 'Fristen',
    Bezeichnung = 'Bezeichnung',
    Kosten_Gebhren_Auslagenetc_ = 'Kosten(Gebühren, Auslagen,etc.)',
    Verrichtung = 'Verrichtung',
    Verrichtungsdetail = 'Verrichtungsdetail',
    Bezeichnung2 = 'Bezeichnung2',
    Formulare = 'Formulare',
    Volltext = 'Volltext',
    Weiterfhrende_Informationen = 'Weiterführende Informationen',
    SynonymeVSM = 'SynonymeVSM',
    Rechtsgrundlage = 'Rechtsgrundlage',
    Schluessel = 'Schluessel',
    Verfahrensablauf = 'Verfahrensablauf',
    Bearbeitungsablauf = 'Bearbeitungsablauf',
    Bearbeitungsdauer = 'Bearbeitungsdauer',
    Verffentlichungsdatum = 'Veröffentlichungsdatum',
    Urheber = 'Urheber',
    Voraussetzungen = 'Voraussetzungen',
    Leistungsgruppierung = 'Leistungsgruppierung',
    Leistungskennung = 'Leistungskennung',
    Kurztext = 'Kurztext',
    Besondere_Merkmale = 'Besondere Merkmale',
    Hinweise__Besonderheiten = 'Hinweise (Besonderheiten)',
    Typisierung = 'Typisierung',
    Synonyme = 'Synonyme',
    Fachlich_freigegeben_durch = 'Fachlich freigegeben durch',
    Fachlich_freigegeben_am = 'Fachlich freigegeben am',
    Erforderliche_Unterlagen = 'Erforderliche Unterlagen',
    /**
     * only on search with query parameter q
     */
    confidence = 'confidence'
}


