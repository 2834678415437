import { OrtModel } from '@/generated/ags_ars';
import { Leika } from '@/generated/leika';
import { SimpleImage } from '@/interfaces/atomic/image/image';
import { Service } from '@/interfaces/service';
import { WebTreffer } from '@generated/web_such';
import { CustomizedLink } from './name-with-link';

/**
 * SearchAPI
 *
 * enumeration to divide from which api the suggestion comes from
 */
export enum SearchAPI {
  /**
   * Internal cms api
   */
  cms,
  /**
   * All vsm service items
   */
  service,
  /**
   * All web results
   */
  webResult,
  /**
   * ags/ars location api
   */
  location,
  /**
   * All search results
   */
  allContent,
}

/**
 * ContentType
 *
 * Enum to define service and circumstance which is needed in FullSearch
 * this is a navigation help in the frontend
 */
export enum ContentType {
  topic = 'themenfeld',
  service = 'leistung',
  circumstances = 'lebenslage',
  information = 'page',
  externalTeaser = 'externer_teaser',
  external = 'external',
}

/**
 * FullSearch - CMS Api
 */
export interface FullSearch {
  /**
   * Title of the content type
   */
  title: string;
  /**
   * Node id
   */
  nid: string;
  /**
   * url of node
   */
  viewNode: string;
  /**
   * Relative image path
   *
   * @see {@link SimpleImage}
   */
  image: SimpleImage;
  /**
   * Description of the content type
   */
  body: {
    processed: string;
  };
  /**
   * uuid of the given content type
   */
  uuid: string;
  /**
   * Enum which is need for the navigation
   *
   * @see {@link ContentType}
   */
  type: ContentType;
}

/**
 * Customized interface for display responsible authority information
 */
export interface ZufiServiceOrganisation {
  /**
   * Name of the responsible authority
   */
  name: string;
  /**
   * Address of the responsible authority
   */
  address: string;
  /**
   * Phone number
   */
  phone: string;
  /**
   * Fax number
   */
  fax: string;
  /**
   * Mail address
   */
  mail: string;
  /**
   * Name of the service
   */
  service: string;
}

/**
 * SearchResult
 *
 * Represents the items for the Auto-Suggestion and Search Result List
 */
export interface SearchResult {
  /**
   * Name of the auto suggestion
   */
  name: string;
  /**
   * Unique id for auto suggestion
   */
  key?: string;
  /**
   * Enum SearchAPI to decide which suggestion belongs to which cluster
   *
   * @see {@link SearchAPI}
   */
  type: SearchAPI;
  /**
   * Short description (optional) is used for display search result item
   */
  description?: string;
  /**
   * LocalePath nuxt link object or href string from search result
   *
   * @see {@link CustomizedLink}
   */
  link?: CustomizedLink | string;
  /**
   * Detailed information for search result in 'raw' format
   *
   * @see {@link OrtModel}
   * @see {@link Leika}
   * @see {@link SearchResult}
   * @see {@link FullSearch}
   * @see {@link ZufiServiceOrganisation}
   * @see {@link WebTreffer}
   */
  raw?: OrtModel | Leika | FullSearch | ZufiServiceOrganisation | WebTreffer;
  /**
   * Indicates if this search result is a CMS service or not
   *
   * @see {@link Service}
   */
  cmsService?: Service;
  /**
   * The image of a portal result
   *
   * @see {@link SimpleImage}
   */
  image?: SimpleImage;
  /**
   * Id of the leika service result
   */
  leikaId?: string;
  /**
   * Title of the leika service result
   */
  leikaServiceTitle?: string;
}

/**
 * SearchValue
 *
 * This is an interface to handle search value and storing suggested value
 * this is necessary to handle the location because only a suggested location
 * can be treated
 */
export interface SearchValue {
  /**
   * Search keyword
   */
  value: string;
  /**
   * Item of auto suggestion
   *
   * @see {@link SearchResult}
   */
  suggestion: SearchResult | null;
}

/**
 * LocationSearchKey
 *
 * wrapper to handle service search with location
 */
export interface LocationSearchKey {
  /**
   * Regionalschluessel
   */
  localKey: string;
  /**
   * Gemeindeschluessel
   */
  communeKey: string;
  /**
   * Verbandsschluessel
   */
  unitKey: string;
}

/**
 * The response structure for fetching topics and circumstances related to a CMS service
 */
export interface CmsServiceRelatedTopicAndCircumstance {
  /* eslint-disable camelcase */
  lebenslage_title: string;
  lebenslage_uuid: string;
  body: string;
  themenfeld_title: string;
  themenfeld_uuid: string;
  description___value: string;
  /* eslint-enable camelcase */
}
