import Vue from 'vue';
import { Plugin } from '@nuxt/types';
import config from '@/config';
import debounce from 'lodash/debounce';

/**
 * Enumeration of all Screen-sizes from small to extra large.
 *
 * IMPORTANT: The order of all screenSizes have to be from the smallest to the largest one!
 */
export enum ScreenSize {
  MOBILE = 'MOBILE',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  EXTRA_LARGE = 'EXTRA_LARGE',
}

/**
 * Class representing information about the current client window dimensions.
 */
export class ClientDimensions {
  constructor(private _innerWidth: number, private _innerHeight: number, private _screenSize: ScreenSize) {}

  public get innerWidth(): number {
    return this._innerWidth;
  }

  public set innerWidth(newInnerWidth: number) {
    this._innerWidth = newInnerWidth;
  }

  public get innerHeight(): number {
    return this._innerHeight;
  }

  public set innerHeight(newInnerHeight: number) {
    this._innerHeight = newInnerHeight;
  }

  public get screenSize(): ScreenSize {
    return this._screenSize;
  }

  public set screenSize(newScreenSize: ScreenSize) {
    this._screenSize = newScreenSize;
  }

  /**
   * Method return if the given screenSize is greater or equal the current screenSize
   *
   * @param screenSize - screenSize to check
   */
  public isLargerOrEqual(screenSize: ScreenSize): boolean {
    return Object.keys(ScreenSize).indexOf(this._screenSize) >= Object.keys(ScreenSize).indexOf(screenSize);
  }
}

/** declare new property */
declare module 'vue/types/vue' {
  interface Vue {
    $clientDimensions: ClientDimensions;
  }
}

const EventListenerPlugin: Plugin = (_, inject) => {
  /**
   * Calculate the screen size depending on the innerWidth of the client.
   */
  const getScreenSize = (innerWidth: number): ScreenSize => {
    let screenSize: ScreenSize;
    switch (true) {
      case innerWidth >= config.breakPoints.xl:
        screenSize = ScreenSize.EXTRA_LARGE;
        break;
      case innerWidth >= config.breakPoints.lg:
        screenSize = ScreenSize.LARGE;
        break;
      case innerWidth >= config.breakPoints.md:
        screenSize = ScreenSize.MEDIUM;
        break;
      case innerWidth >= config.breakPoints.sm:
        screenSize = ScreenSize.SMALL;
        break;
      default:
        screenSize = ScreenSize.MOBILE;
        break;
    }
    return screenSize;
  };

  /**
   * Client dimensions are initially 0 - 0 and have a ScreenSize 'mobile' - because the idea of tailwind is
   * "mobile first". So the server rendered content is for mobile screens.
   */
  const clientDimensions = Vue.observable<ClientDimensions>(new ClientDimensions(0, 0, ScreenSize.MOBILE));

  /**
   * Calculate the current client dimensions
   */
  const setClientDimensions = (): void => {
    const innerWidth = global.innerWidth;
    clientDimensions.innerWidth = innerWidth;
    clientDimensions.innerHeight = global.innerHeight;
    clientDimensions.screenSize = getScreenSize(innerWidth);
  };

  if (process.client) {
    // add resize listener on client
    window.addEventListener('resize', debounce(setClientDimensions, 250));

    /* set the current client dimensions if the app is ready otherwise we could run into hydration errors
     (Mismatching childNodes vs. VNodes) because we have no client dimensions on the server. */
    (window as any).onNuxtReady(setClientDimensions);
  }

  inject('clientDimensions', clientDimensions);
};

export default EventListenerPlugin;
