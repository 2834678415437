/**
 * validates a phone number
 *
 * A phone number consist of:
 * - ICC: international calling code (optional)
 * - NSN: national significant number
 * - DDI: direct dial in (aka PBX) (optional)
 *
 * This validation allows an optional '+' (trunk) prefix as replacement for the '00' notation.
 *
 * The shortest number can be calculated by:
 * ICC + SNS
 *
 * The shortest possible number:
 * Saint Helena, having 4 digits but 3 digits for the ICC.
 * Australia allows down to 5 digits for the NSN but the ICC is only 2 digits.
 *
 * So the shortest number has a trunk prefix plus 7 digits: (+XX XXXXX)
 *                                                          (ICC  NSN )
 * Some people on the countryside in germany may still have a 7 digit number as well:
 * Area code (5 digits) + Subscriber number (2 digits).
 *
 * The longest number as of the E.164 standard by the  ITU Telecommunication Standardization Sector (ITU-T)
 * can have 15 digits plus optional DDI. The DDI however is not registered at the telephone company but
 * at the local telephone system (up to 11 digits).
 *
 * The longest number can have 31 digits (00XXX XXXXXXXXXXXXXX XXXXXXXXXXX)
 *                                       ( ICC       NSN           DDI    )
 *
 * @param value - phone number
 */
export const validatePhoneNumber = (value: string): boolean => {
  const phoneExpression = /^(?:\+)?[\s0-9]{7,31}$/;
  return !!value.match(phoneExpression);
};
