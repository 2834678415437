/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FormPaymentDataDto
 */
export interface FormPaymentDataDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof FormPaymentDataDto
     */
    'availableMethods': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FormPaymentDataDto
     */
    'fee': number;
    /**
     * 
     * @type {boolean}
     * @memberof FormPaymentDataDto
     */
    'skipAllowed': boolean;
}

/**
 * All properties of FormPaymentDataDto.
 * @export
 * @enum {string}
 */
export enum FormPaymentDataDtoProperties {
    availableMethods = 'availableMethods',
    fee = 'fee',
    skipAllowed = 'skipAllowed'
}


