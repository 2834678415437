import { Module, VuexModule } from 'vuex-module-decorators';
import { TopicFields } from '@/interfaces/topic-fields';
import { TopicCard } from '@/interfaces/topic-card';

/**
 * Name of the module. Have to be the same like the generated by nuxt (path to this file ignoring index.js)!
 * @see {@link https://github.com/nuxt/nuxt.js/blob/9681a8937d0c973bc685b757faa686023a4536fc/packages/vue-app/template/store.js#L87}
 */
export const MODULE_NAME = 'search-topic-fields';

/**
 * This module is used for manipulating the 'topicFields'. The 'topicFields' is an array and includes
 * all properties of a 'TopicField'. This could contain an image, a title, a paragraph and the topic Id.
 * This state is needed by the topic fields preview on start page and also for the footer menu.
 *
 * We set the stateFactory to "true", because:
 * 1. It is needed for generating a state factory function instead of object
 * 2. Nuxtjs need a state factory function
 * @see {@link https://github.com/championswimmer/vuex-module-decorators#module-re-use-use-with-nuxtjs}
 */
@Module({ name: MODULE_NAME, namespaced: true, stateFactory: true })
export default class SearchTopicFieldsModule extends VuexModule {
  /******************************************************************************************************************
   * vuex state
   ******************************************************************************************************************/

  /** list of topic fields */
  private topicFields: TopicFields[] = [
    {
      name: 'Arbeit & Ruhestand',
      description: {
        processed: '<p> </p>\n\n\n ',
      },
      weight: 0,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld „Arbeit &amp; Ruhestand“ befasst sich mit verschiedensten Sozialleistungen für Bürgerinnen und Bürger. In insgesamt fünf Lebenslagen, die vom (drohenden) Arbeitsplatzverlust und anschließender Arbeitssuche über die finanzielle Existenzsicherung/ Unterstützung bei finanziellen Problemen sowie den Arbeitsplatzwechsel bis hin zur Altersvorsorge und der Rente/ sozialen Entschädigung reichen, werden vor allem Leistungen, die sich an hilfebedürftige und unterstützungsbedürftige Bürgerinnen und Bürger richten, bearbeitet. Der Vollzug der Leistungen erfolgt neben dem Vollzug durch diverse Bundes-, Landes- sowie kommunale Behörden auch durch weitere Anbieter wie z. B. die Caritas.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld „Arbeit &amp; Ruhestand“ befasst sich mit verschiedensten Sozialleistungen für Bürgerinnen und Bürger.</p>\n',
      },
      id: 'e72b44c8-1835-4588-9627-f0820696996b',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Hobel', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Hobel Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Hobel', title: '', width: 6000, height: 1250 },
          imgLink: { value: 'public://images/Hobel_C.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            { mediaLibrary: '/sites/default/files/images/Start.png' },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Bauen & Wohnen',
      description: undefined,
      weight: 1,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld „Bauen &amp; Wohnen“ umfasst die drei Lebens- und Geschäftslagen „Bauen &amp; Immobilien“, „Hausbau &amp; Immobilienerwerb“ sowie „Wohnen &amp; Umzug“.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld „Bauen &amp; Wohnen“ umfasst die drei Lebens- und Geschäftslagen „Bauen &amp; Immobilien“, „Hausbau &amp; Immobilienerwerb“ sowie „Wohnen &amp; Umzug“.</p>',
      },
      id: '5cf6f3b3-c20f-4ce0-ad73-903f9e65ffad',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Haus auf Bauplänen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Haus auf Bauplänen Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Haus auf Bauplänen', title: '', width: 6000, height: 1250 },
          imgLink: {
            value: 'public://images/Haus auf Bauplänen_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
    },
    {
      name: 'Bildung',
      description: undefined,
      weight: 2,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld bündelt Leistungen in den vier Lebenslagen „Schule“, „Weiterbildung“, „Studium“ und „Berufsausbildung“.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld bündelt Leistungen in den vier Lebenslagen „Schule“, „Weiterbildung“, „Studium“ und „Berufsausbildung“.</p>',
      },
      id: '783d5e8a-5b7f-4c0e-8d2f-722c7c5d3e63',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Bücher mit Stift', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Bücher mit Stift Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Bücher mit Stift', title: '', width: 4500, height: 1250 },
          imgLink: {
            value: 'public://images/Bücher mit Stift 4500_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
    },
    {
      name: 'Einwanderung, Aufenthalt, Integration und Staatsangehörigkeit',
      description: undefined,
      weight: 3,
      fieldAllgemeineDescription: {
        processed:
          '<p><span><span>Das Themenfeld „Einwanderung, Aufenthalt, Integration und Staatsangehörigkeit“ fasst Leistungen zusammen, die die Einreise nach Deutschland\nbetreffen, außerdem den Aufenthalt von ausländischen Personen sowie Leistungen die deutsche Staatsangehörigkeit betreffend. Die Leistungen sind\nauf viele Lebenslagen verteilt, deren Rechtsgrundlagen primär das Ausländerrecht und das Staatsangehörigkeitsrecht sind. Dabei sind die\nNutzergruppen sehr unterschiedlich. Die Leistungen decken dabei den gesamten Aufgabenbereich ab, bei der Einwanderung beginnend bei der\nVisabeantragung, den weiteren Aufenthalt, die Einbürgerung über den Wegzug bis zur möglichen Aufgabe der deutschen Staatsangehörigkeit.</span></span></p>',
      },
      fieldTeaserText: {
        processed:
          '<p><span><span>Das Themenfeld „Einwanderung, Aufenthalt, Integration und Staatsangehörigkeit“ fasst sämtliche Leistungen zusammen, die die Einreise und den<br />\nAufenthalt in der Bundesrepublik betreffen. Dieses Spektrum betrifft ausländische und eingebürgerte Personen sowie Staatenlose.</span></span></p>',
      },
      id: '2cd565e1-6936-4ba7-8e03-6c3b6d7ac8e2',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Arme mit dem Daumen oben', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Arme oben Teaser.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Arme mit dem Daumen oben', title: '', width: 4500, height: 1250 },
          imgLink: { value: 'public://images/Arme oben_C.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Engagement & Hobby',
      description: undefined,
      weight: 4,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld „Engagement &amp; Hobby“ umfasst die fünf Lebenslagen „Bootsbesitz“, „Fischen und Jagen“, „Engagement und Beteiligung“, „Tierhaltung“ und „Veranstaltung durchführen“.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld „Engagement &amp; Hobby“ umfasst die fünf Lebenslagen „Bootsbesitz“, „Fischen und Jagen“, „Engagement und Beteiligung“, „Tierhaltung“ und „Veranstaltung durchführen“.</p>',
      },
      id: 'f2041819-d8d3-4c88-aca8-170e04eda1fb',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Kinder halten Gegenstände hoch', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Wohnzimmer Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Kinder haltenGegenstände hoch', title: '', width: 6000, height: 1250 },
          imgLink: { value: 'public://images/Wohnzimmer_C.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            { mediaLibrary: '/sites/default/files/images/Start.png' },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Familie & Kind',
      description: undefined,
      weight: 5,
      fieldAllgemeineDescription: {
        processed:
          '<p><span><span>Das Themenfeld umfasst verschiedene Leistungen für Familien. Das Ministerium für Kinder, Familie, Flüchtlinge und Integration des Landes Nordrhein-Westfalen entwickelt derzeit ein Portal, das sich an Familien im Land richtet. Über dieses Portal erhalten Familien einen digitalen Zugang zu den Leistungen des Themenfelds sowie weiterführenden Informationen rund um das Familienleben. Wenn das Portal verfügbar ist, erreichen Sie es über einen Link an dieser Stelle. Bis dahin bitten wir Sie noch um etwas Geduld.</span></span></p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Alle Verwaltungsleistungen, die das Familienleben direkt betreffen – in Lebenslagen wie „Geburt“, „Ehe“, „Kinderbetreuung“, aber auch „Adoption“, „Scheidung“ und „Trennung mit Kind“.</p>',
      },
      id: 'fa831c2d-44d9-4cc6-baa4-d20b726f9019',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Vorlesen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Vorlesen Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Vorlesen', title: '', width: 6000, height: 1250 },
          imgLink: { value: 'public://images/Vorlesen_c.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            { mediaLibrary: '/sites/default/files/images/Start.png' },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Forschung & Förderung',
      description: undefined,
      weight: 6,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenspektrum reicht von der Erlaubnis zur Herstellung von Arzneimitteln über die Genehmigung von Pflegesätzen, die Förderung von Forschung, Kultur und Wirtschaft bis zur Zulassung zum Börsenhandel.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenspektrum reicht von der Erlaubnis zur Herstellung von Arzneimitteln über die Genehmigung von Pflegesätzen, die Förderung von Forschung, Kultur und Wirtschaft bis zur Zulassung zum Börsenhandel.</p>',
      },
      id: '959e146e-6f6a-407d-9e6b-f91456f57593',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Mikroskop', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Mikroskop Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Mikroskop', title: '', width: 6000, height: 1250 },
          imgLink: { value: 'public://images/Mikroskop_C.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            { mediaLibrary: '/sites/default/files/images/Start.png' },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Gesundheit',
      description: undefined,
      weight: 7,
      fieldAllgemeineDescription: {
        processed:
          '<p>Neben den Bereichen der Gesundheitsvorsorge und Heilbehandlung, umfassen die über 60 OZG-Leistungen des Themenfelds zusätzlich die Gebiete der Pflege, Leistungen für Menschen mit Behinderung und die Hinterbliebenenversorgung.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Neben den Bereichen der Gesundheitsvorsorge und Heilbehandlung, umfassen die über 60 OZG-Leistungen des Themenfelds zusätzlich die Gebiete der Pflege, Leistungen für Menschen mit Behinderung und die Hinterbliebenenversorgung.</p>',
      },
      id: '4edcdc5c-bc0b-4d27-a893-ac4ddb548642',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Würfel mit Gesundheitssymbolen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Würfel Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Würfel mit Gesundheitssymbolen', title: '', width: 4500, height: 1250 },
          imgLink: {
            value: 'public://images/Würfel 4500_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Mobilität & Reisen',
      description: undefined,
      weight: 8,
      fieldAllgemeineDescription: {
        processed:
          '<p>Mobilität ist ein zentraler Faktor für Wirtschaft und Gesellschaft in Nordrhein-Westfalen. Unabhängig davon, ob Sie privat oder beruflich auf unseren Straßen, unseren Schienen in der Luft unterwegs sein wollen, hier finden Sie Informationen rund um Fahrzeuge, Führerscheine, Logistik und Auslandsaufenthalte.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Mobilität ist ein zentraler Faktor für Wirtschaft und Gesellschaft in Nordrhein-Westfalen. Unabhängig davon, ob Sie privat oder beruflich auf unseren Straßen, unseren Schienen oder in der Luft unterwegs sein wollen: Hier finden Sie Informationen rund um Fahrzeuge, Führerscheine, Logistik und Auslandsaufenthalte.</p>',
      },
      id: 'feb974fd-90ba-4c8d-ac34-7630e2d2541e',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Ladesäule für E-Autos', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Ladesäule Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Ladesäule für E-Autos', title: '', width: 6000, height: 1250 },
          imgLink: {
            value: 'public://images/Elektro Ladesäule_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Querschnittsleistungen',
      description: undefined,
      weight: 9,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld „Querschnittleistungen“ befasst sich mit grundlegenden Verwaltungsleistungen, insbesondere aus den Bereichen des Nachweis-, Melde- und Registerauskunftswesens. Leistungen in diesem Themenfeld sind oft relevant als Voraussetzung für eine spätere Beantragung bzw. den Bezug von Verwaltungsleistungen aus anderen Themenfeldern.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld „Querschnittleistungen“ befasst sich mit grundlegenden Verwaltungsleistungen, insbesondere aus den Bereichen des Nachweis-, Melde- und Registerauskunftswesens. Leistungen in diesem Themenfeld sind oft relevant als Voraussetzung für eine spätere Beantragung bzw. den Bezug von Verwaltungsleistungen aus anderen Themenfeldern.</p>',
      },
      id: 'ed579216-8831-4467-a61c-3db2ddd38d37',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Menschen gehen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Menschen Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Menschen gehen ', title: '', width: 6000, height: 1250 },
          imgLink: { value: 'public://images/Menschen_C.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            { mediaLibrary: '/sites/default/files/images/Start.png' },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Recht & Ordnung',
      description: undefined,
      weight: 10,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld „Recht &amp; Ordnung“ befasst sich mit Leistungen aus den Bereichen gerichtliche und außergerichtliche Verfahren, Naturkatastrophen, Rechtsverstöße, Hilfen für Opfer sowie Fundsachen und Notfallrettung.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld „Recht &amp; Ordnung“ befasst sich mit Leistungen aus den Bereichen gerichtliche und außergerichtliche Verfahren, Naturkatastrophen, Rechtsverstöße, Hilfen für Opfer sowie Fundsachen und Notfallrettung.</p>',
      },
      id: '5fb5512a-94b0-4e71-9f3b-a655206b545b',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Justizia', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Justizia Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Justizia', title: '', width: 6000, height: 1250 },
          imgLink: { value: 'public://images/Justizia_C.jpg', url: '/sites/default/files/images/Start.png' },
          imageStyleUri: [
            { mediaLibrary: '/sites/default/files/images/Start.png' },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Steuern & Zoll',
      description: undefined,
      weight: 11,
      fieldAllgemeineDescription: {
        processed:
          '<p>Der Bereich „Steuererklärung“ ist sowohl für Unternehmen als auch für Bürgerinnen und Bürger relevant, während die Bereiche „Steuern und Abgaben“ und „Auslandsgeschäft“ nur Unternehmen betreffen.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Im Folgenden finden Sie Informationen zu den Themen „Steuern und Abgaben“, „Auslandsgeschäft“ sowie „Steuererklärung“.</p>',
      },
      id: 'ad6ca929-ac4c-421a-b476-0fc05b5003cb',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Zoll Douane Schild', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Zoll Douane Schild Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Zoll Douane Schild', title: '', width: 5500, height: 1250 },
          imgLink: {
            value: 'public://images/Zoll Douane Schild_C .jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Umwelt',
      description: undefined,
      weight: 12,
      fieldAllgemeineDescription: {
        processed:
          '<p>Das Themenfeld „Umwelt“ umfasst Leistungen, die primär von Unternehmen oder Berufsausübenden, in einigen Fällen aber auch von Bürgerinnen und Bürgern in Anspruch genommen werden. Die Leistungen sind auf die beiden weit gefassten Geschäftslagen „Anlagen und Stoffe“ sowie „Abfall und Umweltschutz“ verteilt, betreffen neben den klassischen Umweltmedien aber beispielsweise auch Leistungen im Bereich von Naturschutz, Land- und Forstwirtschaft und Veterinärwesen.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>Das Themenfeld „Umwelt“ umfasst Leistungen, die primär von Unternehmen oder Berufsausübenden, in einigen Fällen aber auch von Bürgerinnen und Bürgern in Anspruch genommen werden. Die Leistungen sind auf die beiden weit gefassten Geschäftslagen „Anlagen und Stoffe“ sowie „Abfall und Umweltschutz“ verteilt, betreffen neben den klassischen Umweltmedien aber beispielsweise auch Leistungen im Bereich von Naturschutz, Land- und Forstwirtschaft und Veterinärwesen.</p>',
      },
      id: '162621e5-5481-4229-97a2-e485e4ecb926',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Berglandschaft', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Berglandschaft Teaser_ C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Berglandschaft', title: '', width: 6000, height: 1250 },
          imgLink: {
            value: 'public://images/Berglandschaft_C_1.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            { medium: '/sites/default/files/images/Start.png' },
            { thumbnail: '/sites/default/files/images/Start.png' },
            { large: '/sites/default/files/images/Start.png' },
          ],
        },
      },
    },
    {
      name: 'Unternehmensführung & -entwicklung',
      description: undefined,
      weight: 13,
      fieldAllgemeineDescription: {
        processed:
          '<p>In dem Themenfeld „Unternehmensführung &amp; -entwicklung“ sind die Leistungen auf acht Geschäftslagen verteilt, die vom Unternehmensstart über Arbeitgebersein, Gewährleistung der Arbeitssicherheit, Teilnahme an Ausschreibungen bis hin zur Unternehmensauflösung reichen.</p>',
      },
      fieldTeaserText: {
        processed:
          '<p>In dem Themenfeld „Unternehmensführung &amp; -entwicklung“ sind die Leistungen auf acht Geschäftslagen verteilt, die vom Unternehmensstart über Arbeitgebersein, Gewährleistung der Arbeitssicherheit, Teilnahme an Ausschreibungen bis hin zur Unternehmensauflösung reichen.</p>',
      },
      id: '06c76712-e3a0-4ad6-9edc-c776534123dc',
      fieldTeaserImage: {
        fieldMediaImage1: {
          meta: { alt: 'Taschenrechner und Vernetzungen', title: '', width: 960, height: 640 },
          imgLink: {
            value: 'public://images/Taschenrechner und Vernetzungen Teaser_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
      fieldThemenfeldCover: {
        fieldMediaImage1: {
          meta: { alt: 'Taschenrechner', title: '', width: 6000, height: 1250 },
          imgLink: {
            value: 'public://images/Taschenrechner und Vernetzungen_C.jpg',
            url: '/sites/default/files/images/Start.png',
          },
          imageStyleUri: [
            {
              mediaLibrary: '/sites/default/files/images/Start.png',
            },
            {
              medium: '/sites/default/files/images/Start.png',
            },
            {
              thumbnail: '/sites/default/files/images/Start.png',
            },
            {
              large: '/sites/default/files/images/Start.png',
            },
          ],
        },
      },
    },
  ];

  /******************************************************************************************************************
   * vuex getter
   ******************************************************************************************************************/

  get topics(): TopicFields[] {
    return this.topicFields;
  }

  get topicsAsCard(): TopicCard[] {
    const topics = this.topicFields.map((topic) => ({
      title: topic.name,
      img: topic.fieldTeaserImage?.fieldMediaImage1,
      description: topic.fieldTeaserText.processed,
      topicId: topic.id,
      weight: topic.weight,
    }));
    return topics;
  }
}
