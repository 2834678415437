import Vue from 'vue';
// Vue Formulate is currently missing a declaration
// @TODO DETOZGNRW-1596 https://github.com/wearebraid/vue-formulate/issues/123
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueFormulate from '@braid/vue-formulate';
import { validatePhoneNumber } from '@/utils/validation/phone.validator';

declare module 'vue/types/vue' {
  interface Vue {
    // @TODO DETOZGNRW-1596 There's currently no definition present: https://github.com/wearebraid/vue-formulate/issues/123
    $formulate: any;
  }
}
type VueFormulatValidation = (() => boolean) | ((...args: any) => string);

export default ({ app }: { app: any }): void => {
  const i18n = app.i18n;

  Vue.use(VueFormulate, {
    rules: {
      phone: ({ value }: { value: string }): boolean => validatePhoneNumber(value),
    },
    locale: 'customLocale',
    locales: {
      customLocale: new Proxy(
        {},
        {
          get(_: unknown, prop: string): VueFormulatValidation {
            // fake that every requested property aka rule is available
            if (prop === 'hasOwnProperty') {
              return (): boolean => true;
            }

            // call translate function when rule translation is requested
            return (ctx: { vm: any; value: unknown; args: unknown[] }): string => {
              // using array interpolation syntax ("value" first, followed by all rule args)
              return i18n.t(`validation.${prop}`, [ctx.value, ...ctx.args]);
            };
          },
        }
      ),
    },
  });
};
