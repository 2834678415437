import { Context, Plugin } from '@nuxt/types';
import { AsyncComponent, Component } from 'vue/types/options';
import { DialogEvent, DialogEventCallbackWrapper } from '@/interfaces/dialog';
import DialogContainerComponent from './DialogContainer.vue';

/** dialog options */
/*
 * TODO https://jira.t-systems-mms.eu/browse/DETOZGNRW-2391 - Ensure type safety between the component calling
 *  '.$dialog<Type>' and setting the generic type, and the component emitting the dialog event with the value
 *  which should be of the same type as the set generic
 */
export interface Options {
  /** title key */
  titleKey: string;
  /** component to render */
  // eslint-disable-next-line no-use-before-define
  component: Comp;
  /** props for component to render */
  props?: Record<string, unknown>;
  /** modal */
  modal?: boolean;
}

/** component type */
declare type Comp = Component<any, any, any, any> | AsyncComponent<any, any, any, any>;
/** declare new properties */
declare module 'vue/types/vue' {
  interface Vue {
    $dialog: <T>(options: Options) => DialogEventCallbackWrapper<T>;
  }
}

/**
 * Manager for the single open window.
 */
class DialogManager {
  /** container */
  private dialogContainer!: DialogContainerComponent | null;
  /** modal layer */
  private modal!: HTMLDivElement | null | undefined;
  /** body styles */
  private bodyOverflow!: string;
  /** pageYOffset */
  private pageYOffset!: number;

  /** constructor which takes the context */
  constructor(private ctx: Context) {}

  /**
   * Opens a dialog.
   *
   * @param options - The dialog options
   */
  openDialog<T>(options: Options): DialogEventCallbackWrapper<T> {
    this.pageYOffset = window.pageYOffset;
    if (this.dialogContainer) {
      this.closeDialog();
    }
    const context = this.buildDialogContext();
    this.dialogContainer = this.initDialogContainer(context, options);
    this.mountDialog();
    // mount modal layer afterwards
    if (options.modal) {
      this.mountModal();
    }
    return this.initDialogEventCallbackHandler<T>();
  }

  /**
   * Returns element mount to.
   */
  private get elementMountTo(): HTMLElement {
    return window.document.querySelector<HTMLElement>('#sp') || window.document.body;
  }

  /**
   * Mounts the modal
   */
  private mountModal(): void {
    this.modal = window.document.createElement('div');
    this.modal.className = 'fixed inset-0 z-50 opacity-75 bg-cornflowerBlue';
    this.modal.setAttribute('data-modal', '');
    this.elementMountTo.prepend(this.modal);
    this.bodyOverflow = window.document.body.style.overflow;
    window.document.body.style.overflow = 'hidden';
  }

  /**
   * Mounts the dialog
   */
  private mountDialog(): void {
    this.dialogContainer?.$mount();
    if (this.dialogContainer?.$el) {
      this.elementMountTo.prepend(this.dialogContainer?.$el);
    }
  }

  /**
   * Initialized the dialog container
   * @param context - The dialog container context
   * @param options - The dialog container options
   */
  private initDialogContainer(context: Record<string, unknown>, options: Options): DialogContainerComponent {
    (DialogContainerComponent.prototype as any)._vueMeta = { initialized: false };
    return new (DialogContainerComponent as any)({
      ...context,
      propsData: {
        component: options.component,
        title: options.titleKey,
        props: options.props || {},
      },
    });
  }

  /**
   * Builds the dialog context
   */
  private buildDialogContext(): Record<string, unknown> {
    const keys = Object.keys(this.ctx.app).filter(
      (key) => key.startsWith('$') || ['router', 'i18n', 'store'].includes(key)
    );

    const context = keys.reduce((memo, key) => {
      if (this.ctx.app[key]) {
        memo[key] = this.ctx.app[key];
      }
      return memo;
    }, {} as Record<string, unknown>);
    context.route = this.ctx.route;
    return context;
  }

  /**
   * Initialized the dialog event callback handler
   */
  private initDialogEventCallbackHandler<T>(): DialogEventCallbackWrapper<T> {
    const okFunctions: { (value: T): void }[] = [];
    const cancelFunctions: { (value: T): void }[] = [];
    const dismissFunctions: { (): void }[] = [];
    const dialogEventCallbackWrapper: DialogEventCallbackWrapper<T> = {
      onOk(fn: (value: T) => void) {
        okFunctions.push(fn);
        return dialogEventCallbackWrapper;
      },
      onCancel(fn: (value: T) => void) {
        cancelFunctions.push(fn);
        return dialogEventCallbackWrapper;
      },
      onDismiss(fn: () => void) {
        dismissFunctions.push(fn);
        return dialogEventCallbackWrapper;
      },
    };
    this.dialogContainer?.$once(DialogEvent.Ok, (value: T) => {
      okFunctions.forEach((fn) => fn(value));
      this.closeDialog();
    });
    this.dialogContainer?.$once(DialogEvent.Cancel, (value: T) => {
      cancelFunctions.forEach((fn) => fn(value));
      this.closeDialog();
    });
    this.dialogContainer?.$once(DialogEvent.Dismiss, () => {
      dismissFunctions.forEach((fn) => fn());
      this.closeDialog();
    });
    return dialogEventCallbackWrapper;
  }

  /**
   * Closes the dialog.
   */
  closeDialog(): void {
    // remove elements first
    this.dialogContainer?.$el.parentNode?.removeChild(this.dialogContainer.$el);
    this.modal?.parentNode?.removeChild(this.modal);
    window.document.body.style.overflow = this.bodyOverflow;
    window.scrollTo(0, this.pageYOffset);
    // destroy container
    this.dialogContainer?.$destroy();
    this.dialogContainer = null;
  }
}

/** vue plugin to call apis */
const dialogPlugin: Plugin = (ctx: Context, inject: (key: string, value: any) => void): Promise<void> | void => {
  const manager = new DialogManager(ctx);
  inject('dialog', <T>(options: Options) => manager.openDialog<T>(options));
};

export default dialogPlugin;
