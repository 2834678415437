/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Websuch-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// @ts-ignore
import { Paginiereung, PaginiereungProperties } from './paginiereung';
// @ts-ignore
import { WebTreffer, WebTrefferProperties } from './web-treffer';

/**
 * 
 * @export
 * @interface WebTrefferXmlAntwort
 */
export interface WebTrefferXmlAntwort {
    /**
     * 
     * @type {WebTreffer}
     * @memberof WebTrefferXmlAntwort
     */
    'WebTreffer'?: WebTreffer;
    /**
     * 
     * @type {Paginiereung}
     * @memberof WebTrefferXmlAntwort
     */
    'Paginierung'?: Paginiereung;
}

/**
 * All properties of WebTrefferXmlAntwort.
 * @export
 * @enum {string}
 */
export enum WebTrefferXmlAntwortProperties {
    WebTreffer = 'WebTreffer',
    Paginierung = 'Paginierung'
}


