











/**
 * CopyrightFooter.vue
 *
 * This component is used to render copyright related information
 *
 */
import { Vue, Component } from 'nuxt-property-decorator';
import { FooterNavigationLink } from 'interfaces/footer-navigation';

export const StartDate = 2021;

@Component({
  name: 'copyright-footer',
})
export default class TheCopyrightFooterComponent extends Vue {
  copyright = 'Copyright';
  links: FooterNavigationLink[] = [
    { title: 'Datenschutzinformation', uuid: '5e50f9f8-89e8-4f26-927e-d105afc1384b' },
    { title: 'Impressum', uuid: '622acfaf-7cad-400f-8703-ae23effba10f' },
  ];

  // async fetch(): Promise<void> {
  //   await this.fetchNavigationMeta();
  // }

  // /**
  //  * gets the navigation meta links
  //  */
  // async fetchNavigationMeta(): Promise<void> {
  //   // If the user has not filled out the appropriate forms in the CMS,
  //   // the endpoint will return a 400 error. The current API wrapper
  //   // does not pass thru the error and returns "undefined" instead.
  //   const res = await this.$callApi<FooterNavigationPayload>('footer-navigation');

  //   if (res) {
  //     const { copyright, ...links } = res.data as FooterNavigation;
  //     this.copyright = copyright;
  //     this.links = Object.values(links);
  //     console.log(JSON.stringify(this.copyright));
  //     console.log(JSON.stringify(this.links));
  //   }
  // }

  /**
   * returns the copyright date using this format
   *
   * YYYY: The current year only, when the copyright year is the same as the current one
   * YYYY - YYYY: A time range from StartDate - current Year.
   */
  get copyrightDate(): string {
    const currentYear = new Date().getFullYear();
    return StartDate < currentYear ? `${StartDate} - ${currentYear}` : StartDate.toString();
  }

  // /**
  //  * If the current locale changes, we need to update the information in current UI
  //  */
  // @Watch('$i18n.locale')
  // async onCurrentLocaleChange(): Promise<void> {
  //   await this.fetchNavigationMeta();
  // }
}
