






import { Vue, Component, Prop } from 'nuxt-property-decorator';
import Error404Component from '@/components/error/404NotFound.vue';

/**
 * The general error page for error handling, it will switch between different error pages according to the error msg
 *
 * Props:
 * * error - the nuxt error
 *
 */
@Component({ name: 'nuxt-error' })
export default class Error extends Vue {
  @Prop({ required: true }) readonly error!: any;

  /**
   * Returns the component to use for the error.
   * Returning `undefined` will result in no render-output.
   */
  get errorPage(): Vue.Component | undefined {
    return this.error.statusCode === 404 ? Error404Component : undefined;
  }
}
