import { Vue, Component } from 'nuxt-property-decorator';
import { LogoImage } from '@/interfaces/logo';

/**
 * base for logo headers.
 */
@Component
export default class LogoComponent extends Vue {
  logo: LogoImage = { url: '/sites/default/files/logo/Logo+Schriftzug.svg', description: 'PIL Logo' };
  defaultLogoDescription = 'PIL Logo';
}
