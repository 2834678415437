/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * ZUFI-API
 * Liefert Zuständigkeiten zurück
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * Der Typ der Anschfift.   * 001: Allgemeine Hausanschrift   * 002: Eine Besucheranschrift ist eine Anschrift für Besucher   * 003: Postfach ist eine besondere Anschrift speziell für Post   * 004: Großempfänger-Postfach ist eine spezielle Anschrift für Post bei Großkunden   * 005: Lieferanschrift ist eine besondere Anschrift zur Anlieferung von Waren 
 * @export
 * @enum {string}
 */
export enum TypModel {
    _001 = '001',
    _002 = '002',
    _003 = '003',
    _004 = '004',
    _005 = '005'
}



