import { DirectiveOptions } from 'vue';
import { Vue } from 'nuxt-property-decorator';
import tippy, { DefaultProps } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css';
import { formatTooltipContent } from '../utils/html-convert';

/**
 * default options for tooltip
 */
const defaultOptions: Partial<DefaultProps> = {
  theme: 'light-border',
  placement: 'bottom',
  allowHTML: true,
  delay: [800, 0],
  touch: ['hold', 500],
};

tippy.setDefaultProps(defaultOptions);

/**
 * create new tippy instance
 * @param el - element
 * @param binding - directive binding
 */
function createTooltip(el: any, binding: any): void {
  if (binding && binding.value && binding.value.title) {
    const title = binding.value.title;
    const description = binding.value.description;
    const tooltip = formatTooltipContent(title, description);
    if (el._tippy) {
      el._tippy.setContent(tooltip);
    } else {
      tippy(el, {
        content: tooltip,
      });
    }
  }
}

/**
 * Custom Tooltip directive for display formatted tooltip
 * @example
 * Using value:
 * ```
 * v-custom-tooltip={title: 'title', description: 'description'}
 * ```
 */
const customTooltip: DirectiveOptions = {
  inserted(el, binding) {
    createTooltip(el, binding);
  },
  unbind(el: any) {
    el._tippy && el._tippy.destroy();
  },
  componentUpdated(el: any, binding) {
    createTooltip(el, binding);
  },
};

Vue.directive('custom-tooltip', customTooltip);
export default customTooltip;
