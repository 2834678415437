/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */
export enum FormStatus {
    DataUsageAck = 'DATA_USAGE_ACK',
    Editing = 'EDITING',
    Completed = 'COMPLETED',
    PaymentPending = 'PAYMENT_PENDING',
    Paid = 'PAID',
    PaymentFailure = 'PAYMENT_FAILURE',
    Submitted = 'SUBMITTED',
    SubmitFailure = 'SUBMIT_FAILURE'
}



