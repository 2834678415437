import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import { SearchValue, SearchAPI } from '@/interfaces/vsm';

/**
 * Name of the module. Have to be the same like the generated by nuxt (path to this file ignoring index.js)!
 * @see {@link https://github.com/nuxt/nuxt.js/blob/9681a8937d0c973bc685b757faa686023a4536fc/packages/vue-app/template/store.js#L87}
 */
export const MODULE_NAME = 'search';

/**
 * This module is used to store the search keywords so each other component has an access
 * to it and can reuse it
 *
 * We set the stateFactory to "true", because:
 * 1. It is needed for generating a state factory function instead of object
 * 2. Nuxtjs need a state factory function
 * @see {@link https://github.com/championswimmer/vuex-module-decorators#module-re-use-use-with-nuxtjs}
 */
@Module({ name: MODULE_NAME, namespaced: true, stateFactory: true })
export default class SearchModule extends VuexModule {
  /** return the initial topic search value */
  private static getInitialTopicValue = (): SearchValue => ({ value: '', suggestion: null });

  /** return the initial location search value */
  private static getInitialLocationValue = (): SearchValue => ({ value: '', suggestion: null });

  /** return the initial tab value */
  private static getInitialTabValue = (): SearchAPI => SearchAPI.allContent;

  /******************************************************************************************************************
   * vuex state
   ******************************************************************************************************************/

  /** Search value for topic search */
  private _topic: SearchValue = SearchModule.getInitialTopicValue();
  /** Search value for location search */
  private _location: SearchValue = SearchModule.getInitialLocationValue();
  /** Selected tab on search page */
  private _tab: SearchAPI = SearchModule.getInitialTabValue();

  /******************************************************************************************************************
   * vuex getter
   ******************************************************************************************************************/

  /** Getter for the stored topic search value */
  get topic(): SearchValue {
    return this._topic;
  }

  /** Getter for the stored location search value */
  get location(): SearchValue {
    return this._location;
  }

  /** Getter for the stored tab value */
  get tab(): SearchAPI {
    return this._tab;
  }

  /******************************************************************************************************************
   * vuex mutations
   ******************************************************************************************************************/

  /**
   * This mutation updates the stored topic search value
   *
   * @param newTopic - new topic search value
   */
  @Mutation
  updateTopic(newTopic: SearchValue): void {
    this._topic = newTopic;
  }

  /**
   * This mutation resets the stored topic search value
   */
  @Mutation
  resetTopic(): void {
    this._topic = SearchModule.getInitialTopicValue();
  }

  /**
   * This mutation updates the stored location search value
   *
   * @param newLocation - new location search value
   */
  @Mutation
  updateLocation(newLocation: SearchValue): void {
    this._location = newLocation;
  }

  /**
   * This mutation resets the stored location search value
   */
  @Mutation
  resetLocation(): void {
    this._location = SearchModule.getInitialLocationValue();
  }

  /**
   * This mutation updates the stored location search value
   * @param newTab - new tab
   */
  @Mutation
  updateTab(newTab: SearchAPI): void {
    this._tab = newTab;
  }

  /**
   * This mutation reset the stored tab
   */
  @Mutation
  resetTab(): void {
    this._tab = SearchModule.getInitialTabValue();
  }
}
