/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AxiosPromise, AxiosInstance, CancelTokenSource } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, ResponseWithIncluded } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequestOptions, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcrLevel } from '../models';
// @ts-ignore
import { UserAndMetadataDto } from '../models';

/**
 * All paths of SessionService.
 * Path parameters are prefixed with a colon, e.g. /foo/bar/:baz
 *
 * @export
 */
export const SessionServicePaths = {
    apiSessionControllerAuthorizationAnon: '/api/authorization/anon',
    apiSessionControllerAuthorizationUrl: '/api/authorization/url',
    apiSessionControllerLogout: '/api/logout',
    apiSessionControllerUser: '/api/user'
}

/**
 * All request headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 *
 * @export
 */
export const SessionServiceRequestHeaders = {

}

/**
 * All response headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 * Axios will transform all header names to lowercase and therefore this map will also contain all headers in lowercase.
 *
 * @see https://github.com/axios/axios/issues/413
 *
 * @export
 */
export const SessionServiceResponseHeaders = {

}

/**
 * All path parameters of each operation.
 *
 * @export
 */
export const SessionServicePathParams = {

}

/**
 * All query parameters of each operation.
 *
 * @export
 */
export const SessionServiceQueryParams = {

    apiSessionControllerAuthorizationUrl: {
        redirect: 'redirect',
        level: 'level',
        anon: 'anon'
    },

}

/**
 * SessionService - axios parameter creator
 * @export
 */
export const SessionServiceAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerAuthorizationAnon: async (options: RequestOptions = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authorization/anon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} redirect 
         * @param {AcrLevel} [level] 
         * @param {boolean} [anon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerAuthorizationUrl: async (redirect: string, level?: AcrLevel, anon?: boolean, options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'redirect' is not null or undefined
            assertParamExists('apiSessionControllerAuthorizationUrl', 'redirect', redirect)
            const localVarPath = `/api/authorization/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (redirect !== undefined) {
                localVarQueryParameter['redirect'] = redirect;
            }
            if (level !== undefined) {
                localVarQueryParameter['level'] = level;
            }
            if (anon !== undefined) {
                localVarQueryParameter['anon'] = anon;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerLogout: async (options: RequestOptions = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerUser: async (options: RequestOptions = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Cancel tokens of pending requests. One cancel token per service function.
 */
const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * SessionService - functional programming interface
 * @export
 */
export const SessionServiceFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionServiceAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionControllerAuthorizationAnon(options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionControllerAuthorizationAnon(options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiSessionController_authorizationAnon'],
                set: (token: CancelTokenSource) => cancelTokens['ApiSessionController_authorizationAnon'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {string} redirect 
         * @param {AcrLevel} [level] 
         * @param {boolean} [anon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionControllerAuthorizationUrl(redirect: string, level?: AcrLevel, anon?: boolean, options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionControllerAuthorizationUrl(redirect, level, anon, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiSessionController_authorizationUrl'],
                set: (token: CancelTokenSource) => cancelTokens['ApiSessionController_authorizationUrl'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionControllerLogout(options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionControllerLogout(options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiSessionController_logout'],
                set: (token: CancelTokenSource) => cancelTokens['ApiSessionController_logout'] = token,
              },
              configuration
            );
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionControllerUser(options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserAndMetadataDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionControllerUser(options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens['ApiSessionController_user'],
                set: (token: CancelTokenSource) => cancelTokens['ApiSessionController_user'] = token,
              },
              configuration
            );
        },
    }
};

/**
 * SessionService - factory interface
 * @export
 */
export const SessionServiceFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionServiceFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerAuthorizationAnon(options?: RequestOptions): AxiosPromise<void> {
            return localVarFp.apiSessionControllerAuthorizationAnon(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} redirect 
         * @param {AcrLevel} [level] 
         * @param {boolean} [anon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerAuthorizationUrl(redirect: string, level?: AcrLevel, anon?: boolean, options?: RequestOptions): AxiosPromise<string> {
            return localVarFp.apiSessionControllerAuthorizationUrl(redirect, level, anon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerLogout(options?: RequestOptions): AxiosPromise<void> {
            return localVarFp.apiSessionControllerLogout(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionControllerUser(options?: RequestOptions): AxiosPromise<UserAndMetadataDto> {
            return localVarFp.apiSessionControllerUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionService - interface
 * @export
 * @interface SessionService
 */
export interface SessionServiceInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionServiceInterface
     */
    apiSessionControllerAuthorizationAnon(options?: RequestOptions): AxiosPromise<void>;

    /**
     * 
     * @param {string} redirect 
     * @param {AcrLevel} [level] 
     * @param {boolean} [anon] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionServiceInterface
     */
    apiSessionControllerAuthorizationUrl(redirect: string, level?: AcrLevel, anon?: boolean, options?: RequestOptions): AxiosPromise<string>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionServiceInterface
     */
    apiSessionControllerLogout(options?: RequestOptions): AxiosPromise<void>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionServiceInterface
     */
    apiSessionControllerUser(options?: RequestOptions): AxiosPromise<UserAndMetadataDto>;

}

/**
 * SessionService - object-oriented interface
 * @export
 * @class SessionService
 * @extends {BaseAPI}
 */
export class SessionService extends BaseAPI implements SessionServiceInterface {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionService
     */
    public apiSessionControllerAuthorizationAnon(options?: RequestOptions) {
        return SessionServiceFp(this.configuration).apiSessionControllerAuthorizationAnon(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} redirect 
     * @param {AcrLevel} [level] 
     * @param {boolean} [anon] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionService
     */
    public apiSessionControllerAuthorizationUrl(redirect: string, level?: AcrLevel, anon?: boolean, options?: RequestOptions) {
        return SessionServiceFp(this.configuration).apiSessionControllerAuthorizationUrl(redirect, level, anon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionService
     */
    public apiSessionControllerLogout(options?: RequestOptions) {
        return SessionServiceFp(this.configuration).apiSessionControllerLogout(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionService
     */
    public apiSessionControllerUser(options?: RequestOptions) {
        return SessionServiceFp(this.configuration).apiSessionControllerUser(options).then((request) => request(this.axios, this.basePath));
    }
}
