import VuexPersistence from 'vuex-persist';
import { Plugin } from '@nuxt/types';
import { MODULE_NAME } from '@/store/search';

/**
 * TODO https://jira.t-systems-mms.eu/browse/DETOZGNRW-1096
 * Currently we temporary remove onNuxtReady() because it will synchronize vuex storage after mounted hook has been executed,
 * Which potentially triggers events and leads the other Watch methods behave incorrectly.
 */
const vuexPersistPlugin: Plugin = ({ store }) => {
  new VuexPersistence({
    key: 'nrw-sp.search-module',
    storage: window.localStorage,
    modules: [MODULE_NAME],
  }).plugin(store);
};

export default vuexPersistPlugin;
