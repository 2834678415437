import { UserAndMetadataDto, UserAndMetadataDtoLoginMetadata, UserAndMetadataDtoUser } from '@/generated/integration';
import { Action, Module, VuexModule, Mutation } from 'vuex-module-decorators';

/** special user type for ui */
export type UiUser = Pick<UserAndMetadataDtoUser, 'refId' | 'name' | 'transient'> | null;

/**
 * Name of the module. Have to be the same like the generated by nuxt (path to this file ignoring index.js)!
 * @see {@link https://github.com/nuxt/nuxt.js/blob/9681a8937d0c973bc685b757faa686023a4536fc/packages/vue-app/template/store.js#L87}
 */
export const MODULE_NAME = 'account';

/**
 * Store for account data.
 */
@Module({ name: MODULE_NAME, namespaced: true, stateFactory: true })
export default class AccountModule extends VuexModule {
  /******************************************************************************************************************
   * vuex state
   ******************************************************************************************************************/

  /** user data */
  private _user: UiUser = null;
  /** transient user */
  private _transientUser: UiUser = null;
  /** metadata */
  private _metadata: UserAndMetadataDtoLoginMetadata | null = null;

  /******************************************************************************************************************
   * vuex getter
   ******************************************************************************************************************/

  get user(): UiUser {
    return this._user;
  }

  get transientUser(): UiUser {
    return this._transientUser;
  }

  get metadata(): UserAndMetadataDtoLoginMetadata | null {
    return this._metadata;
  }

  get isLoggedIn(): boolean {
    return !!(this._user || this._transientUser);
  }
  /******************************************************************************************************************
   * vuex mutations
   ******************************************************************************************************************/

  /**
   * Sets user data in state.
   *
   * @param user - user to set
   */
  @Mutation
  setUser(userAndMeta: UserAndMetadataDto | null): void {
    if (!userAndMeta) {
      this._user = null;
      this._transientUser = null;
      this._metadata = null;
      return;
    }
    if (userAndMeta.user && userAndMeta.user.transient) {
      this._transientUser = userAndMeta.user;
      this._user = null;
      this._metadata = null;
      return;
    }
    this._user = userAndMeta.user;
    this._transientUser = null;
    this._metadata = userAndMeta.loginMetadata || null;
  }

  /******************************************************************************************************************
   * vuex actions
   ******************************************************************************************************************/

  /**
   * Action to fetch user data.
   */
  @Action({ rawError: true })
  async fetchUser(): Promise<void> {
    const userAndMeta = (await this.store.$services.sessionService.apiSessionControllerUser()).data;
    this.setUser(userAndMeta || null);
  }

  /**
   * Action to logout user.
   */
  @Action({ rawError: true })
  async logout(): Promise<void> {
    await this.store.$services.sessionService.apiSessionControllerLogout();
    this.setUser(null);
  }
}
