export default class EscapeUtils {
  /**
   * Escapes text for usage in html templates.
   *
   * @param text - text to escape
   * @returns escaped text
   */
  static escapeText(text: string): string {
    const pNode = document.createElement('p');
    pNode.textContent = text;
    return pNode.innerHTML;
  }
}
