/**
 * Sets url params.
 *
 * @param url - url to handle
 * @param params - params to set
 */
export function setURLParams(url: string, params?: Record<string, string>): string {
  if (typeof params === 'object' && params !== null) {
    Object.keys(params).forEach((key) => {
      if (params[key]) {
        url = url.replace(key, encodeURIComponent(params[key]));
      }
    });
  }
  return url;
}

/**
 * Appends query params to url.
 *
 * @param url - url to prepare
 * @param params - params to set
 */
export function setQueryParams(url: string, params?: Record<string, string | number | boolean | undefined>): string {
  let query = '';
  if (typeof params === 'object' && params !== null) {
    const firstSeparator = url.includes('?') ? '&' : '?';
    query = Object.keys(params).reduce<string>((memo, key) => {
      const param = params?.[key];
      if (param) {
        memo += `${memo ? '&' : firstSeparator}${key}=${encodeURIComponent(param)}`;
      }
      return memo;
    }, '');
  }
  return url + query;
}
