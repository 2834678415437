/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Websuch-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { AxiosPromise, AxiosInstance, CancelTokenSource } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction, ResponseWithIncluded } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, RequestOptions, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestErrorModel } from '../models';
// @ts-ignore
import { ServerErrorModel } from '../models';
// @ts-ignore
import { WebTrefferJsonAntwort } from '../models';

/**
 * All paths of DefaultService.
 * Path parameters are prefixed with a colon, e.g. /foo/bar/:baz
 *
 * @export
 */
export const DefaultServicePaths = {
    /**
     * Liefert Webtreffer
     */
    webTrefferGet: '/web-treffer'
}

/**
 * All request headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 *
 * @export
 */
export const DefaultServiceRequestHeaders = {

}

/**
 * All response headers.
 * According to @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec4.html#sec4.2 header names are case-sensitive.
 * Axios will transform all header names to lowercase and therefore this map will also contain all headers in lowercase.
 *
 * @see https://github.com/axios/axios/issues/413
 *
 * @export
 */
export const DefaultServiceResponseHeaders = {

}

/**
 * All path parameters of each operation.
 *
 * @export
 */
export const DefaultServicePathParams = {

}

/**
 * All query parameters of each operation.
 *
 * @export
 */
export const DefaultServiceQueryParams = {

    /**
     * Available query parameters for webTrefferGet.
     */
    webTrefferGet: {
        /**
         * Der Begriff, nach dem gesucht wird.
         */
        suchbegriff: 'suchbegriff',
        /**
         * Eine Liste der Collections, in der gesucht werden soll. Eine Collection ist eine Sammlung von verschiedenen Webseiten, die zu einer Kommune/Kreis/Land gehören.
         */
        collections: 'collections',
        /**
         * Die Startzahl der Paginierung. Die Nummerierung fängt mit 0 an.
         */
        start: 'start',
        /**
         * Die höchste Anzahl der Ergebnisse, die pro Suchanfrage zurückgeliefert wird.
         */
        anzahl: 'anzahl',
        /**
         * Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * `EN` - Englisch   * `DE` - Deutsch   * `FR` - Französisch   * `ES` - Spanisch   * `PT` - Portugiesisch   * `IT` - Italienisch   * `NL` - Niederländisch   * `PL` - Polnisch   * `RU` - Russisch   * `JP` - Japanisch   * `ZH` - Chinesisch 
         */
        sprache: 'sprache'
    }

}

/**
 * DefaultService - axios parameter creator
 * @export
 */
export const DefaultServiceAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Diese API wird eingesetzt, um nach Webtreffern zu suchen. Eine paginierte Liste der Ergebnisse wird zurückgeliefert.
         * @summary Liefert Webtreffer
         * @param {string} suchbegriff Der Begriff, nach dem gesucht wird.
         * @param {Array<string>} [collections] Eine Liste der Collections, in der gesucht werden soll. Eine Collection ist eine Sammlung von verschiedenen Webseiten, die zu einer Kommune/Kreis/Land gehören.
         * @param {number} [start] Die Startzahl der Paginierung. Die Nummerierung fängt mit 0 an.
         * @param {number} [anzahl] Die höchste Anzahl der Ergebnisse, die pro Suchanfrage zurückgeliefert wird.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webTrefferGet: async (suchbegriff: string, collections?: Array<string>, start?: number, anzahl?: number, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options: RequestOptions = {}): Promise<RequestArgs> => {
            // verify required parameter 'suchbegriff' is not null or undefined
            assertParamExists('webTrefferGet', 'suchbegriff', suchbegriff)
            const localVarPath = `/web-treffer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (suchbegriff !== undefined) {
                localVarQueryParameter['suchbegriff'] = suchbegriff;
            }
            if (collections) {
                localVarQueryParameter['collections'] = collections;
            }
            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }
            if (anzahl !== undefined) {
                localVarQueryParameter['anzahl'] = anzahl;
            }
            if (sprache !== undefined) {
                localVarQueryParameter['sprache'] = sprache;
            }

    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Cancel tokens of pending requests. One cancel token per service function.
 */
const cancelTokens: Record<string, CancelTokenSource> = {};

/**
 * DefaultService - functional programming interface
 * @export
 */
export const DefaultServiceFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultServiceAxiosParamCreator(configuration)
    return {
        /**
         * Diese API wird eingesetzt, um nach Webtreffern zu suchen. Eine paginierte Liste der Ergebnisse wird zurückgeliefert.
         * @summary Liefert Webtreffer
         * @param {string} suchbegriff Der Begriff, nach dem gesucht wird.
         * @param {Array<string>} [collections] Eine Liste der Collections, in der gesucht werden soll. Eine Collection ist eine Sammlung von verschiedenen Webseiten, die zu einer Kommune/Kreis/Land gehören.
         * @param {number} [start] Die Startzahl der Paginierung. Die Nummerierung fängt mit 0 an.
         * @param {number} [anzahl] Die höchste Anzahl der Ergebnisse, die pro Suchanfrage zurückgeliefert wird.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async webTrefferGet(suchbegriff: string, collections?: Array<string>, start?: number, anzahl?: number, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebTrefferJsonAntwort>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.webTrefferGet(suchbegriff, collections, start, anzahl, sprache, options);
            return createRequestFunction(
              localVarAxiosArgs,
              BASE_PATH,
              {
                get: (): CancelTokenSource => cancelTokens[''],
                set: (token: CancelTokenSource) => cancelTokens[''] = token,
              },
              configuration
            );
        },
    }
};

/**
 * DefaultService - factory interface
 * @export
 */
export const DefaultServiceFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultServiceFp(configuration)
    return {
        /**
         * Diese API wird eingesetzt, um nach Webtreffern zu suchen. Eine paginierte Liste der Ergebnisse wird zurückgeliefert.
         * @summary Liefert Webtreffer
         * @param {string} suchbegriff Der Begriff, nach dem gesucht wird.
         * @param {Array<string>} [collections] Eine Liste der Collections, in der gesucht werden soll. Eine Collection ist eine Sammlung von verschiedenen Webseiten, die zu einer Kommune/Kreis/Land gehören.
         * @param {number} [start] Die Startzahl der Paginierung. Die Nummerierung fängt mit 0 an.
         * @param {number} [anzahl] Die höchste Anzahl der Ergebnisse, die pro Suchanfrage zurückgeliefert wird.
         * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        webTrefferGet(suchbegriff: string, collections?: Array<string>, start?: number, anzahl?: number, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): AxiosPromise<WebTrefferJsonAntwort> {
            return localVarFp.webTrefferGet(suchbegriff, collections, start, anzahl, sprache, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultService - interface
 * @export
 * @interface DefaultService
 */
export interface DefaultServiceInterface {
    /**
     * Diese API wird eingesetzt, um nach Webtreffern zu suchen. Eine paginierte Liste der Ergebnisse wird zurückgeliefert.
     * @summary Liefert Webtreffer
     * @param {string} suchbegriff Der Begriff, nach dem gesucht wird.
     * @param {Array<string>} [collections] Eine Liste der Collections, in der gesucht werden soll. Eine Collection ist eine Sammlung von verschiedenen Webseiten, die zu einer Kommune/Kreis/Land gehören.
     * @param {number} [start] Die Startzahl der Paginierung. Die Nummerierung fängt mit 0 an.
     * @param {number} [anzahl] Die höchste Anzahl der Ergebnisse, die pro Suchanfrage zurückgeliefert wird.
     * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultServiceInterface
     */
    webTrefferGet(suchbegriff: string, collections?: Array<string>, start?: number, anzahl?: number, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions): AxiosPromise<WebTrefferJsonAntwort>;

}

/**
 * DefaultService - object-oriented interface
 * @export
 * @class DefaultService
 * @extends {BaseAPI}
 */
export class DefaultService extends BaseAPI implements DefaultServiceInterface {
    /**
     * Diese API wird eingesetzt, um nach Webtreffern zu suchen. Eine paginierte Liste der Ergebnisse wird zurückgeliefert.
     * @summary Liefert Webtreffer
     * @param {string} suchbegriff Der Begriff, nach dem gesucht wird.
     * @param {Array<string>} [collections] Eine Liste der Collections, in der gesucht werden soll. Eine Collection ist eine Sammlung von verschiedenen Webseiten, die zu einer Kommune/Kreis/Land gehören.
     * @param {number} [start] Die Startzahl der Paginierung. Die Nummerierung fängt mit 0 an.
     * @param {number} [anzahl] Die höchste Anzahl der Ergebnisse, die pro Suchanfrage zurückgeliefert wird.
     * @param {'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH'} [sprache] Die gewünschte Sprache für die Übersetzung. Folgende Sprachen werden unterstützt:   * &#x60;EN&#x60; - Englisch   * &#x60;DE&#x60; - Deutsch   * &#x60;FR&#x60; - Französisch   * &#x60;ES&#x60; - Spanisch   * &#x60;PT&#x60; - Portugiesisch   * &#x60;IT&#x60; - Italienisch   * &#x60;NL&#x60; - Niederländisch   * &#x60;PL&#x60; - Polnisch   * &#x60;RU&#x60; - Russisch   * &#x60;JP&#x60; - Japanisch   * &#x60;ZH&#x60; - Chinesisch 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultService
     */
    public webTrefferGet(suchbegriff: string, collections?: Array<string>, start?: number, anzahl?: number, sprache?: 'EN' | 'DE' | 'FR' | 'ES' | 'PT' | 'IT' | 'NL' | 'PL' | 'RU' | 'JP' | 'ZH', options?: RequestOptions) {
        return DefaultServiceFp(this.configuration).webTrefferGet(suchbegriff, collections, start, anzahl, sprache, options).then((request) => request(this.axios, this.basePath));
    }
}
