import { Plugin } from '@nuxt/types';
import sanitizeHtml from 'sanitize-html';

/**
 * Type of the split message function.
 */
type SplitMessageType = (msgKey: string, placeholderName: string) => string[];
/**
 * Type of the sanitize function.
 * TODO DETOZGNRW-2287 Check all v-html use cases and apply v-if/v-show accordingly when the response of sanitizer is undefined
 */
type SanitizeType = (dirty: string) => string | undefined;

/** declare new properties */
declare module 'vue/types/vue' {
  interface Vue {
    $splitMessage: SplitMessageType;
    $sanitize: SanitizeType;
  }
}

const SEPARATOR = '$$$$';

/**
 * Nuxt plugin to add some util functions to components.
 */
const UtilPlugin: Plugin = ({ app }, inject) => {
  /* Method which splits the translated method */
  const splitMsg: SplitMessageType = (msgKey, placeholderName) => {
    const msgParts = (app.i18n.t(msgKey, { [placeholderName]: SEPARATOR }) as string).split(SEPARATOR);
    if (msgParts.length < 2) {
      throw new Error(
        `Message key '${msgKey}' does not exists or have no named param '${placeholderName}'. Splitting is not possible!`
      );
    }
    return msgParts;
  };
  /* Method which sanitize html markups (enabled img tag) */
  const sanitizeHTMLWithImage: SanitizeType = function (dirty: string) {
    return typeof dirty === 'string'
      ? sanitizeHtml(dirty, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
          allowedAttributes: {
            a: ['href', 'name', 'target'],
            img: ['src', 'alt'],
          },
        })
      : undefined;
  };

  /* inject methods to each vue component */
  inject('splitMessage', splitMsg);
  inject('sanitize', sanitizeHTMLWithImage);
};

export default UtilPlugin;
