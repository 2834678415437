/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Websuch-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface WebTreffer
 */
export interface WebTreffer {
    /**
     * Id des Webtreffers
     * @type {string}
     * @memberof WebTreffer
     */
    'id'?: string;
    /**
     * Titel des Webtreffers
     * @type {string}
     * @memberof WebTreffer
     */
    'titel'?: string;
    /**
     * Der Schnipsel aus der Seite. Er enthält manchmal HTML Elemente.
     * @type {string}
     * @memberof WebTreffer
     */
    'snippet'?: string;
    /**
     * Das Datum an dem die Seite zuletzt aktualisiert wurde, im Format JJJJ-MM-TT.
     * @type {string}
     * @memberof WebTreffer
     */
    'datum'?: string;
    /**
     * Das Datum an dem die Seite zuletzt gecrawlt wurde. Das Format entspricht dem vollständigen Datum und der Uhrzeit wie in dem Standard RFC 3339, Abschnitt 5.6 spezifiziert wurde. Siehe https://tools.ietf.org/html/rfc3339#section-5.6
     * @type {string}
     * @memberof WebTreffer
     */
    'crawlDatum'?: string;
    /**
     * Mime Typ des Webtreffers
     * @type {string}
     * @memberof WebTreffer
     */
    'mime'?: string;
    /**
     * Die Sprache des Webtreffers, wie im ISO 639‑1 Standard spezifiziert. Siehe https://infogalactic.com/info/List_of_ISO_639-1_codes.
     * @type {string}
     * @memberof WebTreffer
     */
    'sprache'?: string;
    /**
     * Die Url des Webtreffers.
     * @type {string}
     * @memberof WebTreffer
     */
    'url'?: string;
    /**
     * Die kodierte Url des Webtreffers. Diese Url enthält nur ASCII lesbare Zeichen.
     * @type {string}
     * @memberof WebTreffer
     */
    'kodierteUrl'?: string;
    /**
     * Die Kodierung der Seite.
     * @type {string}
     * @memberof WebTreffer
     */
    'kodierung'?: string;
    /**
     * Die Größe der Seite in Kilobyte.
     * @type {number}
     * @memberof WebTreffer
     */
    'groesse'?: number;
}

/**
 * All properties of WebTreffer.
 * @export
 * @enum {string}
 */
export enum WebTrefferProperties {
    /**
     * Id des Webtreffers
     */
    id = 'id',
    /**
     * Titel des Webtreffers
     */
    titel = 'titel',
    /**
     * Der Schnipsel aus der Seite. Er enthält manchmal HTML Elemente.
     */
    snippet = 'snippet',
    /**
     * Das Datum an dem die Seite zuletzt aktualisiert wurde, im Format JJJJ-MM-TT.
     */
    datum = 'datum',
    /**
     * Das Datum an dem die Seite zuletzt gecrawlt wurde. Das Format entspricht dem vollständigen Datum und der Uhrzeit wie in dem Standard RFC 3339, Abschnitt 5.6 spezifiziert wurde. Siehe https://tools.ietf.org/html/rfc3339#section-5.6
     */
    crawlDatum = 'crawlDatum',
    /**
     * Mime Typ des Webtreffers
     */
    mime = 'mime',
    /**
     * Die Sprache des Webtreffers, wie im ISO 639‑1 Standard spezifiziert. Siehe https://infogalactic.com/info/List_of_ISO_639-1_codes.
     */
    sprache = 'sprache',
    /**
     * Die Url des Webtreffers.
     */
    url = 'url',
    /**
     * Die kodierte Url des Webtreffers. Diese Url enthält nur ASCII lesbare Zeichen.
     */
    kodierteUrl = 'kodierteUrl',
    /**
     * Die Kodierung der Seite.
     */
    kodierung = 'kodierung',
    /**
     * Die Größe der Seite in Kilobyte.
     */
    groesse = 'groesse'
}


