























/**
 * TheMandatoryFooter.vue
 *
 * This component includes all mandatory links
 *
 * Component call:
 *   <TheMandatoryFooter />
 */
import { Vue, Component } from 'nuxt-property-decorator';
import { ContentIdRouteParams, RouteNames } from '@generated/routeNames';
import CopyrightFooterComponent from '@/components/footer/CopyrightFooter.vue';

@Component({
  name: 'the-mandatory-footer',
  components: {
    CopyrightFooter: CopyrightFooterComponent,
  },
})
export default class TheMandatoryFooterComponent extends Vue {
  getContentPath(contentId: string): string {
    return this.localePath({ name: RouteNames.CONTENT_ID, params: { [ContentIdRouteParams.ID]: contentId } });
  }
}
