/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Websuch-API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ServerErrorModel
 */
export interface ServerErrorModel {
    /**
     * 
     * @type {Array<object>}
     * @memberof ServerErrorModel
     */
    'fehler'?: Array<object>;
}

/**
 * All properties of ServerErrorModel.
 * @export
 * @enum {string}
 */
export enum ServerErrorModelProperties {
    fehler = 'fehler'
}


