/**
 * Represents the dialog event names
 */
export enum DialogEvent {
  Dismiss = 'dismiss',
  Cancel = 'cancel',
  Ok = 'ok',
}

/**
 * Callback Wrapper for dialog events
 */
export interface DialogEventCallbackWrapper<T> {
  /**
   * Executed when the dialog is confirmed by the user
   */
  onOk(fn: (value: T) => void): DialogEventCallbackWrapper<T>;

  /**
   * Executed when the user closes the dialog via click outside of the dialog, close button click or escape keyboard button press
   */
  onCancel(fn: (value: T) => void): DialogEventCallbackWrapper<T>;

  /**
   * Executed when the user clicks the cancel button
   */
  onDismiss(fn: () => void): DialogEventCallbackWrapper<T>;
}
