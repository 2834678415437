/* tslint:disable */
/* eslint-disable */
/**
 * Copyright(c) 2021 T-Systems Multimedia Solutions GmbH
 * Riesaer Str. 5, 01129 Dresden
 * All rights reserved.
 *
 * Serviceportal Integration Components
 * Integration Layer Api for Serviceportal.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ScopeDto
 */
export interface ScopeDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ScopeDto
     */
    'sk': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ScopeDto
     */
    'npa': Array<string>;
}

/**
 * All properties of ScopeDto.
 * @export
 * @enum {string}
 */
export enum ScopeDtoProperties {
    sk = 'sk',
    npa = 'npa'
}


